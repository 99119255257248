import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    AppReleaseListDocument,
    AppReleaseListQuery,
    useAppReleaseListQuery,
    useCreateAppReleaseMutation,
    useUpdateAppReleaseMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { errorRed } from '~/theme/WildTheme';
import { useUser } from '~/hooks';

type AppRelease = NonNullable<AppReleaseListQuery['appReleasesV2']['results'][0]>;

interface FormInput {
    name: string;
}
const inputValidation = Yup.object().shape({
    name: Yup.string().required('You need to enter a name'),
});

export const AppReleaseModal: React.FC<{
    isOpen: boolean;
    item: AppRelease | null;
    onClose: () => void;
}> = props => {
    const { isOpen, item, onClose } = props;
    const [nameUsed, setNameUsed] = useState(false);
    const { data: userData } = useUser();

    // list of conditionTypes to compare with name field of new one
    const { data: appReleasesList, loading: listLoading } = useAppReleaseListQuery();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormInput>({
        resolver: yupResolver(inputValidation as any),
        defaultValues: {
            name: item?.name,
        },
    });

    useEffect(() => {
        reset({
            name: item?.name,
        });
    }, [item, reset]);

    const handleClose = () => {
        setNameUsed(false);
        onClose();
    };

    const [createAppRelease, { loading: createLoading }] = useCreateAppReleaseMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem saving the App Release: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.createAppRelease?.resourceCreated;
            if (response.data?.createAppRelease?.success && newItem) {
                const currentItems = cache.readQuery<AppReleaseListQuery>({
                    query: AppReleaseListDocument,
                });
                if (currentItems?.appReleasesV2) {
                    cache.writeQuery<AppReleaseListQuery>({
                        query: AppReleaseListDocument,
                        data: {
                            appReleasesV2: {
                                results: [...currentItems.appReleasesV2.results, newItem],
                                total: currentItems.appReleasesV2.total,
                            },
                        },
                    });
                }
            }
        },
    });

    const [updateItem, { loading: updateLoading }] = useUpdateAppReleaseMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem updating the App Release: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newItem = response.data?.updateAppRelease?.resourceUpdated;
            if (response.data?.updateAppRelease?.success && newItem) {
                const currentItems = cache.readQuery<AppReleaseListQuery>({
                    query: AppReleaseListDocument,
                });
                if (currentItems?.appReleasesV2) {
                    const updatedList = currentItems.appReleasesV2.results.map(item => {
                        if (item.id === newItem.id) {
                            return newItem;
                        }
                        return item;
                    });
                    cache.writeQuery<AppReleaseListQuery>({
                        query: AppReleaseListDocument,
                        data: {
                            appReleasesV2: { results: [...updatedList], total: updatedList.length },
                        },
                    });
                }
            }
        },
    });

    const isInEditMode = item !== null;
    const onSubmit = (values: AppRelease) => {
        setNameUsed(false);
        if (values?.name) {
            if (
                appReleasesList &&
                appReleasesList.appReleasesV2.results.filter(
                    t =>
                        t.name.toLowerCase() === values.name.toLowerCase() &&
                        (!isInEditMode || t.id !== item.id),
                ).length > 0
            ) {
                setNameUsed(true);
                return;
            }
        }
        values.addedBy = userData?.currentUser?.name || 'Unknown';
        if (isInEditMode && item !== null) {
            updateItem({
                variables: {
                    input: {
                        id: item.id,
                        data: values,
                    },
                },
            });
            return;
        }
        createAppRelease({
            variables: {
                input: values,
            },
        });
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                {isInEditMode ? 'Edit App Release' : 'Add App Release'}
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    {listLoading || updateLoading || createLoading ? (
                        <Loading height={50} />
                    ) : (
                        <div>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Name *"
                                    fullWidth
                                    margin="dense"
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                                {nameUsed && (
                                    <div style={{ color: errorRed }}>
                                        Name already used. Please change.
                                    </div>
                                )}
                            </Grid>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    AlertSeverity,
    CareTeamContactInput,
    CareTeamMemberInput,
    FetchCareTeamMembersForCareTeamMembersPageDocument,
    useCareTeamContactsForCareTeamMemberEditModalQuery,
    useCareTeamMembersForCareTeammemberModalQuery,
    useCareTeamMemberTypesForCareTeamMemberEditModalQuery,
    useCreateCareTeamMemberMutation,
    useFetchCareTeamMemberByIdLazyQuery,
    usePreFetchAppsQuery,
    useUpdateCareTeamMemberFromModalMutation,
    useUsersForCareTeamMemberModalQuery,
    UsStateListQuery,
} from '~/schemaTypes';
import { CareTeamMemberEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import CareTeamContactModal from '../../CareTeamContacts/CareTeamContactModal/CareTeamContactModal';
import { useStyles } from './styles';
import { CARE_TEAM_MEMBER_VALIDATION_SCHEMA } from './yupSchema';

export const OVERRIDEN_CONTACT = 'overridenContact';

export const ELIGIBILITY_CHECK_VARIANTS = new Map([
    ['WHASN', 'WHASN'],
    ['WHUSA', 'WHUSA'],
    ['UNLV', 'UNLV'],
    ['None', ''],
]);
interface CareTeamMemberFormInput {
    appBundleId: string;
    careTeamMemberTypeId: string;
    careTeamMemberContactId?: string;
    careTeamMemberTIN?: string;
    careTeamMemberNPI?: string;
    practiceExternalId?: string;
    practiceCode?: string;
    prerequisiteTeamMembers: string[];
    overridenCareTeamMemberContact: CareTeamContactInput;
    eligibilityCheck: string;
    eligibilityRecheckDays: number;
    consentRequired: boolean;
    consentAgreement: string;
    label: {
        en: string;
        es: string;
    };
    usState?: string;
    zipCode?: string;
    isActive?: boolean;
    userId?: string;
    appDefault?: boolean;
    shortName: string;
}

type CareTeamMemberModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    usStateList?: UsStateListQuery;
    setCareTeamMemberId: Dispatch<SetStateAction<string>>;
    id?: string;
};

const CareTeamMemberModal: React.FC<CareTeamMemberModalProps> = ({
    setOpen,
    usStateList,
    setCareTeamMemberId,
    id,
}) => {
    const { classes } = useStyles();
    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsQuery();

    const [showCareTeamContactModal, setShowCareTeamContactModal] = useState(false);
    const [overridenContact, setOverridenContact] = useState<CareTeamContactInput | undefined>(
        undefined,
    );

    const { data: careTeamMembers, loading: careTeamMembersLoading } =
        useCareTeamMembersForCareTeammemberModalQuery();

    // Load CareTeamMemberTypes
    const { data: memberTypes, loading: memberTypesLoading } =
        useCareTeamMemberTypesForCareTeamMemberEditModalQuery();

    // Load CareTeamContacts
    const { data: contacts, loading: contactsLoading } =
        useCareTeamContactsForCareTeamMemberEditModalQuery();

    const { data: users, loading: usersLoading } = useUsersForCareTeamMemberModalQuery();

    // Create CareTeamMember
    const [createCareTeamMember, { loading: createCareTeamMemberLoading }] =
        useCreateCareTeamMemberMutation({
            onCompleted: data => {
                if (data.createCareTeamMember?.success) {
                    setOpen(false);
                }
            },
            onError: error => {
                TriggerGlobalConfirm({
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    callback: () => {},
                    message: `There was a problem creating the care team member: ${error.message}`,
                });
            },
            refetchQueries: [{ query: FetchCareTeamMembersForCareTeamMembersPageDocument }],
        });

    // Update CareTeamMember
    const [updateCareTeamMember, { loading: updateCareTeamMemberLoading }] =
        useUpdateCareTeamMemberFromModalMutation({
            onCompleted: data => {
                if (data.updateCareTeamMember?.success) {
                    setOpen(false);
                    setCareTeamMemberId('');
                }
            },
            onError: error => {
                TriggerGlobalConfirm({
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    callback: () => {},
                    message: `There was a problem updating the care team member: ${error.message}`,
                });
            },
            refetchQueries: [{ query: FetchCareTeamMembersForCareTeamMembersPageDocument }],
        });

    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { errors },
        setError,
    } = useForm<CareTeamMemberFormInput>({
        resolver: yupResolver(CARE_TEAM_MEMBER_VALIDATION_SCHEMA as any),
        defaultValues: {
            prerequisiteTeamMembers: [],
            consentRequired: false,
            isActive: true,
        },
    });

    const careTeamMemberTypeId = watch('careTeamMemberTypeId');
    const selectedUser = watch('userId');

    // load created or updated CareTeamMember
    const [getCareTeamMember, { loading: careTeamMemberLoading }] =
        useFetchCareTeamMemberByIdLazyQuery({
            onCompleted: ({ careTeamMember }) => {
                if (careTeamMember) {
                    const {
                        appBundleId,
                        label,
                        shortName,
                        careTeamMemberTypeId,
                        careTeamMemberContactId,
                        careTeamMemberTIN,
                        careTeamMemberNPI,
                        practiceExternalId,
                        practiceCode,
                        overridenCareTeamMemberContact,
                        prerequisiteTeamMembers,
                        eligibilityCheck,
                        eligibilityRecheckDays,
                        usState,
                        zipCode,
                        consentRequired,
                        consentAgreement,
                        isActive,
                        userId,
                        appDefault,
                    } = careTeamMember;

                    reset({
                        appBundleId: appBundleId ?? '',
                        shortName: shortName ?? '',
                        careTeamMemberTypeId,
                        careTeamMemberContactId,
                        careTeamMemberTIN: careTeamMemberTIN ?? '',
                        careTeamMemberNPI: careTeamMemberNPI ?? '',
                        practiceExternalId: practiceExternalId ?? '',
                        practiceCode: practiceCode ?? '',
                        prerequisiteTeamMembers: prerequisiteTeamMembers ?? [],
                        eligibilityCheck: eligibilityCheck ?? '',
                        eligibilityRecheckDays: eligibilityRecheckDays ?? 0,
                        consentRequired: consentRequired ?? false,
                        consentAgreement: consentAgreement ?? '',
                        label: {
                            en: label?.en ?? '',
                            es: label?.es ?? '',
                        },
                        usState: usState ?? '',
                        zipCode: zipCode ?? '',
                        isActive: isActive ?? true,
                        userId: userId ?? '',
                        appDefault: appDefault ?? false,
                    });

                    if (overridenCareTeamMemberContact) {
                        setOverridenContact(overridenCareTeamMemberContact);
                    }
                }
            },
            fetchPolicy: 'network-only',
        });

    const careTeamMemberType = watch('careTeamMemberTypeId');
    const isSelectedMemberTypeJob = useMemo(() => {
        return (
            memberTypes?.careTeamMemberTypesV2.results.find(t => t.id === careTeamMemberType)
                ?.isJob === true
        );
    }, [careTeamMemberType, memberTypes?.careTeamMemberTypesV2.results]);

    const validateInput = (data: CareTeamMemberInput) => {
        if (isSelectedMemberTypeJob && data.userId === '') {
            setError('userId', {
                type: 'required',
                message: 'User is required for job type',
            });
            TriggerGlobalAlert({
                message: 'User is required for job type',
                severity: AlertSeverity.Error,
            });
            return false;
        }
        return true;
    };

    const onSubmit = (data: CareTeamMemberInput) => {
        if (!validateInput(data)) {
            return;
        }
        if (id) {
            const { appBundleId, ...rest } = data;
            updateCareTeamMember({
                variables: {
                    input: {
                        id,
                        data: {
                            ...rest,
                            overridenCareTeamMemberContact: overridenContact,
                            usState: data?.usState !== '' ? data.usState : null,
                            zipCode: data?.zipCode !== '' ? data.zipCode : null,
                            userId: data?.userId !== '' ? data.userId : null,
                        },
                    },
                },
            });
        } else {
            createCareTeamMember({
                variables: {
                    input: {
                        ...data,
                        overridenCareTeamMemberContact: overridenContact,
                        usState: data?.usState !== '' ? data.usState : undefined,
                        zipCode: data?.zipCode !== '' ? data.zipCode : undefined,
                        careTeamMemberContactId:
                            data?.careTeamMemberContactId !== ''
                                ? data.careTeamMemberContactId
                                : undefined,
                        userId: data?.userId !== '' ? data.userId : undefined,
                    },
                },
            });
        }
    };

    const overrideCB = (contactData: CareTeamContactInput) => {
        setOverridenContact(contactData);
    };

    const handleDeleteOverride = () => {
        setOverridenContact(undefined);
    };

    const handleCancel = () => {
        setOpen(false);
        setCareTeamMemberId('');
    };

    const handleSelectMemberType = () => {
        if (!isSelectedMemberTypeJob) {
            setValue('userId', '');
            setValue('appDefault', false);
        }
    };

    useEffect(() => {
        if (id) getCareTeamMember({ variables: { input: { id } } });
    }, [id, getCareTeamMember]);

    if (updateCareTeamMemberLoading) return <Loading subtitle="Updating Care Team Member..." />;
    if (createCareTeamMemberLoading) return <Loading subtitle="Creating Care Team Member..." />;
    if (memberTypesLoading) return <Loading subtitle="Loading Care Team Member Types..." />;
    if (contactsLoading) return <Loading subtitle="Loading Care Team Contacts..." />;
    if (careTeamMembersLoading) return <Loading subtitle="Loading Care Team Contacts..." />;
    if (usersLoading) return <Loading subtitle="Loading Users..." />;

    if (careTeamMemberLoading || preFetchLoading)
        return <Loading subtitle="Loading Care Team member" />;

    return (
        <>
            <form noValidate onSubmit={handleSubmit(onSubmit)} data-test={CareTeamMemberEnum.MODAL}>
                <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                    {id === '' ? 'New Care Team Member' : 'Edit Care Team Member'}
                </DialogTitleWithClose>
                <DialogContent className="formCont">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <OutlinedSection title="Label">
                                <TextField
                                    variant="outlined"
                                    label="English"
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    {...register('label.en')}
                                    error={!!errors.label?.en}
                                    helperText={errors.label?.en?.message}
                                    data-test={CareTeamMemberEnum.LABEL_EN}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Spanish"
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    {...register('label.es')}
                                    error={!!errors.label?.es}
                                    helperText={errors.label?.es?.message}
                                    data-test={CareTeamMemberEnum.LABEL_ES}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Short Name"
                                    type="text"
                                    margin="dense"
                                    fullWidth
                                    {...register('shortName')}
                                    error={!!errors.shortName}
                                    helperText={errors.shortName?.message}
                                    data-test={CareTeamMemberEnum.SHORT_NAME}
                                />
                            </OutlinedSection>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="isActive"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <FormControlLabel
                                        label="Is active member?"
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={e => onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <ReactHookFormSelect
                                control={control}
                                name="appBundleId"
                                variant="outlined"
                                defaultValue=""
                                label="App"
                                fullWidth
                                margin="dense"
                                error={!!errors.appBundleId}
                                data-test={CareTeamMemberEnum.CLIENT}
                                disabled={!!id}
                            >
                                {preFetchData?.applicationsV2.results.map(({ id, appBundleId }) => (
                                    <MenuItem key={id} value={appBundleId} data-test={appBundleId}>
                                        {appBundleId}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={6}>
                            <ReactHookFormSelect
                                control={control}
                                name="careTeamMemberTypeId"
                                variant="outlined"
                                defaultValue=""
                                label="Care Team Member Type"
                                fullWidth
                                margin="dense"
                                data-test={CareTeamMemberEnum.TYPE}
                                error={!!errors.careTeamMemberTypeId}
                            >
                                {memberTypes?.careTeamMemberTypesV2.results.map(({ name, id }) => (
                                    <MenuItem
                                        onClick={handleSelectMemberType}
                                        key={id}
                                        value={id}
                                        data-test={name}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                    </Grid>
                    {isSelectedMemberTypeJob && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ReactHookFormSelect
                                    control={control}
                                    name="userId"
                                    variant="outlined"
                                    defaultValue=""
                                    label="Select user for job type"
                                    fullWidth
                                    margin="dense"
                                    error={!!errors.userId}
                                >
                                    {users?.usersV2.results.map(({ name, id }) => (
                                        <MenuItem key={id} value={id} data-test={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <Controller
                                    name="appDefault"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <FormControlLabel
                                            label="Set User as App Default for this Care Team Member"
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    disabled={Boolean(!selectedUser)}
                                                    onChange={e => onChange(e.target.checked)}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={2}>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                            {['delivery_hospital', 'practice'].includes(
                                memberTypes?.careTeamMemberTypesV2?.results.find(
                                    ({ id }) => id === careTeamMemberTypeId,
                                )?.name || '',
                            ) && (
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="TIN"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('careTeamMemberTIN')}
                                        error={!!errors.careTeamMemberTIN}
                                        helperText={errors.careTeamMemberTIN?.message}
                                    />
                                </Grid>
                            )}
                            {['provider'].includes(
                                memberTypes?.careTeamMemberTypesV2?.results.find(
                                    ({ id }) => id === careTeamMemberTypeId,
                                )?.name || '',
                            ) && (
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        label="NPI"
                                        type="text"
                                        margin="dense"
                                        fullWidth
                                        {...register('careTeamMemberNPI')}
                                        error={!!errors.careTeamMemberNPI}
                                        helperText={errors.careTeamMemberNPI?.message}
                                    />
                                </Grid>
                            )}
                            {['practice'].includes(
                                memberTypes?.careTeamMemberTypesV2?.results.find(
                                    ({ id }) => id === careTeamMemberTypeId,
                                )?.name || '',
                            ) && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Practice External Id"
                                            type="text"
                                            margin="dense"
                                            fullWidth
                                            {...register('practiceExternalId')}
                                            error={!!errors.practiceExternalId}
                                            helperText={errors.practiceExternalId?.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            label="Practice Code"
                                            type="text"
                                            margin="dense"
                                            fullWidth
                                            {...register('practiceCode')}
                                            error={!!errors.practiceCode}
                                            helperText={errors.practiceCode?.message}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <OutlinedSection title="Care Team Contact">
                                <ReactHookFormSelect
                                    control={control}
                                    name="careTeamMemberContactId"
                                    variant="outlined"
                                    defaultValue=""
                                    label="Care Team Contact"
                                    fullWidth
                                    data-test={CareTeamMemberEnum.CONTACT}
                                    margin="dense"
                                    error={!!errors.careTeamMemberContactId}
                                >
                                    {contacts?.careTeamContactsV2.results.map(({ name, id }) => (
                                        <MenuItem key={id} value={id} data-test={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <Button
                                    onClick={() => setShowCareTeamContactModal(true)}
                                    color="secondary"
                                    variant="contained"
                                    disabled={
                                        !watch('careTeamMemberContactId') || !!overridenContact
                                    }
                                    className={classes.overrideButton}
                                >
                                    Override contact (optional)
                                </Button>
                            </OutlinedSection>
                        </Grid>
                        {overridenContact && (
                            <Grid item xs={6}>
                                <OutlinedSection title="Overriden Contact">
                                    <Grid
                                        container
                                        direction="column"
                                        alignItems="stretch"
                                        className={classes.overriddenContactWrapper}
                                    >
                                        <Grid item>
                                            <Typography>Name: {overridenContact?.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                Type:{' '}
                                                {
                                                    memberTypes?.careTeamMemberTypesV2.results.find(
                                                        ({ id }) =>
                                                            id ===
                                                            overridenContact.careTeamMemberTypeId,
                                                    )?.name
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                Phone: {overridenContact?.phoneNumber}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                Email: {overridenContact?.emailAddress}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>Url: {overridenContact?.url}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                Adress: {overridenContact?.address.state},{' '}
                                                {overridenContact?.address.city},{' '}
                                                {overridenContact?.address.code},{' '}
                                                {overridenContact?.address.street1}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        startIcon={<Edit />}
                                        onClick={() => setShowCareTeamContactModal(true)}
                                        color="secondary"
                                        variant="contained"
                                        disabled={!watch('careTeamMemberContactId')}
                                        className={classes.overrideButton}
                                    >
                                        Edit Overriden Contact
                                    </Button>
                                    <Button
                                        startIcon={<Delete />}
                                        onClick={() => handleDeleteOverride()}
                                        color="primary"
                                        variant="contained"
                                        disabled={!watch('careTeamMemberContactId')}
                                        className={classes.overrideButton}
                                    >
                                        Delete Override
                                    </Button>
                                </OutlinedSection>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                name="usState"
                                variant="outlined"
                                defaultValue=""
                                label="US State"
                                fullWidth
                                margin="dense"
                                error={!!errors.usState}
                            >
                                {usStateList?.usStateList.map(i => (
                                    <MenuItem key={i.abbreviation} value={i.abbreviation || ''}>
                                        {i.name}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            <Button
                                startIcon={<ClearIcon />}
                                color="primary"
                                variant="contained"
                                className={classes.leftIndent}
                                onClick={() => {
                                    setValue('usState', '');
                                }}
                            >
                                Clear US State
                            </Button>
                            <TextField
                                variant="outlined"
                                label="Zip Code"
                                type="text"
                                margin="dense"
                                fullWidth
                                {...register('zipCode')}
                                error={!!errors.label?.en}
                                helperText={errors.label?.en?.message}
                            />
                            <AutocompleteWithRecordOptions
                                options={careTeamMembers?.careTeamMembersV2.results ?? []}
                                valueKey="id"
                                labelKey="nameWithType"
                                name="prerequisiteTeamMembers"
                                label="Prerequisite Team Members"
                                placeholder="Select Members..."
                                control={control}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <OutlinedSection title="Eligibility Check">
                                <ReactHookFormSelect
                                    control={control}
                                    name="eligibilityCheck"
                                    variant="outlined"
                                    defaultValue=""
                                    label="Eligibility Check"
                                    fullWidth
                                    margin="dense"
                                    error={!!errors.careTeamMemberTypeId}
                                >
                                    {Array.from(ELIGIBILITY_CHECK_VARIANTS.keys()).map(key => (
                                        <MenuItem
                                            key={key}
                                            value={ELIGIBILITY_CHECK_VARIANTS.get(key)}
                                        >
                                            {key}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                <Typography className={classes.leftIndent}>
                                    Recheck (optional)
                                </Typography>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Typography className={classes.leftIndent}>
                                            Every
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            margin="dense"
                                            {...register('eligibilityRecheckDays')}
                                            className={classes.numberField}
                                            error={Boolean(errors.eligibilityRecheckDays)}
                                            defaultValue={0}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>Days</Typography>
                                    </Grid>
                                </Grid>
                            </OutlinedSection>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <OutlinedSection title="Consent">
                                <Controller
                                    name="consentRequired"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <FormControlLabel
                                            label="Consent Required"
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={e => onChange(e.target.checked)}
                                                />
                                            }
                                        />
                                    )}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Consent Agreement"
                                    type="text"
                                    margin="dense"
                                    multiline
                                    minRows={10}
                                    fullWidth
                                    {...register('consentAgreement')}
                                />
                            </OutlinedSection>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        data-test={CareTeamMemberEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
            <Dialog
                scroll="body"
                open={showCareTeamContactModal}
                fullWidth
                maxWidth="lg"
                aria-labelledby="form-dialog-title"
            >
                <CareTeamContactModal
                    id={watch('careTeamMemberContactId')}
                    isOverrideMode
                    setOpen={setShowCareTeamContactModal}
                    overrideCB={overrideCB}
                    defaultOverridenContactValues={overridenContact}
                />
            </Dialog>
        </>
    );
};

export default CareTeamMemberModal;

import { useState } from 'react';
import {
    useGetResolvedTagsForPatientTranscriptQuery,
    useGetTriggerInterventionStartEpisodesForPatientTranscriptQuery,
    useGetTriggerInterventionEndEpisodesForPatientTranscriptQuery,
    useGetTriggerInterventionSetProfileValueForPatientTranscriptQuery,
    useGetTriggerInterventionSendMessageCenterForPatientTranscriptQuery,
    useGetTriggerInterventionCreateAdvocateTaskForPatientTranscriptQuery,
    useGetExpressionEvaluatedForPatientTranscriptQuery,
    useGetTriggerFiredEventsForPatientTranscriptQuery,
    useGetTriggerInterventionClearProfileValueForPatientTranscriptQuery,
    useGetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery,
} from '~/schemaTypes';
import type { DateRangeFilter } from '../types';

export const usePatientTranscriptQueries = (
    patientId: string,
    dateRangeFilter: DateRangeFilter,
) => {
    const [eventPaginationTokens, setEventPaginationTokens] = useState<{
        resolvedTags?: string;
        triggerFired?: string;
        expressionEvaluated?: string;
        interventionStartEpisode?: string;
        interventionEndEpisode?: string;
        interventionSetProfileValue?: string;
        interventionSendMessageCenter?: string;
        interventionCreateAdvocateTask?: string;
        interventionClearProfileValue?: string;
        interventionClearProfileChoice?: string;
    }>({});

    const {
        data: resolvedTagEvents,
        loading: loadingResolvedTagEvents,
        fetchMore: fetchMoreResolvedTags,
    } = useGetResolvedTagsForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.resolvedTags,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionStartEpisodes,
        loading: loadingTriggerInterventionStartEpisodes,
        fetchMore: fetchMoreStartEpisodes,
    } = useGetTriggerInterventionStartEpisodesForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.interventionStartEpisode,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionEndEpisodes,
        loading: loadingTriggerInterventionEndEpisodes,
        fetchMore: fetchMoreEndEpisodes,
    } = useGetTriggerInterventionEndEpisodesForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.interventionEndEpisode,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionSetProfileValue,
        loading: loadingTriggerInterventionSetProfileValue,
        fetchMore: fetchMoreSetProfileValue,
    } = useGetTriggerInterventionSetProfileValueForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.interventionSetProfileValue,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionSendMessageCenter,
        loading: loadingTriggerInterventionSendMessageCenter,
        fetchMore: fetchMoreSendMessageCenter,
    } = useGetTriggerInterventionSendMessageCenterForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.interventionSendMessageCenter,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionCreateAdvocateTask,
        loading: loadingTriggerInterventionCreateAdvocateTask,
        fetchMore: fetchMoreCreateAdvocateTask,
    } = useGetTriggerInterventionCreateAdvocateTaskForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.interventionCreateAdvocateTask,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: expressionEvaluated,
        loading: loadingExpressionEvaluated,
        fetchMore: fetchMoreExpressionEvaluated,
    } = useGetExpressionEvaluatedForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.expressionEvaluated,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerFiredEvents,
        loading: loadingTriggerFiredEvents,
        fetchMore: fetchMoreTriggerFired,
    } = useGetTriggerFiredEventsForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.triggerFired,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionClearProfileValue,
        loading: loadingTriggerInterventionClearProfileValue,
        fetchMore: fetchMoreClearProfileValue,
    } = useGetTriggerInterventionClearProfileValueForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.interventionClearProfileValue,
                ...dateRangeFilter,
            },
        },
    });

    const {
        data: triggerInterventionClearProfileChoice,
        loading: loadingTriggerInterventionClearProfileChoice,
        fetchMore: fetchMoreClearProfileChoice,
    } = useGetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery({
        variables: {
            input: {
                patientId,
                continuationToken: eventPaginationTokens.interventionClearProfileChoice,
                ...dateRangeFilter,
            },
        },
    });

    return {
        queries: {
            resolvedTagEvents,
            triggerInterventionStartEpisodes,
            triggerInterventionEndEpisodes,
            triggerInterventionSetProfileValue,
            triggerInterventionSendMessageCenter,
            triggerInterventionCreateAdvocateTask,
            expressionEvaluated,
            triggerFiredEvents,
            triggerInterventionClearProfileValue,
            triggerInterventionClearProfileChoice,
        },
        loading: {
            loadingResolvedTagEvents,
            loadingTriggerInterventionStartEpisodes,
            loadingTriggerInterventionEndEpisodes,
            loadingTriggerInterventionSetProfileValue,
            loadingTriggerInterventionSendMessageCenter,
            loadingTriggerInterventionCreateAdvocateTask,
            loadingExpressionEvaluated,
            loadingTriggerFiredEvents,
            loadingTriggerInterventionClearProfileValue,
            loadingTriggerInterventionClearProfileChoice,
        },
        pagination: {
            eventPaginationTokens,
            setEventPaginationTokens,
        },
        fetchMore: {
            fetchMoreResolvedTags,
            fetchMoreStartEpisodes,
            fetchMoreEndEpisodes,
            fetchMoreSetProfileValue,
            fetchMoreSendMessageCenter,
            fetchMoreCreateAdvocateTask,
            fetchMoreExpressionEvaluated,
            fetchMoreTriggerFired,
            fetchMoreClearProfileValue,
            fetchMoreClearProfileChoice,
        },
    };
};

import { FormLabel, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../styles';

type ToggleButtonProps = {
    label: string;
    onChange: (event: React.MouseEvent<HTMLElement>, value: boolean) => void;
    value?: boolean;
    fullWidth?: boolean;
    reversedButtons?: boolean;
};

const ToggleButtonSwitch: React.FC<ToggleButtonProps> = props => {
    const { classes } = useStyles();
    const { label, onChange, value, fullWidth, reversedButtons } = props;
    return (
        <Grid container direction="column" paddingX={2} className={classes.toggle}>
            <FormLabel>
                <Typography variant="body1" color="black">
                    {label}
                </Typography>
            </FormLabel>
            <Grid container item xs={4} md={4}>
                {reversedButtons ? (
                    <ToggleButtonGroup
                        exclusive
                        onChange={onChange}
                        value={value}
                        color="primary"
                        style={{
                            width: fullWidth ? '100%' : '',
                            minWidth: fullWidth ? '100%' : '',
                        }}
                    >
                        <ToggleButton value={false} fullWidth>
                            No
                        </ToggleButton>
                        <ToggleButton fullWidth value>
                            Yes
                        </ToggleButton>
                    </ToggleButtonGroup>
                ) : (
                    <ToggleButtonGroup
                        exclusive
                        onChange={onChange}
                        value={value}
                        color="primary"
                        style={{
                            width: fullWidth ? '100%' : '',
                            minWidth: fullWidth ? '100%' : '',
                        }}
                    >
                        <ToggleButton fullWidth value>
                            Yes
                        </ToggleButton>
                        <ToggleButton value={false} fullWidth>
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                )}
            </Grid>
        </Grid>
    );
};

export default ToggleButtonSwitch;

import type { TranscriptCard } from '../types';

export const isTrackerHistory = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'TrackerHistory' }> => {
    return card.__typename === 'TrackerHistory';
};

export const isProfileValueHistory = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'ProfileValueHistory' }> => {
    return card.__typename === 'ProfileValueHistory';
};

export const isPatientNote = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'PatientNotes' }> => {
    return card.__typename === 'PatientNotes';
};

export const isAdvocateTask = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'AdvocateTask' }> => {
    return card.__typename === 'AdvocateTask';
};

export const isSurveyResponse = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'SurveyResponse' }> => {
    return card.__typename === 'SurveyResponse';
};

export const isChatMessage = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'ChatMessage' }> => {
    return card.__typename === 'ChatMessage';
};

export const isSentMessage = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'SentMessage' }> => {
    return card.__typename === 'SentMessage';
};

export const isResolvedTagEvent = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'ResolvedTagEvent' }> => {
    return card.__typename === 'ResolvedTagEvent';
};

export const isTriggerInterventionStartEpisode = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'TriggerInterventionStartEpisodeEvent' }> => {
    return card.__typename === 'TriggerInterventionStartEpisodeEvent';
};

export const isTriggerInterventionEndEpisode = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'TriggerInterventionEndEpisodeEvent' }> => {
    return card.__typename === 'TriggerInterventionEndEpisodeEvent';
};

export const isTriggerInterventionSetProfileValue = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'TriggerInterventionSetProfileValueEvent' }> => {
    return card.__typename === 'TriggerInterventionSetProfileValueEvent';
};

export const isTriggerInterventionSendMessageCenter = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'TriggerInterventionSendMessageCenterEvent' }> => {
    return card.__typename === 'TriggerInterventionSendMessageCenterEvent';
};

export const isTriggerInterventionCreateAdvocateTask = (
    card: TranscriptCard,
): card is Extract<
    TranscriptCard,
    { __typename: 'TriggerInterventionCreateAdvocateTaskEvent' }
> => {
    return card.__typename === 'TriggerInterventionCreateAdvocateTaskEvent';
};

export const isExpressionEvaluated = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'ExpressionEvaluatedEvent' }> => {
    return card.__typename === 'ExpressionEvaluatedEvent';
};

export const isTriggerFired = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'TriggerFiredEvent' }> => {
    return card.__typename === 'TriggerFiredEvent';
};

export const isTriggerInterventionClearProfileValue = (
    card: TranscriptCard,
): card is Extract<TranscriptCard, { __typename: 'TriggerInterventionClearProfileValueEvent' }> => {
    return card.__typename === 'TriggerInterventionClearProfileValueEvent';
};

export const isTriggerInterventionClearProfileChoice = (
    card: TranscriptCard,
): card is Extract<
    TranscriptCard,
    { __typename: 'TriggerInterventionClearProfileChoiceEvent' }
> => {
    return card.__typename === 'TriggerInterventionClearProfileChoiceEvent';
};

// ... continue with other type guards

import { Checkbox, Grid, ListItemText, Menu, MenuItem } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useCallback } from 'react';
import { PatientsHiddenColumnsAtom } from '../Patients.context';
import { VC_COLUMNS, DOULA_COLUMNS, GENERAL_COLUMNS, GridColumns } from '../types';

type FilterColumnsModalProps = {
    hiddenColumnsAnchorEl: null | HTMLElement;
    setHiddenColumnsAnchorEl: (value: React.SetStateAction<null | HTMLElement>) => void;
    isDoulaView?: boolean;
    isAffiliateCareView?: boolean;
};

export const FilterColumnsModal: React.FC<FilterColumnsModalProps> = ({
    hiddenColumnsAnchorEl,
    setHiddenColumnsAnchorEl,
    isDoulaView = false,
    isAffiliateCareView = false,
}) => {
    const [hiddenColumns, setHiddenColumns] = useAtom(PatientsHiddenColumnsAtom);
    const showColumn = useCallback(
        (column: keyof typeof hiddenColumns) => {
            if (isAffiliateCareView) {
                return VC_COLUMNS.includes(column);
            }
            if (isDoulaView) {
                return DOULA_COLUMNS.includes(column);
            }
            return GENERAL_COLUMNS.includes(column);
        },
        [isAffiliateCareView, isDoulaView],
    );
    return (
        <Grid container spacing={0}>
            <Grid item>
                <Menu
                    open={Boolean(hiddenColumnsAnchorEl)}
                    onClose={() => setHiddenColumnsAnchorEl(null)}
                    anchorEl={hiddenColumnsAnchorEl}
                    style={{ marginTop: '48px' }}
                >
                    {showColumn(GridColumns.invitationCode) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    invitationCode: !state.invitationCode,
                                }));
                                localStorage.setItem(
                                    'invitationCodeHidden',
                                    String(!hiddenColumns.invitationCode),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.invitationCode} />
                            <ListItemText primary="Invitation Code" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.externalId) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    externalId: !state.externalId,
                                }));
                                localStorage.setItem(
                                    'externalIdHidden',
                                    String(!hiddenColumns.externalId),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.externalId} />
                            <ListItemText primary="External ID" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.firstName) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    firstName: !state.firstName,
                                }));
                                localStorage.setItem(
                                    'firstNameHidden',
                                    String(!hiddenColumns.firstName),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.firstName} />
                            <ListItemText primary="First Name" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.lastName) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    lastName: !state.lastName,
                                }));
                                localStorage.setItem(
                                    'lastNameHidden',
                                    String(!hiddenColumns.lastName),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.lastName} />
                            <ListItemText primary="Last Name" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.riskScore) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    riskScore: !state.riskScore,
                                }));
                                localStorage.setItem(
                                    'riskScoreHidden',
                                    String(!hiddenColumns.riskScore),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.riskScore} />
                            <ListItemText primary="Risk Score" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.createdAt) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    createdAt: !state.createdAt,
                                }));
                                localStorage.setItem(
                                    'createdAtHidden',
                                    String(!hiddenColumns.createdAt),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.createdAt} />
                            <ListItemText primary="Created" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.assignedHealthAdvocate) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    assignedHealthAdvocate: !state.assignedHealthAdvocate,
                                }));
                                localStorage.setItem(
                                    'assignedHealthAdvocateHidden',
                                    String(!hiddenColumns.assignedHealthAdvocate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.assignedHealthAdvocate} />
                            <ListItemText primary="Assigned Health Advocate" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.openAdvocateTaskCount) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    openAdvocateTaskCount: !state.openAdvocateTaskCount,
                                }));
                                localStorage.setItem(
                                    'openAdvocateTaskCountHidden',
                                    String(!hiddenColumns.openAdvocateTaskCount),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.openAdvocateTaskCount} />
                            <ListItemText primary="Open Tasks" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.openCriticalAdvocateTaskCount) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    openCriticalAdvocateTaskCount:
                                        !state.openCriticalAdvocateTaskCount,
                                }));
                                localStorage.setItem(
                                    'openCriticalAdvocateTaskCountHidden',
                                    String(!hiddenColumns.openCriticalAdvocateTaskCount),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.openCriticalAdvocateTaskCount} />
                            <ListItemText primary="Open Critical Tasks" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.patientCallStatus) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    patientCallStatus: !state.patientCallStatus,
                                }));
                                localStorage.setItem(
                                    'patientCallStatusHidden',
                                    String(!hiddenColumns.patientCallStatus),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.patientCallStatus} />
                            <ListItemText primary="Call Status" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.birthDate) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    birthDate: !state.birthDate,
                                }));
                                localStorage.setItem(
                                    'birthDateHidden',
                                    String(!hiddenColumns.birthDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.birthDate} />
                            <ListItemText primary="Birth Date" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.organization) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    organization: !state.organization,
                                }));
                                localStorage.setItem(
                                    'practiceHidden',
                                    String(!hiddenColumns.organization),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.organization} />
                            <ListItemText primary="Organization" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.clinic) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    clinic: !state.clinic,
                                }));
                                localStorage.setItem(
                                    'practiceHidden',
                                    String(!hiddenColumns.clinic),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.clinic} />
                            <ListItemText primary="Clinic" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.onboardingCompleteDate) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    onboardingCompleteDate: !state.onboardingCompleteDate,
                                }));
                                localStorage.setItem(
                                    'onboardingCompleteDateHidden',
                                    String(!hiddenColumns.onboardingCompleteDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.onboardingCompleteDate} />
                            <ListItemText primary="Onboarding Completed" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.isPregnant) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    isPregnant: !state.isPregnant,
                                }));
                                localStorage.setItem(
                                    'isPregnantHidden',
                                    String(!hiddenColumns.isPregnant),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.isPregnant} />
                            <ListItemText primary="Is Pregnant" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.dueDate) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    dueDate: !state.dueDate,
                                }));
                                localStorage.setItem(
                                    'dueDateHidden',
                                    String(!hiddenColumns.dueDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.dueDate} />
                            <ListItemText primary="Due Date" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.deliveryDate) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    deliveryDate: !state.deliveryDate,
                                }));
                                localStorage.setItem(
                                    'deliveryDateHidden',
                                    String(!hiddenColumns.deliveryDate),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.deliveryDate} />
                            <ListItemText primary="Delivery Date" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.email) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    email: !state.email,
                                }));
                                localStorage.setItem('emailHidden', String(!hiddenColumns.email));
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.email} />
                            <ListItemText primary="Email" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.phoneNumber) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    phoneNumber: !state.phoneNumber,
                                }));
                                localStorage.setItem(
                                    'phoneNumberHidden',
                                    String(!hiddenColumns.phoneNumber),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.phoneNumber} />
                            <ListItemText primary="Phone Number" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.language) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    language: !state.language,
                                }));
                                localStorage.setItem('language', String(!hiddenColumns.language));
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.language} />
                            <ListItemText primary="Registration Language" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.id) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    id: !state.id,
                                }));
                                localStorage.setItem('id', String(!hiddenColumns.id));
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.id} />
                            <ListItemText primary="Patient ID" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.affiliates) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    affiliates: !state.affiliates,
                                }));
                                localStorage.setItem(
                                    'affiliates',
                                    String(!hiddenColumns.affiliates),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.affiliates} />
                            <ListItemText primary="Affiliate(s)" />
                        </MenuItem>
                    )}
                    {showColumn(GridColumns.coverageExpiration) && (
                        <MenuItem
                            onClick={() => {
                                setHiddenColumns(state => ({
                                    ...state,
                                    coverageExpiration: !state.coverageExpiration,
                                }));
                                localStorage.setItem(
                                    'coverageExpiration',
                                    String(!hiddenColumns.coverageExpiration),
                                );
                            }}
                        >
                            <Checkbox checked={!hiddenColumns.coverageExpiration} />
                            <ListItemText primary="Coverage Expiration" />
                        </MenuItem>
                    )}
                </Menu>
            </Grid>
        </Grid>
    );
};

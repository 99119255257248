import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Grid, Typography, TablePagination, Checkbox, FormControlLabel } from '@mui/material';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import moment from 'moment';
import {
    OrderByDirectionEnum,
    usePatientEstimatesSubmittedsV2LazyQuery,
    PatientEstimatesSubmittedStatus,
} from '~/schemaTypes';
import tableIcons from '~/helpers/tableIcons';
import { PAGESIZE } from '~/constants';
import useDownload from './useDownload';
import { PatientEstimatesSubmitted } from './types';
import ClaimStatus from './components/ClaimStatus';
import Copy from './components/Copy';

const INCOMPLETE_FILTER = [
    PatientEstimatesSubmittedStatus.Ready,
    PatientEstimatesSubmittedStatus.InProgress,
    PatientEstimatesSubmittedStatus.Error,
];

const POLL_INTERVAL = 30 * 1000;

const PatientEstimatesSubmittedList = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [page, setPage] = useState<number>(0);
    const [incompleteFilter, setIncompleteFilter] = useState<boolean>(false);
    const { downloadHandler } = useDownload();
    const [
        fetchSubmittedEstimates,
        { refetch, data: submittedEstimates, loading, stopPolling, startPolling },
    ] = usePatientEstimatesSubmittedsV2LazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
    });
    const fetchEstimatesPayload = useMemo(
        () => ({
            variables: {
                input: {
                    filter: {
                        fieldsInList: {
                            ...(incompleteFilter && { status: INCOMPLETE_FILTER }),
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        }),
        [page, rowsPerPage, incompleteFilter],
    );
    useEffect(() => {
        fetchSubmittedEstimates(fetchEstimatesPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchEstimatesPayload]);
    useEffect(() => {
        const inCompletedEstimate = submittedEstimates?.patientEstimatesSubmittedsV2.results.find(
            e =>
                e.status &&
                [
                    PatientEstimatesSubmittedStatus.Ready,
                    PatientEstimatesSubmittedStatus.InProgress,
                ].includes(e.status),
        );
        if (inCompletedEstimate) {
            startPolling(POLL_INTERVAL);
        } else {
            stopPolling();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedEstimates]);
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const reUploadCb = useCallback(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<PatientEstimatesSubmitted>
                    icons={tableIcons as MaterialTableProps<any>['icons']}
                    columns={[
                        {
                            title: 'ID',
                            field: 'id',
                            render: ({ id }) => <Copy id={id} />,
                            sorting: false,
                            width: 30,
                        },
                        {
                            title: 'Invoice ID',
                            field: 'invoiceId',
                            render: ({ claim }) =>
                                claim?.affiliateInvoiceId && <Copy id={claim.affiliateInvoiceId} />,
                            sorting: false,
                            width: 30,
                        },
                        {
                            title: 'Claim ID',
                            field: 'claimId',
                            render: ({ claimId }) => claimId && <Copy id={claimId} />,
                            sorting: false,
                            width: 30,
                        },
                        {
                            title: 'Date Submitted',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>
                                    {moment(createdAt).local().format('MM-DD-YYYY hh:mm A')}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Patient First Name',
                            field: 'PatFName',
                            render: ({ patient }) => (
                                <Typography data-test={patient?.firstName}>
                                    {patient?.firstName}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Patient Last Name',
                            field: 'PatLName',
                            render: ({ patient }) => (
                                <Typography data-test={patient?.lastName}>
                                    {patient?.lastName}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Type',
                            field: 'type',
                            render: ({ type }) => <Typography data-test={type}>{type}</Typography>,
                            sorting: false,
                        },
                        {
                            title: 'Status',
                            field: 'status',
                            render: claim => (
                                <ClaimStatus
                                    claim={claim}
                                    reUploadCb={reUploadCb}
                                    downloadHandler={downloadHandler}
                                />
                            ),
                            sorting: false,
                            minWidth: 350,
                        },
                    ]}
                    title="VC Patient Estimates Submitted"
                    data={
                        submittedEstimates?.patientEstimatesSubmittedsV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paginationPosition: 'both',
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                    }}
                    isLoading={loading}
                    localization={{ header: { actions: '' } }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            isFreeAction: true,
                            icon: () => (
                                <FormControlLabel
                                    label="Show only Incomplete Patient Estimates"
                                    control={
                                        <Checkbox
                                            checked={incompleteFilter}
                                            onChange={event =>
                                                setIncompleteFilter(event.target.checked)
                                            }
                                        />
                                    }
                                />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick: () => {},
                        },
                    ]}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={submittedEstimates?.patientEstimatesSubmittedsV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                />
            </Grid>
        </div>
    );
};

export default PatientEstimatesSubmittedList;

import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import { EligibilityCheckResultsV2Query } from '~/schemaTypes';
import { JsonViewer } from '@textea/json-viewer';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';

export type EligibilityCheckResult =
    EligibilityCheckResultsV2Query['eligibilityCheckResultsV2']['results'][0];

const EligibilityCheckResultsViewModal = ({
    onClose,
    eligibilityCheckResult,
}: {
    onClose: () => void;
    eligibilityCheckResult: EligibilityCheckResult | null;
}) => {
    return (
        <Dialog
            open={Boolean(eligibilityCheckResult)}
            onClose={onClose}
            fullWidth
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitleWithClose id="eligibility-check-view-modal" onClose={onClose}>
                Eligibility Check Result
            </DialogTitleWithClose>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={4}>
                        <TextField
                            label="App Bundle Id"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.appBundleId ?? ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Unit Id 1"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.unitId ?? ''}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <TextField
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.firstName ?? ''}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.lastName ?? ''}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <TextField
                            label="Config Version"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.configVersion ?? ''}
                        />
                    </Grid>
                    <FormControlLabel
                        control={<Checkbox checked={eligibilityCheckResult?.success ?? false} />}
                        label="Is Success"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={eligibilityCheckResult?.isEligible ?? false} />}
                        label="Is Eligible"
                    />
                </Grid>

                <OutlinedSection title="Request Body">
                    <JsonViewer value={JSON.parse(eligibilityCheckResult?.requestBody ?? '{}')} />
                </OutlinedSection>
                <OutlinedSection title="Response Body">
                    <JsonViewer value={JSON.parse(eligibilityCheckResult?.responseBody ?? '{}')} />
                </OutlinedSection>
                <OutlinedSection title="Logs">
                    <ul>
                        {eligibilityCheckResult?.logs?.map(log => (
                            <li key={log}>{log}</li>
                        ))}
                    </ul>
                </OutlinedSection>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EligibilityCheckResultsViewModal;

import {
    Autocomplete,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import DateInput from '~/components/DateInput/DateInput';
import ButtonContainer from '../components/ButtonContainer';
import FooterDivider from '../components/FooterDivider';
import SupportBlock from '../components/SupportBlock';
import { useVCFContext } from '../hooks/useVCF';
import useStyles from '../styles';
import { EMPTY_GENDER_VALUE, inputGenders, RelationshipToInsuredEnum } from '../types';

type PatientInfoProps = {
    stepHandler: () => void;
    setSelfSelected: (value: boolean) => void;
    stepLoading: boolean;
    selfSelected: boolean;
    selfCheck: boolean;
    isEligibilityRecheck?: boolean;
};

const PatientInfo: React.FC<PatientInfoProps> = props => {
    const {
        stepLoading,
        setSelfSelected,
        stepHandler,
        selfSelected,
        selfCheck,
        isEligibilityRecheck,
    } = props;
    const { classes } = useStyles();
    const { isMobileView } = useVCFContext();
    const {
        register,
        formState: { errors },
        control,
        setValue,
        getValues,
    } = useFormContext();
    return (
        <>
            <Grid item xs={12} className={classes.subHeader} paddingBottom={isMobileView ? 2 : 4}>
                <Typography paragraph variant="h6">
                    Patient Information
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" rowSpacing={{ md: 2, xs: 1 }}>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="First Name"
                            fullWidth
                            {...register('firstName')}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message as string}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Last Name"
                            fullWidth
                            {...register('lastName')}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message as string}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Controller
                            control={control}
                            name="birthDate"
                            render={({ field }) => (
                                <DateInput
                                    label="Date of Birth"
                                    field={field}
                                    error={errors.birthDate as FieldError}
                                    inputProps={{
                                        fullWidth: true,
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Autocomplete
                            id="sex"
                            disablePortal
                            options={inputGenders.filter(option => option.id !== '')}
                            value={
                                getValues
                                    ? inputGenders.find(v => v.id === getValues('sex'))
                                    : EMPTY_GENDER_VALUE
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name="sex"
                                    error={!!errors.sex}
                                    helperText={errors.sex?.message as string}
                                    label="Sex"
                                />
                            )}
                            {...register('sex')}
                            onChange={(event, value) => {
                                setValue('sex', value?.id || '', { shouldValidate: true });
                            }}
                            onBlur={e => {
                                e.preventDefault();
                            }}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label={`Phone Number${selfCheck ? ' (optional)' : ''}`}
                            fullWidth
                            {...register('phoneNumber')}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber?.message as string}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Email"
                            fullWidth
                            {...register('email')}
                            error={!!errors.email}
                            helperText={errors.email?.message as string}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Insurance ID Number"
                            fullWidth
                            {...register('insuranceId')}
                            error={!!errors.insuranceId}
                            helperText={errors.insuranceId?.message as string}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            label="Insurance Group Number"
                            fullWidth
                            {...register('insuranceGroup')}
                            error={!!errors.insuranceGroup}
                            helperText={errors.insuranceGroup?.message as string}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Autocomplete
                            id="patientRelationshipToInsured"
                            disablePortal
                            options={Object.values(RelationshipToInsuredEnum)}
                            value={
                                getValues
                                    ? Object.values(RelationshipToInsuredEnum).find(
                                          v => v === getValues('patientRelationshipToInsured'),
                                      )
                                    : ''
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name="patientRelationshipToInsured"
                                    error={!!errors.patientRelationshipToInsured}
                                    helperText={
                                        errors.patientRelationshipToInsured?.message as string
                                    }
                                    label="Relationship To Primary Insured"
                                />
                            )}
                            {...register('patientRelationshipToInsured')}
                            onChange={(event, value) => {
                                setValue('patientRelationshipToInsured', value || '', {
                                    shouldValidate: true,
                                });
                                setSelfSelected(value === 'self' || !value);
                            }}
                            onBlur={e => {
                                e.preventDefault();
                            }}
                        />
                    </Grid>
                    <Grid item md={5} xs={12} />
                    <Grid
                        item
                        md={5}
                        xs={12}
                        className={selfSelected ? classes.hide : classes.show}
                    >
                        <TextField
                            label="Insured First Name"
                            fullWidth
                            {...register('insuredFirstName')}
                            error={!!errors.insuredFirstName}
                            helperText={errors.insuredFirstName?.message as string}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...(selfSelected && { InputLabelProps: { shrink: true } })}
                        />
                    </Grid>
                    <Grid
                        item
                        md={5}
                        xs={12}
                        className={selfSelected ? classes.hide : classes.show}
                    >
                        <TextField
                            label="Insured Last Name"
                            fullWidth
                            {...register('insuredLastName')}
                            error={!!errors.insuredLastName}
                            helperText={errors.insuredLastName?.message as string}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...(selfSelected && { InputLabelProps: { shrink: true } })}
                        />
                    </Grid>
                    <Grid
                        item
                        md={5}
                        xs={12}
                        className={selfSelected ? classes.hide : classes.show}
                    >
                        <Controller
                            control={control}
                            name="subscriberSex"
                            defaultValue=""
                            render={({ field: { onChange, value } }) => (
                                <FormControl fullWidth>
                                    <InputLabel id="subscriberSex">Insured Sex</InputLabel>
                                    <Select
                                        value={value}
                                        fullWidth
                                        onChange={e => onChange(e.target.value)}
                                        MenuProps={{
                                            classes: { paper: classes.menuPaper },
                                            marginThreshold: null,
                                            disableScrollLock: false,
                                            anchorOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                    overflowY: 'auto',
                                                },
                                            },
                                        }}
                                        error={!!errors.subscriberSex}
                                    >
                                        <MenuItem key="female" value="female">
                                            Female
                                        </MenuItem>
                                        <MenuItem key="male" value="male">
                                            Male
                                        </MenuItem>
                                        <MenuItem key="non-binary" value="non-binary">
                                            Non-Binary
                                        </MenuItem>
                                        <MenuItem key="other" value="other">
                                            Other
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        md={5}
                        xs={12}
                        className={selfSelected ? classes.hide : classes.show}
                    >
                        <Controller
                            control={control}
                            name="subscriberBirthDate"
                            render={({ field }) => (
                                <DateInput
                                    label="Insured Date of Birth"
                                    field={field}
                                    error={errors.subscriberBirthDate as FieldError}
                                    inputProps={{
                                        fullWidth: true,
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <FooterDivider isMobileView={isMobileView} />
            <ButtonContainer
                showWide={isEligibilityRecheck}
                nextButton={{
                    handler: stepHandler,
                    label: isEligibilityRecheck ? 'Refresh Eligibility' : 'Next',
                    disabled: stepLoading,
                    loading: stepLoading,
                }}
            />
            <SupportBlock isMobileView={isMobileView} />
        </>
    );
};

export default PatientInfo;

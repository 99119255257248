import React from 'react';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import type { TranscriptCardProps } from '../types';
import {
    isTrackerHistory,
    isProfileValueHistory,
    isPatientNote,
    isAdvocateTask,
    isSurveyResponse,
    isChatMessage,
    isSentMessage,
    isResolvedTagEvent,
    isTriggerInterventionStartEpisode,
    isTriggerInterventionEndEpisode,
    isTriggerInterventionSetProfileValue,
    isTriggerInterventionSendMessageCenter,
    isTriggerInterventionCreateAdvocateTask,
    isExpressionEvaluated,
    isTriggerFired,
    isTriggerInterventionClearProfileValue,
    isTriggerInterventionClearProfileChoice,
} from '../utils/typeGuards';
import { TrackerCard } from '../TranscriptCards/TrackerCard/TrackerCard';
import { ProfileValueCard } from '../TranscriptCards/ProfileValueCard/ProfileValueCard';
import { PatientNoteCard } from '../TranscriptCards/PatientNoteCard/PatientNoteCard';
import { AdvocateTaskCard } from '../TranscriptCards/AdvocateTaskCard/AdvocateTaskCard';
import { SurveyResponseCard } from '../TranscriptCards/SurveyResponseCard/SurveyResponseCard';
import { ChatMessageCard } from '../TranscriptCards/ChatMessageCard/ChatMessageCard';
import { SentMessageCard } from '../TranscriptCards/SentMessageCard/SentMessageCard';
import { ResolvedTagEventCard } from '../TranscriptCards/ResolvedTagEventCard/ResolvedTagEventCard';
import { TriggerInterventionStartEpisodeCard } from '../TranscriptCards/TriggerInterventionCards/TriggerInterventionStartEpisodeCard';
import { TriggerInterventionEndEpisodeCard } from '../TranscriptCards/TriggerInterventionCards/TriggerInterventionEndEpisodeCard';
import { TriggerInterventionSetProfileValueCard } from '../TranscriptCards/TriggerInterventionCards/TriggerInterventionSetProfileValueCard';
import { TriggerInterventionSendMessageCenterCard } from '../TranscriptCards/TriggerInterventionCards/TriggerInterventionSendMessageCenterCard';
import { TriggerInterventionCreateAdvocateTaskCard } from '../TranscriptCards/TriggerInterventionCards/TriggerInterventionCreateAdvocateTaskCard';
import { ExpressionEvaluatedCard } from '../TranscriptCards/TriggerInterventionCards/ExpressionEvaluatedCard';
import { TriggerFiredEventCard } from '../TranscriptCards/TriggerInterventionCards/TriggerFiredEventCard';
import { TriggerInterventionClearProfileValueCard } from '../TranscriptCards/TriggerInterventionCards/TriggerInterventionClearProfileValueCard';
import { TriggerInterventionClearProfileChoiceCard } from '../TranscriptCards/TriggerInterventionCards/TriggerInterventionClearProfileChoiceCard';

export const TranscriptCard = ({ card }: TranscriptCardProps) => {
    return (
        <div className="w-[600px]">
            {isTrackerHistory(card) && <TrackerCard tracker={card} />}
            {isProfileValueHistory(card) && <ProfileValueCard profileValue={card} />}
            {isPatientNote(card) && <PatientNoteCard patientNote={card} />}
            {isAdvocateTask(card) && <AdvocateTaskCard advocateTask={card} />}
            {isSurveyResponse(card) && <SurveyResponseCard surveyResponse={card} />}
            {isChatMessage(card) && <ChatMessageCard chatMessage={card} />}
            {isSentMessage(card) && <SentMessageCard sentMessage={card} />}
            {isResolvedTagEvent(card) && <ResolvedTagEventCard resolvedTagEvent={card} />}
            {isTriggerInterventionStartEpisode(card) && (
                <TriggerInterventionStartEpisodeCard intervention={card} />
            )}
            {isTriggerInterventionEndEpisode(card) && (
                <TriggerInterventionEndEpisodeCard intervention={card} />
            )}
            {isTriggerInterventionSetProfileValue(card) && (
                <TriggerInterventionSetProfileValueCard intervention={card} />
            )}
            {isTriggerInterventionSendMessageCenter(card) && (
                <TriggerInterventionSendMessageCenterCard intervention={card} />
            )}
            {isTriggerInterventionCreateAdvocateTask(card) && (
                <TriggerInterventionCreateAdvocateTaskCard intervention={card} />
            )}
            {isExpressionEvaluated(card) && <ExpressionEvaluatedCard event={card} />}
            {isTriggerFired(card) && <TriggerFiredEventCard event={card} />}
            {isTriggerInterventionClearProfileValue(card) && (
                <TriggerInterventionClearProfileValueCard intervention={card} />
            )}
            {isTriggerInterventionClearProfileChoice(card) && (
                <TriggerInterventionClearProfileChoiceCard intervention={card} />
            )}
            <p className="flex justify-end italic">
                {displayDateWithLocalTimeZoneAbbr({
                    isoDateStr: new Date(card.createdAt).toISOString(),
                })}
            </p>
        </div>
    );
};

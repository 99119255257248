import { useState } from 'react';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import type { DateRangeFilter } from '../types';
import { usePatientTranscriptQueries } from './usePatientTranscriptQueries';

export const useLoadMore = (
    queries: ReturnType<typeof usePatientTranscriptQueries>['queries'],
    fetchMore: ReturnType<typeof usePatientTranscriptQueries>['fetchMore'],
    setEventPaginationTokens: (
        updater: (prev: Record<string, string | undefined>) => Record<string, string | undefined>,
    ) => void,
    dateRangeFilter: DateRangeFilter,
    patientId: string,
) => {
    const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});

    const handleLoadMore = async (queryType: string) => {
        try {
            setLoadingStates(prev => ({ ...prev, [queryType]: true }));

            switch (queryType) {
                case 'resolvedTags':
                    if (queries.resolvedTagEvents?.getResolvedTagsForPatientTranscript?.nextToken) {
                        const result = await fetchMore.fetchMoreResolvedTags({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.resolvedTagEvents
                                            .getResolvedTagsForPatientTranscript.nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getResolvedTagsForPatientTranscript: {
                                        ...fetchMoreResult.getResolvedTagsForPatientTranscript,
                                        items: [
                                            ...(prev.getResolvedTagsForPatientTranscript?.items ||
                                                []),
                                            ...(fetchMoreResult.getResolvedTagsForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            resolvedTags:
                                result.data?.getResolvedTagsForPatientTranscript?.nextToken ??
                                undefined,
                        }));
                    }
                    break;

                case 'interventionStartEpisode':
                    if (
                        queries.triggerInterventionStartEpisodes
                            ?.getTriggerInterventionStartEpisodesForPatientTranscript?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreStartEpisodes({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerInterventionStartEpisodes
                                            .getTriggerInterventionStartEpisodesForPatientTranscript
                                            .nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerInterventionStartEpisodesForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerInterventionStartEpisodesForPatientTranscript,
                                        items: [
                                            ...(prev
                                                .getTriggerInterventionStartEpisodesForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerInterventionStartEpisodesForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            interventionStartEpisode:
                                result.data?.getTriggerInterventionStartEpisodesForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                case 'interventionEndEpisode':
                    if (
                        queries.triggerInterventionEndEpisodes
                            ?.getTriggerInterventionEndEpisodesForPatientTranscript?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreEndEpisodes({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerInterventionEndEpisodes
                                            .getTriggerInterventionEndEpisodesForPatientTranscript
                                            .nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerInterventionEndEpisodesForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerInterventionEndEpisodesForPatientTranscript,
                                        items: [
                                            ...(prev
                                                .getTriggerInterventionEndEpisodesForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerInterventionEndEpisodesForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            interventionEndEpisode:
                                result.data?.getTriggerInterventionEndEpisodesForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                case 'expressionEvaluated':
                    if (
                        queries.expressionEvaluated?.getExpressionEvaluatedForPatientTranscript
                            ?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreExpressionEvaluated({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.expressionEvaluated
                                            .getExpressionEvaluatedForPatientTranscript.nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getExpressionEvaluatedForPatientTranscript: {
                                        ...fetchMoreResult.getExpressionEvaluatedForPatientTranscript,
                                        items: [
                                            ...(prev.getExpressionEvaluatedForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getExpressionEvaluatedForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            expressionEvaluated:
                                result.data?.getExpressionEvaluatedForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                case 'interventionSetProfileValue':
                    if (
                        queries.triggerInterventionSetProfileValue
                            ?.getTriggerInterventionSetProfileValueForPatientTranscript?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreSetProfileValue({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerInterventionSetProfileValue
                                            .getTriggerInterventionSetProfileValueForPatientTranscript
                                            .nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerInterventionSetProfileValueForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerInterventionSetProfileValueForPatientTranscript,
                                        items: [
                                            ...(prev
                                                .getTriggerInterventionSetProfileValueForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerInterventionSetProfileValueForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            interventionSetProfileValue:
                                result.data
                                    ?.getTriggerInterventionSetProfileValueForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                case 'interventionSendMessageCenter':
                    if (
                        queries.triggerInterventionSendMessageCenter
                            ?.getTriggerInterventionSendMessageCenterForPatientTranscript?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreSendMessageCenter({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerInterventionSendMessageCenter
                                            .getTriggerInterventionSendMessageCenterForPatientTranscript
                                            .nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerInterventionSendMessageCenterForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerInterventionSendMessageCenterForPatientTranscript,
                                        items: [
                                            ...(prev
                                                .getTriggerInterventionSendMessageCenterForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerInterventionSendMessageCenterForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            interventionSendMessageCenter:
                                result.data
                                    ?.getTriggerInterventionSendMessageCenterForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                case 'interventionCreateAdvocateTask':
                    if (
                        queries.triggerInterventionCreateAdvocateTask
                            ?.getTriggerInterventionCreateAdvocateTaskForPatientTranscript
                            ?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreCreateAdvocateTask({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerInterventionCreateAdvocateTask
                                            .getTriggerInterventionCreateAdvocateTaskForPatientTranscript
                                            .nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerInterventionCreateAdvocateTaskForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerInterventionCreateAdvocateTaskForPatientTranscript,
                                        items: [
                                            ...(prev
                                                .getTriggerInterventionCreateAdvocateTaskForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerInterventionCreateAdvocateTaskForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            interventionCreateAdvocateTask:
                                result.data
                                    ?.getTriggerInterventionCreateAdvocateTaskForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                case 'triggerFired':
                    if (
                        queries.triggerFiredEvents?.getTriggerFiredEventsForPatientTranscript
                            ?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreTriggerFired({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerFiredEvents
                                            .getTriggerFiredEventsForPatientTranscript.nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerFiredEventsForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerFiredEventsForPatientTranscript,
                                        items: [
                                            ...(prev.getTriggerFiredEventsForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerFiredEventsForPatientTranscript?.items ||
                                                []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            triggerFired:
                                result.data?.getTriggerFiredEventsForPatientTranscript?.nextToken ??
                                undefined,
                        }));
                    }
                    break;

                case 'interventionClearProfileValue':
                    if (
                        queries.triggerInterventionClearProfileValue
                            ?.getTriggerInterventionClearProfileValueForPatientTranscript?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreClearProfileValue({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerInterventionClearProfileValue
                                            .getTriggerInterventionClearProfileValueForPatientTranscript
                                            .nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerInterventionClearProfileValueForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerInterventionClearProfileValueForPatientTranscript,
                                        items: [
                                            ...(prev
                                                .getTriggerInterventionClearProfileValueForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerInterventionClearProfileValueForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            interventionClearProfileValue:
                                result.data
                                    ?.getTriggerInterventionClearProfileValueForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                case 'interventionClearProfileChoice':
                    if (
                        queries.triggerInterventionClearProfileChoice
                            ?.getTriggerInterventionClearProfileChoiceForPatientTranscript
                            ?.nextToken
                    ) {
                        const result = await fetchMore.fetchMoreClearProfileChoice({
                            variables: {
                                input: {
                                    patientId,
                                    continuationToken:
                                        queries.triggerInterventionClearProfileChoice
                                            .getTriggerInterventionClearProfileChoiceForPatientTranscript
                                            .nextToken,
                                    ...dateRangeFilter,
                                },
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                return {
                                    getTriggerInterventionClearProfileChoiceForPatientTranscript: {
                                        ...fetchMoreResult.getTriggerInterventionClearProfileChoiceForPatientTranscript,
                                        items: [
                                            ...(prev
                                                .getTriggerInterventionClearProfileChoiceForPatientTranscript
                                                ?.items || []),
                                            ...(fetchMoreResult
                                                .getTriggerInterventionClearProfileChoiceForPatientTranscript
                                                ?.items || []),
                                        ],
                                    },
                                };
                            },
                        });
                        setEventPaginationTokens(prev => ({
                            ...prev,
                            interventionClearProfileChoice:
                                result.data
                                    ?.getTriggerInterventionClearProfileChoiceForPatientTranscript
                                    ?.nextToken ?? undefined,
                        }));
                    }
                    break;

                default:
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Warning,
                        message: `Unhandled load more case: ${queryType}`,
                    });
                    break;
            }
        } finally {
            setLoadingStates(prev => ({ ...prev, [queryType]: false }));
        }
    };

    return { handleLoadMore, loadingStates };
};

import { Typography } from '@mui/material';
import React from 'react';
import { GetTriggerInterventionCreateAdvocateTaskForPatientTranscriptQuery } from '~/schemaTypes';

type TriggerInterventionCreateAdvocateTask = NonNullable<
    NonNullable<
        GetTriggerInterventionCreateAdvocateTaskForPatientTranscriptQuery['getTriggerInterventionCreateAdvocateTaskForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionCreateAdvocateTaskCard = ({
    intervention,
}: {
    intervention: TriggerInterventionCreateAdvocateTask;
}) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Trigger Intervention Create Task</Typography>
            <p className="m-0">
                {`Task: ${intervention?.event?.data?.advocateTaskTemplate?.label || 'Unknown'}`}
            </p>
            <p className="m-0">
                {`Description: ${
                    intervention?.event?.data?.advocateTaskTemplate?.description || 'Unknown'
                }`}
            </p>
            <p className="m-0">
                {`Priority: ${
                    intervention?.event?.data?.advocateTaskTemplate?.priority || 'Unknown'
                }`}
            </p>
        </div>
    );
};

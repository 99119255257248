import React from 'react';
import { FormControl, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { CARD_TYPES } from '../constants';
import type { CardTypes } from '../types';

type CardTypeFilterProps = {
    cardTypes: CardTypes;
    setCardTypes: (cardTypes: CardTypes) => void;
};

export const CardTypeFilter = ({ cardTypes, setCardTypes }: CardTypeFilterProps) => {
    return (
        <FormControl fullWidth variant="standard">
            <Select
                labelId="card-types-label"
                id="card-types-select"
                variant="outlined"
                multiple
                value={CARD_TYPES.filter(type => cardTypes[type])}
                onChange={e => {
                    const selectedTypes = e.target.value as string[];
                    const newCardTypes = CARD_TYPES.reduce((acc, type) => {
                        acc[type] = selectedTypes.includes(type);
                        return acc;
                    }, {} as CardTypes);
                    setCardTypes(newCardTypes);
                }}
                renderValue={values => {
                    if (values.length === 0) {
                        return <em>Select card types</em>;
                    }
                    if (values.length === 1) {
                        return values[0];
                    }
                    return `${values[0]}...`;
                }}
                displayEmpty
            >
                {CARD_TYPES.map(type => (
                    <MenuItem key={type} value={type}>
                        <Checkbox checked={cardTypes[type]} />
                        <ListItemText primary={type} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

import { Button } from '@mui/material';
import React from 'react';
import { COLOR_ENV_IAT, COLOR_ENV_STAGE, COLOR_ENV_UAT } from '~/components/EnvLabel/EnvLabel';
import { displayDateWithAbbrTimeZone, isToday } from '~/helpers';
import { useUser, useUserPermissions } from '~/hooks';

export const AppBuildDisplay: React.FC<{
    id: string;
    title: string;
    notes?: string | null;
    currentEnv: number;
    minPlatformLevel: number;
    appVersion: number;
    logVersion: number;
    storeReview: boolean;
    backVersion: boolean;
    canPromote: boolean;
    history: {
        env: number | null | undefined;
        promotedBy: string | null | undefined;
        notes: string | null | undefined;
        promotionDate: string | null | undefined;
    }[];
    promoteHandler: (id: string) => void;
}> = props => {
    const {
        id,
        currentEnv,
        notes,
        minPlatformLevel,
        appVersion,
        logVersion,
        title,
        storeReview,
        history,
        backVersion,
        promoteHandler,
        canPromote,
    } = props;
    let bgColor = '#ffffff';
    switch (currentEnv) {
        case 1:
            bgColor = COLOR_ENV_STAGE;
            break;
        case 2:
            bgColor = COLOR_ENV_IAT;
            break;
        case 3:
            bgColor = COLOR_ENV_UAT;
            break;
        case 4:
            bgColor = '#ff0000';
            break;
        case 5:
            bgColor = '#000000';
            break;
        default:
            break;
    }
    const { pagePermissions } = useUserPermissions();
    const envHasData = (env: number): boolean => {
        const histItem = history.find(h => h.env === env);
        return histItem !== undefined;
    };
    const formatDate = (date: string | null | undefined): string => {
        if (date) {
            return displayDateWithAbbrTimeZone({
                isoDateStr: date,
                format: `${isToday(new Date(date)) ? 'h:mm a' : 'MMM DD, h:mm a'}`,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
        }
        return '';
    };
    const { data: userData } = useUser();
    const canPromoteBuild = (env: number): boolean => {
        if (!canPromote) {
            return false;
        }
        if (!pagePermissions?.AppBuilds.Edit) {
            return false;
        }
        if (env !== currentEnv) {
            return false;
        }
        if (env === 5) {
            return false;
        }
        if ((env === 3 || env === 4) && !userData?.currentUser?.canPromoteToProd) {
            return false;
        }
        return true;
    };
    const div = (env: number) => {
        if (envHasData(env)) {
            return (
                <div>
                    <div>
                        {env === 1 ? 'Created' : 'Promoted'} by:
                        <div>{history.find(h => h.env === env)?.promotedBy}</div>
                    </div>
                    <div>
                        At:<div>{formatDate(history.find(h => h.env === env)?.promotionDate)}</div>
                    </div>
                    <div style={{ fontSize: '.8em', fontStyle: 'italic' }}>
                        {env !== 1 && history.find(h => h.env === env)?.notes}
                    </div>
                    {canPromoteBuild(env) && (
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => promoteHandler(id)}
                            >
                                Promote
                            </Button>
                        </div>
                    )}
                </div>
            );
        }
        return null;
    };
    const backVersionDivStyle = {
        padding: '5px',
        border: `3px solid #ccc`,
        borderRadius: '15px',
        backgroundColor: '#ccc',
    };
    const withData = {
        padding: '5px',
        border: `3px solid #ccc`,
        borderRadius: '15px',
    };
    const selected = {
        padding: '5px',
        border: `5px double ${bgColor}`,
        borderRadius: '15px',
    };
    const getStyle = (env: number) => {
        if (env === currentEnv) {
            return selected;
        }
        if (envHasData(env)) {
            return withData;
        }
        return {};
    };
    return (
        <>
            <tr>
                <td style={backVersion ? backVersionDivStyle : withData}>
                    <div>
                        <strong>
                            {title}
                            {backVersion && (
                                <span style={{ color: 'red', paddingLeft: '5px' }}>
                                    (Back Version)
                                </span>
                            )}
                        </strong>
                    </div>
                    <div>App. Version: {appVersion}</div>
                    <div>Min. Platform Level: {minPlatformLevel}</div>
                    <div>Log Version: {logVersion}</div>
                    {storeReview && currentEnv !== 5 && (
                        <div>
                            <strong>Store Review Required</strong>
                        </div>
                    )}
                    {notes && <div>{notes}</div>}
                </td>
                <td style={getStyle(1)}>{div(1)}</td>
                <td style={getStyle(2)}>{div(2)}</td>
                <td style={getStyle(3)}>{div(3)}</td>
                <td style={getStyle(4)}>{div(4)}</td>
                <td style={getStyle(5)}>{div(5)}</td>
            </tr>
            <tr>
                <td colSpan={6}>
                    <hr />
                </td>
            </tr>
        </>
    );
};

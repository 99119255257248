import MaterialTable from '@material-table/core';
import { Groups2Outlined, InfoOutlined, MoreVert } from '@mui/icons-material';
import {
    Button,
    Dialog,
    IconButton,
    Link,
    Menu,
    MenuItem,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { format, toDate } from 'date-fns-tz';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    EligibilityRefreshOrigin,
    OrderByDirectionEnum,
    Patient,
    useVirtualCareAffiliatePatientsLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import EditInsuranceDialog from './Patient/EditInsuranceDialog';
import ReassignDialog from './Patient/ReassignDialog';
import CoverageExpiration from './components/CoverageExpiration';

type PatientsProps = {
    affiliateId?: string;
    initPatientId?: string;
    portalView?: boolean;
};

const Patients: React.FC<PatientsProps> = props => {
    const { affiliateId, initPatientId = '', portalView = false } = props;
    const { userAffiliateId } = useAffiliateCareView();
    const currentAffiliateId = affiliateId || userAffiliateId;
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGESIZE);
    const [searchInput, setSearchInput] = useState<string>(initPatientId);
    const tableRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [reassignDialogOpen, setReassignDialogOpen] = useState(false);
    const [insuranceDialogOpen, setInsuranceDialogOpen] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setPage(pageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    useEffect(() => {
        setSearchInput(initPatientId);
    }, [initPatientId]);
    const onSearchChange = useCallback((searchString: string) => {
        setSearchInput(searchString);
    }, []);
    const fetchVariables = useMemo(() => {
        return {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
                affiliateId: currentAffiliateId ?? 'no-data',
                ...(searchInput && { search: searchInput.trim() }),
            },
        };
    }, [page, rowsPerPage, currentAffiliateId, searchInput]);
    const [fetchPatients, { data, loading }] = useVirtualCareAffiliatePatientsLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    useEffect(() => {
        fetchPatients({ variables: fetchVariables });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchVariables]);
    const createPatientHandler = useCallback(
        () => {
            if (portalView) {
                window.open(
                    `/affiliate-care/${affiliateId}/1?sc=true`,
                    '_blank',
                    'noopener noreferrer',
                );
            } else {
                history(`/affiliate-care-portal/patients/${currentAffiliateId}/1?sc=true`);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentAffiliateId],
    );
    const handleMoreMenuClick = (event: React.MouseEvent<HTMLButtonElement>, patient: Patient) => {
        setAnchorEl(event.currentTarget);
        setSelectedPatient(patient);
    };
    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setSelectedPatient(null);
    }, []);
    const onCloseReassignDialog = useCallback(() => {
        setReassignDialogOpen(false);
        handleClose();
        fetchPatients({ variables: fetchVariables });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchVariables]);

    const onCloseEditInsuranceDialog = useCallback(() => {
        setInsuranceDialogOpen(false);
        handleClose();
        fetchPatients({ variables: fetchVariables });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchVariables]);
    const openMenu = !!anchorEl;
    return (
        <div ref={tableRef} style={{ position: 'relative' }}>
            <MaterialTable
                title="Eligible Patients"
                columns={[
                    {
                        title: 'id',
                        field: 'id',
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Patient Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                        sorting: false,
                        customFilterAndSearch: () => true,
                        width: 90,
                    },
                    {
                        title: '',
                        field: 'multiples',
                        render: ({ VCF }) =>
                            VCF?.babyMultiples && (
                                <Tooltip title="Having Twins/Multiples">
                                    <Groups2Outlined />
                                </Tooltip>
                            ),
                        sorting: false,
                        customFilterAndSearch: () => true,
                        width: 50,
                    },
                    {
                        title: 'First Name',
                        field: 'firstName',
                        render: ({ firstName, id }) => (
                            <Link
                                to={
                                    portalView
                                        ? `/app-config/vcaffiliates/patients/${affiliateId}/patient/${id}`
                                        : `/affiliate-care-portal/patients/${id}`
                                }
                                component={RouterLink}
                            >
                                {firstName}
                            </Link>
                        ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Last Name',
                        field: 'lastName',
                        render: ({ lastName, id }) => (
                            <Link
                                to={
                                    portalView
                                        ? `/app-config/vcaffiliates/patients/${affiliateId}/patient/${id}`
                                        : `/affiliate-care-portal/patients/${id}`
                                }
                                component={RouterLink}
                            >
                                {lastName}
                            </Link>
                        ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'DOB',
                        field: 'birthDate',
                        render: ({ birthDate }) =>
                            format(toDate(birthDate.split('T')[0]), 'MM/dd/yyyy'),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Coverage Expiration',
                        field: 'insurancePlans',
                        render: ({ insurancePlans, id }) => (
                            <CoverageExpiration
                                insurancePlans={insurancePlans ?? []}
                                patientId={id}
                                affiliateId={currentAffiliateId}
                                origin={
                                    portalView
                                        ? EligibilityRefreshOrigin.AdminPortal
                                        : EligibilityRefreshOrigin.AffiliatePortal
                                }
                            />
                        ),
                        sorting: false,
                        searchable: false,
                        cellStyle: { padding: 0 },
                    },
                    {
                        title: 'Phone Number',
                        field: 'phoneNumber',
                        render: ({ phoneNumber, virtualCarePhoneNumber }) => (
                            <Typography>{virtualCarePhoneNumber ?? phoneNumber}</Typography>
                        ),
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Email',
                        field: 'email',
                        render: ({ email }) => <Typography data-test={email}>{email}</Typography>,
                        sorting: false,
                        searchable: false,
                    },
                    {
                        title: 'Date Created',
                        field: 'createdAt',
                        render: ({ createdAt }) =>
                            format(toDate(createdAt.split('T')[0]), 'MM/dd/yyyy'),
                        sorting: false,
                        searchable: false,
                    },
                ]}
                data={data?.virtualCareAffiliatePatients?.results ?? []}
                options={{
                    search: true,
                    paging: true,
                    pageSize: rowsPerPage,
                    pageSizeOptions: [10, PAGESIZE, 50],
                    debounceInterval: 500,
                    ...(searchInput && { searchText: searchInput }),
                    showTitle: !portalView,
                }}
                isLoading={loading}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onSearchChange={onSearchChange}
                totalCount={data?.virtualCareAffiliatePatients?.total ?? 0}
                page={page}
                localization={{
                    ...(!portalView && {
                        header: {
                            actions: '',
                        },
                    }),
                }}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={data?.virtualCareAffiliatePatients?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        isFreeAction: true,
                        onClick: createPatientHandler,
                        icon: () => <Button variant="outlined">Add Eligible Patient</Button>,
                        hidden: !portalView && !pagePermissions?.VirtualCarePatients.Edit,
                    },
                    {
                        onClick: (e, patient) => {
                            handleMoreMenuClick(e, patient as Patient);
                        },
                        icon: () => <MoreVert />,
                        hidden: !portalView,
                    },
                ]}
            />
            <Menu
                open={openMenu}
                onClose={handleClose}
                anchorEl={anchorEl}
                id="more-menu"
                keepMounted
            >
                <MenuItem onClick={() => setReassignDialogOpen(true)}>Reassign Patient</MenuItem>
                <MenuItem onClick={() => setInsuranceDialogOpen(true)}>
                    Edit Insurance Dates
                </MenuItem>
            </Menu>
            {selectedPatient && (
                <Dialog open={reassignDialogOpen}>
                    <ReassignDialog
                        currentAffiliateId={currentAffiliateId}
                        patient={selectedPatient}
                        onClose={onCloseReassignDialog}
                    />
                </Dialog>
            )}
            {selectedPatient && (
                <Dialog open={insuranceDialogOpen}>
                    <EditInsuranceDialog
                        patient={selectedPatient}
                        onClose={onCloseEditInsuranceDialog}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default Patients;

import { useMediaQuery } from '@mui/material';
import { createContext, useContext, useMemo, useState } from 'react';
import { DOULA_LOGO_PATH, DOULA_SUPPORT_EMAIL, SUPPORT_EMAIL, WF_LOGO_PATH } from '../constants';
import { Steps } from '../types';

type Context = {
    step: Steps;
    setStep: (value: Steps) => void;
    setShowLoading: (value: boolean) => void;
    showLoading: boolean;
    isMobileView: boolean;
    showFormDescription: boolean;
    isAffiliateView: boolean;
    isDoulaView: boolean;
    formDescriptionText?: string;
    email: string;
    enableAppointmentStep?: boolean;
    affiliateId?: string;
    logoPath: string;
};

const defaultData = {
    step: Steps.PATIENT_INFO,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStep: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShowLoading: () => {},
    showLoading: false,
    isMobileView: false,
    showFormDescription: false,
    isAffiliateView: false,
    isDoulaView: false,
    email: SUPPORT_EMAIL,
    enableAppointmentStep: true,
    logoPath: WF_LOGO_PATH,
};

export const VCFContext = createContext<Context>(defaultData);

export const useVCFContext = () => useContext(VCFContext);

type initProps = {
    isAffiliateView?: boolean;
    isDoulaView?: boolean;
    formDescriptionText?: string;
    email?: string;
    affiliateId?: string;
    logoPath?: string;
};

export const useInitVCF = (props?: initProps) => {
    const isMobileView = useMediaQuery('(max-width:900px)');
    const [step, setStep] = useState<Steps>(Steps.PATIENT_INFO);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const showFormDescription = useMemo(
        () =>
            [Steps.PATIENT_INFO, Steps.PATIENT_INFO_CONTACT, Steps.APPOINTMENT_METHOD].includes(
                step,
            ),
        [step],
    );
    const email = props?.isDoulaView ? DOULA_SUPPORT_EMAIL : defaultData.email;
    const logoPath = props?.isDoulaView ? DOULA_LOGO_PATH : defaultData.logoPath;

    return {
        step,
        setStep,
        setShowLoading,
        showLoading,
        isMobileView,
        showFormDescription,
        isDoulaView: !!props?.isDoulaView ?? defaultData.isDoulaView,
        isAffiliateView: !!props?.isAffiliateView ?? defaultData.isAffiliateView,
        formDescriptionText: props?.formDescriptionText,
        email: props?.email ?? email,
        affiliateId: props?.affiliateId,
        logoPath: props?.logoPath ?? logoPath,
        enableAppointmentStep: defaultData.enableAppointmentStep,
    };
};

/* eslint-disable prettier/prettier */
import { Button, Card, Fade, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { format } from 'date-fns-tz';
import React, { useMemo } from 'react';
import Masonry from 'react-masonry-css';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { ADI, PHONE_NUMBER_PROFILE_DEFINITION_ID, ZIP_CODE_PROFILE_DEFINITION_ID } from '~/constants';
import {
    BatchTaskStatus,
    BatchTaskType,
    useCreateBatchTaskMutation,
    useDeletePatientOnPatientsPageMutation,
    useFetchPatientForPatientDetailsPageQuery,
    useFetchPatientProfileVariablesForPatientDetailsPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '~/hooks';
import useUserPermissions from '../../../../../hooks/useUserPermissions';

type RouteParams = {
    id: string;
};

const useStyles = makeStyles()({
    root: {},
    masonryColumn: {
        paddingLeft: 30,
        backgroundClip: 'padding-box',
    },
    masonryCard: {
        width: '100%',
    },
    masonryGrid: {
        display: 'flex',
        marginLeft: '-30px',
        width: 'auto',
    },
});

const PatientDetails: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const { classes } = useStyles();
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const { data: userData } = useUser();
    const { data: patientData, loading: patientLoading } =
        useFetchPatientForPatientDetailsPageQuery({
            variables: { input: { id } },
        });

    const { data: { patientProfileVariables } = {}, loading: isPatientProfileVariablesLoading } =
        useFetchPatientProfileVariablesForPatientDetailsPageQuery({
            variables: {
                patientId: id ?? '',
                profileVariableDefIds: [
                    PHONE_NUMBER_PROFILE_DEFINITION_ID,
                    ZIP_CODE_PROFILE_DEFINITION_ID,
                    ADI,
                ],
            },
        });
    const [createBatchTask] = useCreateBatchTaskMutation({});

    const submitRebuild = () => {
        if (patientData?.patient?.appUserId) {
            createBatchTask({
                variables: {
                    input: {
                        type: BatchTaskType.PatientCacheRebuild,
                        status: BatchTaskStatus.NotStarted,
                        addedBy: userData?.currentUser?.name ?? '',
                        priority: 5,
                        runAfter: new Date(),
                        settings: { appUserId: patientData.patient.appUserId },
                    },
                },
            });
        }
    };
    const [deletePatient] = useDeletePatientOnPatientsPageMutation({
        onCompleted: () => {
            history(`/portal/patients`);
        },
    });

    const breakpointCols = useMemo(
        () => ({
            default: 2,
            800: 1,
        }),
        [],
    );

    const PersonalInfo = useMemo(
        () => [
            {
                label: 'ID',
                data: patientData?.patient?.id,
            },
            {
                label: 'External ID',
                data: patientData?.patient?.externalId,
            },
            {
                label: 'Full Name',
                data: patientData?.patient?.fullName,
            },
            {
                label: 'DOB',
                data:
                    patientData?.patient?.birthDate &&
                    format(
                        new Date(
                            patientData?.patient?.birthDate.substr(
                                0,
                                patientData?.patient?.birthDate.length - 1,
                            ),
                        ),
                        'MM/dd/yyyy',
                        { timeZone: 'UTC' },
                    ),
            },
            {
                label: 'Email',
                data: patientData?.patient?.email,
            },
            {
                label: 'Phone Number',
                data:
                    patientData?.patient?.virtualCarePhoneNumber ??
                    patientProfileVariables?.find(
                        pv => pv.profileVariableDefId === PHONE_NUMBER_PROFILE_DEFINITION_ID,
                    )?.value.str ?? patientData?.patient?.phoneNumber,
            },
            {
                label: 'Address',
                data: (
                    <>
                        {patientData?.patient?.mailingAddress?.street1 && (
                            <Typography noWrap>
                                {patientData?.patient?.mailingAddress?.street1}
                            </Typography>
                        )}
                        {patientData?.patient?.mailingAddress?.street2 && (
                            <Typography noWrap>
                                {patientData?.patient?.mailingAddress?.street2}
                            </Typography>
                        )}
                        {patientData?.patient?.mailingAddress?.city &&
                        patientData?.patient?.mailingAddress?.state &&
                        patientData?.patient?.mailingAddress?.code ? (
                            <Typography noWrap>
                                {patientData?.patient?.mailingAddress?.city},{' '}
                                {patientData?.patient?.mailingAddress?.state}{' '}
                                {patientData?.patient?.mailingAddress?.code}{' '}
                            </Typography>
                        ) : (
                            patientData?.patient?.mailingAddress?.city ||
                            patientData?.patient?.mailingAddress?.state
                        )}
                    </>
                ),
            },
            {
                label: 'Zip Code',
                data:
                    patientProfileVariables?.find(
                        pv => pv.profileVariableDefId === ZIP_CODE_PROFILE_DEFINITION_ID,
                    )?.value.str ?? patientData?.patient?.mailingAddress?.code,
            },
            {
                label: 'ADI',
                data:
                    patientProfileVariables?.find(
                        pv => pv.profileVariableDefId === ADI,
                    )?.value.num,
            },
        ],
        [patientData, patientProfileVariables],
    );

    const PortalAppData = useMemo(
        () => [
            {
                label: 'Portal Creation Date',
                data:
                    patientData?.patient?.createdAt &&
                    format(
                        new Date(
                            patientData?.patient?.createdAt.substr(
                                0,
                                patientData?.patient?.createdAt.length - 1,
                            ),
                        ),
                        'MM/dd/yyyy',
                        { timeZone: 'UTC' },
                    ),
            },
            {
                label: 'Invitation Code',
                data: patientData?.patient?.invitationCode,
            },
            {
                label: 'Onboarding Complete Date',
                data: patientData?.patient?.onboardingCompleteDate
                ? new Date(
                      patientData?.patient?.onboardingCompleteDate,
                  ).toLocaleDateString()
                : 'Not Complete'
            },
            {
                label: 'App Bundle Id',
                data: patientData?.patient?.appBundleId,
            },
            {
                label: 'Practice Code',
                data: patientData?.patient?.signUpPracticeCode ?? 'N/A',
            },
            {
                label: 'Invitation Mode',
                data: patientData?.patient?.invitationMode,
            },
            {
                label: 'Practice Name',
                data: patientData?.patient?.practice.name,
            },
        ],
        [patientData],
    );

    if (patientLoading || isPatientProfileVariablesLoading) {
        return <Loading />;
    }

    return (
        <Grid className={classes.root} container spacing={2}>
            <Grid item xs={12}>
                <Masonry
                    breakpointCols={breakpointCols}
                    className={classes.masonryGrid}
                    columnClassName={classes.masonryColumn}
                >
                    <Fade in timeout={500}>
                        <Grid container>
                            <Typography paragraph variant="h6">
                                Personal Info:
                            </Typography>
                            <Card className={classes.masonryCard}>
                                <Grid container spacing={3}>
                                    {PersonalInfo.map(({ data, label }) => (
                                        <Grid key={label} item xs={12}>
                                            <Typography
                                                color="primary"
                                                gutterBottom
                                                variant="body1"
                                            >
                                                {label}:
                                            </Typography>
                                            <Typography variant="subtitle1">{data}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                        </Grid>
                    </Fade>
                    <Fade in timeout={500}>
                        <Grid container>
                            <Typography paragraph variant="h6">
                                Portal & App Info:
                            </Typography>
                            <Card className={classes.masonryCard}>
                                <Grid container spacing={3}>
                                    {PortalAppData.map(({ data, label }) => (
                                        <Grid key={label} item xs={12}>
                                            <Typography
                                                color="primary"
                                                gutterBottom
                                                variant="body1"
                                            >
                                                {label}:
                                            </Typography>
                                            <Typography variant="subtitle1">{data}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                        </Grid>
                    </Fade>
                </Masonry>
                {pagePermissions?.PatientDetails.Delete && (
                    <Grid xs={12} container justifyContent="flex-end">
                        <Button
                            variant="contained"
                            startIcon={<FontAwesomeIcon icon={faRefresh} />}
                            onClick={() => {
                                TriggerGlobalConfirm({
                                    message: `Do you want to rebuild the cache for ${patientData?.patient?.fullName}`,
                                    callback: () => {
                                        submitRebuild();
                                    },
                                });
                            }}
                        >
                            Rebuild Cache
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<FontAwesomeIcon icon={faTrash} />}
                            hidden={!pagePermissions.PatientDetails.Delete}
                            onClick={() => {
                                TriggerGlobalConfirm({
                                    message: `Do you want to delete the patient ${patientData?.patient?.fullName}`,
                                    callback: () => {
                                        deletePatient({
                                            variables: {
                                                input: {
                                                    id,
                                                },
                                            },
                                        });
                                    },
                                });
                            }}
                        >
                            Delete Patient
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default PatientDetails;

import { Typography } from '@mui/material';
import React from 'react';
import { ChoiceConfiguration, mapProfileVariableValue } from '~/helpers/getStringifiedValue';
import {
    GetProfileValueHistoriesByPatientIdForPatientTranscriptQuery,
    UserProfileValueType,
} from '~/schemaTypes';

type ProfileValue = NonNullable<
    GetProfileValueHistoriesByPatientIdForPatientTranscriptQuery['getProfileValueHistoriesByPatientId']
>[0];

export const ProfileValueCard = ({ profileValue }: { profileValue: ProfileValue }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Profile Change</Typography>
            <p className="m-0">
                {profileValue?.profileDef?.name}:{' '}
                {profileValue?.val != null &&
                    profileValue?.type != null &&
                    mapProfileVariableValue(
                        profileValue?.val,
                        profileValue?.type as unknown as UserProfileValueType,
                        {
                            choices: profileValue?.profileDef?.choices?.filter(
                                choice => choice != null,
                            ),
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            choiceValueType: profileValue?.profileDef?.choiceValueType,
                        } as ChoiceConfiguration,
                        {
                            isDateTime: profileValue?.profileDef?.isDateTime,
                        },
                    )}
            </p>
        </div>
    );
};

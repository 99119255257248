import { Typography } from '@mui/material';
import React from 'react';
import { GetTriggerInterventionSendMessageCenterForPatientTranscriptQuery } from '~/schemaTypes';

type TriggerInterventionSendMessageCenter = NonNullable<
    NonNullable<
        GetTriggerInterventionSendMessageCenterForPatientTranscriptQuery['getTriggerInterventionSendMessageCenterForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionSendMessageCenterCard = ({
    intervention,
}: {
    intervention: TriggerInterventionSendMessageCenter;
}) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Trigger Intervention Send Message</Typography>
            <p className="m-0">
                {`Title: ${intervention?.messageCenterTemplate?.title || 'Unknown'}`}
            </p>
            <p className="m-0">
                {`Content: ${intervention?.messageCenterTemplate?.content?.en || 'Unknown'}`}
            </p>
        </div>
    );
};

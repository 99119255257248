import { useCallback } from 'react';
import { useGetPatientEstimateByKeyLazyQuery } from '~/schemaTypes';

const useDownload = () => {
    const [downloadReport] = useGetPatientEstimateByKeyLazyQuery({
        fetchPolicy: 'network-only',
        onCompleted: res => {
            if (res.getPatientEstimateByKey?.data) {
                const blob = new Blob([
                    new Uint8Array(res.getPatientEstimateByKey.data as unknown as Buffer).buffer,
                ]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = res?.getPatientEstimateByKey?.filename ?? 'patient.estimate.txt';
                link.click();
                link.remove();
            }
        },
    });
    const downloadHandler = useCallback((fileName: string) => {
        downloadReport({
            variables: {
                input: {
                    key: fileName,
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { downloadHandler };
};

export default useDownload;

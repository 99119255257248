import React, { useEffect } from 'react';
import { Grid, TextField, DialogContent, Button, DialogActions } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { TriggerGlobalAlert, SuppressNextGlobalAlert } from '~/state';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    VcExternalFormTemplate,
    useCreateVcExternalFormTemplateMutation,
    useUpdateVcExternalFormTemplateMutation,
    useVcExternalFormTemplateLazyQuery,
} from '~/schemaTypes';
import { VC_EXTERNAL_FORM_TEMPLATE_SCHEMA } from './yupSchema';

type VCExternalFormTemplateEditModalProps = {
    id?: string;
    onClose: () => void;
};

type VCExternalFormTemplateFormInput = Pick<VcExternalFormTemplate, 'name' | 'formTemplateId'>;

export const VCExternalFormTemplateEditModal: React.FC<VCExternalFormTemplateEditModalProps> = ({
    id,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<VCExternalFormTemplateFormInput>({
        resolver: yupResolver(VC_EXTERNAL_FORM_TEMPLATE_SCHEMA as any),
    });

    const [fetchVCExternalFormTemplate, { loading: vcExternalFormTemplateLoading }] =
        useVcExternalFormTemplateLazyQuery({
            onCompleted: data => {
                const fetchedData = data.vCExternalFormTemplate;
                if (fetchedData) {
                    setValue('name', fetchedData.name);
                    setValue('formTemplateId', fetchedData.formTemplateId);
                }
            },
        });

    useEffect(() => {
        if (id) {
            fetchVCExternalFormTemplate({ variables: { input: { id } } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const [createVcExternalFormTemplate, { loading: createLoading }] =
        useCreateVcExternalFormTemplateMutation({
            onCompleted: data => {
                if (!data.createVCExternalFormTemplate?.success) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message:
                            data.createVCExternalFormTemplate?.message ?? 'Unknown Error Occurred',
                    });
                    return;
                }
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Entry created successfully',
                });
                onClose();
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
            refetchQueries: ['VCExternalFormTemplatesForVCExternalFormTemplatesPage'],
        });

    const [updateVcExternalFormTemplate, { loading: updateLoading }] =
        useUpdateVcExternalFormTemplateMutation({
            onCompleted: data => {
                if (!data.updateVCExternalFormTemplate?.success) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message:
                            data.updateVCExternalFormTemplate?.message ?? 'Unknown Error Occurred',
                    });
                    return;
                }
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Entry updated successfully',
                });
                onClose();
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
            refetchQueries: ['VCExternalFormTemplatesForVCExternalFormTemplatesPage'],
        });

    const onSubmit = (data: VCExternalFormTemplateFormInput) => {
        SuppressNextGlobalAlert(true);
        if (id) {
            updateVcExternalFormTemplate({
                variables: {
                    input: {
                        id,
                        data,
                    },
                },
            });
        } else {
            createVcExternalFormTemplate({
                variables: {
                    input: {
                        name: data.name,
                        formTemplateId: data.formTemplateId,
                    },
                },
            });
        }
    };

    if (vcExternalFormTemplateLoading) {
        return <Loading />;
    }

    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => onClose()}>
                {id ? 'Edit External VC Form Template' : 'Create External VC Form Template'}
            </DialogTitleWithClose>
            <DialogContent dividers>
                {(createLoading || updateLoading) && <Loading />}
                {!(createLoading || updateLoading) && (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Name"
                                    fullWidth
                                    margin="dense"
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Template Id"
                                    fullWidth
                                    margin="dense"
                                    {...register('formTemplateId')}
                                    error={!!errors.formTemplateId}
                                    helperText={errors.formTemplateId?.message}
                                />
                            </Grid>
                        </Grid>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <LoadingButton
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    loading={createLoading || updateLoading}
                    onClick={() => handleSubmit(onSubmit)()}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </div>
    );
};

import * as Yup from 'yup';

export const CARE_TEAM_MEMBER_VALIDATION_SCHEMA = Yup.object().shape({
    appBundleId: Yup.string().required('Required'),
    careTeamMemberTypeId: Yup.string().required('Required'),
    careTeamMemberContactId: Yup.string().notRequired().nullable(),
    careTeamMemberTIN: Yup.string()
        .notRequired()
        .nullable()
        .matches(/\d{9}|\d{2}-\d{7}|^$/, '9-digit number, sometimes formatted XX-XXXXXXX'),
    careTeamMemberNPI: Yup.string()
        .notRequired()
        .nullable()
        .matches(/\d{10}|^$/, '10-digit number'),
    practiceExternalId: Yup.string().notRequired().nullable(),
    practiceCode: Yup.string().notRequired().nullable(),
    overridenCareTeamMemberContact: Yup.object()
        .shape({
            careTeamMemberTypeId: Yup.string(),
            name: Yup.string(),
            address: Yup.object().shape({
                street1: Yup.string(),
                street2: Yup.string(),
                city: Yup.string(),
                state: Yup.string(),
                code: Yup.string(),
            }),
            phoneNumber: Yup.string(),
            emailAddress: Yup.string(),
            url: Yup.string(),
        })
        .notRequired(),
    label: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
    usState: Yup.string().notRequired().nullable(),
    zipCode: Yup.string()
        .notRequired()
        .nullable()
        .matches(/(\d{5}|^$)/),
    prerequisiteTeamMembers: Yup.array().of(Yup.string()).notRequired(),
    eligibilityCheck: Yup.string(),
    eligibilityRecheckDays: Yup.number(),
    consentRequired: Yup.boolean(),
    consentAgreement: Yup.string(),
    isActive: Yup.boolean(),
    userId: Yup.string(),
    appDefault: Yup.boolean(),
    shortName: Yup.string().notRequired().nullable(),
});

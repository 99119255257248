import { IconButton, Snackbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useState } from 'react';
import { useSettingsForEnvLabelQuery } from '~/schemaTypes';

const COLOR_ENV_LOCAL = '#990000';
const COLOR_ENV_DEV = '#999900';
export const COLOR_ENV_STAGE = '#009900';
export const COLOR_ENV_IAT = '#009999';
export const COLOR_ENV_UAT = '#990099';

export const EnvLabel: React.FC = () => {
    const [envLabel, setEnvLabel] = useState<string>('');
    const [showLabel, setShowLabel] = useState<boolean>(false);
    const [labelColor, setLabelColor] = useState<string>('#000000');
    const setFavicon = (env?: string | null) => {
        const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
        if (link) {
            switch (env) {
                case 'local':
                    link.href = '/favicon-local.ico';
                    break;
                case 'dev':
                    link.href = '/favicon-dev.ico';
                    break;
                case 'stage':
                    link.href = '/favicon-stage.ico';
                    break;
                case 'iat':
                    link.href = '/favicon-iat.ico';
                    break;
                case 'uat':
                    link.href = '/favicon-uat.ico';
                    break;
                default:
                    link.href = '/favicon.ico';
                    break;
            }
        }
    };
    useSettingsForEnvLabelQuery({
        onCompleted: data => {
            setEnvLabel(data.settings.env ?? '');
            setFavicon(data.settings.env);
            switch (data.settings.env) {
                case 'local':
                    setLabelColor(COLOR_ENV_LOCAL);
                    setShowLabel(true);
                    break;
                case 'dev':
                    setLabelColor(COLOR_ENV_DEV);
                    setShowLabel(true);
                    break;
                case 'stage':
                    setLabelColor(COLOR_ENV_STAGE);
                    setShowLabel(true);
                    break;
                case 'iat':
                    setLabelColor(COLOR_ENV_IAT);
                    setShowLabel(true);
                    break;
                case 'uat':
                    setLabelColor(COLOR_ENV_UAT);
                    setShowLabel(true);
                    break;
                default:
                    setShowLabel(false);
                    break;
            }
        },
    });
    const hideLabel = () => {
        setShowLabel(false);
        setFavicon();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showLabel}
            onClose={hideLabel}
            autoHideDuration={10000}
            message={`Environment: ${envLabel.toUpperCase()}`}
            ContentProps={{
                sx: {
                    fontWeight: 'bold',
                    backgroundColor: labelColor,
                },
            }}
            action={
                <IconButton onClick={hideLabel}>
                    <Close htmlColor="#ffffff" />
                </IconButton>
            }
        />
    );
};

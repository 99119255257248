import { PortalVcUserType } from '~/schemaTypes';
import useUser from './useUser';

const useDoulaView = () => {
    const currentUser = useUser();
    return {
        isDoulaView: currentUser.data?.currentUser?.vcType === PortalVcUserType.Doula,
    };
};

export default useDoulaView;

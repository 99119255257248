import { HeaderLink } from '~/components/Header/Header';
import {
    AdminUserEnum,
    AppDefaultEnum,
    CarePlanEnum,
    CareTeamMemberEnum,
    CommonEnum,
    ConditionTypeEnum,
    ExpressionEnum,
    ProfileDefEnum,
    ProfileScreenEnum,
    ProfileValueOriginSelectorEnum,
    RoleEnum,
    SurveyEnum,
    TimelineEnum,
} from '~/selectors';

const ConfigLinks: HeaderLink[] = [
    {
        to: '/app-config/apps',
        text: 'Applications',
        hide: userPermissions => !userPermissions.Apps.Read,
        dataTest: CommonEnum.NAV_APPS,
    },
    {
        to: '/app-config/value-defaults',
        text: 'App Defaults',
        hide: userPermissions => !userPermissions.AppDefaults.Read,
        dataTest: AppDefaultEnum.NAV_APP_DEFAULTS,
    },
    {
        to: '/app-config/expressions',
        text: 'Expressions',
        hide: userPermissions => !userPermissions.Expressions.Read,
        dataTest: ExpressionEnum.NAV_EXPRESSIONS,
    },
    {
        to: '/app-config/care-team-contacts',
        text: `Care Team Contacts`,
        hide: userPermissions => !userPermissions.CareTeamContacts.Read,
    },
    {
        to: '/app-config/profile-defs',
        text: 'Profile Definitions',
        hide: userPermissions => !userPermissions.ProfileDefs.Read,
        dataTest: ProfileDefEnum.NAV_PROFILE_DEFINITIONS,
    },
    {
        to: '/app-config/profile-value-origins',
        text: 'Profile Value Origins',
        hide: userPermissions => !userPermissions.ProfileValueOrigin.Read,
        dataTest: ProfileValueOriginSelectorEnum.NAV_PROFILE_VALUE_ORIGIN,
    },
    {
        to: '/app-config/surveys',
        text: 'Surveys',
        hide: userPermissions => !userPermissions.SurveyDefs.Read,
        dataTest: SurveyEnum.NAV_SURVEYS,
    },
    {
        to: '/app-config/bio-screens',
        text: 'Profile Screens',
        hide: userPermissions => !userPermissions.ProfileScreens.Read,
        dataTest: ProfileScreenEnum.NAV_PROFILE_SCREENS,
    },
    {
        to: '/app-config/timelines',
        text: `Timelines`,
        hide: userPermissions => !userPermissions.WhatsNewTimelines.Read,
        dataTest: TimelineEnum.NAV_TIMELINES,
    },
    {
        to: '/app-config/tags',
        text: `Tags`,
        hide: userPermissions => !userPermissions.Tags.Read,
    },
    {
        to: '/app-config/triggers',
        text: `Triggers - Tag`,
        hide: userPermissions => !userPermissions.Triggers.Read,
    },
    {
        to: '/app-config/datetriggers',
        text: `Triggers - Date`,
        hide: userPermissions => !userPermissions.DateTriggers.Read,
    },
    {
        to: '/app-config/eventtriggers',
        text: `Triggers - Event`,
        hide: userPermissions => !userPermissions.EventTriggers.Read,
    },
    {
        to: '/app-config/trendtriggers',
        text: `Triggers - Trend`,
        hide: userPermissions => !userPermissions.TrendTriggers.Read,
    },
    {
        to: '/app-config/care-plans',
        text: 'Care Plans',
        hide: userPermissions => !userPermissions.CarePlans.Read,
        dataTest: CarePlanEnum.NAV_CARE_PLANS,
    },
    {
        to: '/app-config/care-teams',
        text: `Care Teams`,
        hide: userPermissions => !userPermissions.CareTeams.Read,
    },
    {
        to: '/app-config/care-team-members',
        text: `Care Team Members`,
        hide: userPermissions => !userPermissions.CareTeamMembers.Read,
        dataTest: CareTeamMemberEnum.NAV_CARE_TEAM_MEMBERS,
    },
    {
        to: '/app-config/roles',
        text: `Roles`,
        hide: userPermissions => !userPermissions.Roles.Read,
        dataTest: RoleEnum.NAV_ROLES,
    },
    {
        to: '/app-config/condition-types',
        text: 'Condition Types',
        hide: userPermissions => !userPermissions.ConditionTypes.Read,
        dataTest: ConditionTypeEnum.NAV_CONDITION_TYPES,
    },
    {
        to: '/app-config/articlecategories',
        text: 'Article Categories',
        hide: userPermissions => !userPermissions.ArticleCategory.Read,
    },
    {
        to: '/app-config/articleexports',
        text: 'Export Articles',
        hide: userPermissions => !userPermissions.ArticleExport.Read,
    },
    {
        to: '/app-config/reportingcategories',
        text: 'Reporting Categories',
        hide: userPermissions => !userPermissions.ReportingCategory.Read,
    },
    {
        to: '/app-config/staff',
        text: 'Admin Users',
        hide: userPermissions => !userPermissions?.Staff.Read,
        dataTest: AdminUserEnum.NAV_ADMIN_USERS,
    },
    {
        to: '/app-config/simplecontent',
        text: 'Simple Content',
        hide: userPermissions => !userPermissions.SimpleContent.Read,
    },
    {
        to: '/app-config/articles',
        text: 'Articles',
        hide: userPermissions => !userPermissions.Articles.Read,
    },
    {
        to: '/app-config/articlegroups',
        text: 'Article Groups',
        hide: userPermissions => !userPermissions.ArticleGroup.Read,
    },
    {
        to: '/app-config/advocate-task-templates',
        text: 'Advocate Task Templates',
        hide: userPermissions => !userPermissions.AdvocateTaskTemplates.Read,
    },
    {
        to: '/app-config/articleapproval',
        text: 'Article Approval',
        hide: userPermissions => !userPermissions.ArticleApproval.Read,
    },
    {
        to: '/app-config/message-center-templates',
        text: 'Message Center Templates',
        hide: userPermissions => !userPermissions.MessageCenterTemplates.Read,
    },
    {
        to: '/app-config/contentdirectory',
        text: 'Content Directories',
        hide: userPermissions => !userPermissions.ContentDirectory.Read,
    },
    {
        to: '/app-config/articlepromotions',
        text: 'Article Promotions',
        hide: userPermissions => !userPermissions.ArticlePromotions.Read,
    },
    {
        to: '/app-config/care-team-member-types',
        text: 'Care Team Member Types',
        hide: userPermissions => !userPermissions.CareTeamMemberTypes.Read,
    },
    {
        to: '/app-config/timelineviewer',
        text: 'Timeline Viewer',
        hide: userPermissions => !userPermissions.AdminTimelineViewer.Read,
    },
    {
        to: '/app-config/notetags',
        text: `Note Tags`,
        hide: userPermissions => !userPermissions.NoteTags.Read,
    },
    {
        to: '/app-config/patient-action-types',
        text: `Patient Action Types`,
        hide: userPermissions => !userPermissions.PatientActions.Read,
    },
    {
        to: '/app-config/highlights',
        text: `Highlights`,
        hide: userPermissions => !userPermissions.Highlights.Read,
    },
    {
        to: '/app-config/referencelinks',
        text: `Reference Links`,
        hide: userPermissions => !userPermissions.ReferenceLinks.Read,
    },
    {
        to: '/app-config/referencelinkCategories',
        text: `Reference Link Categories`,
        hide: userPermissions => !userPermissions.ReferenceLinkCategories.Read,
    },
    {
        to: '/app-config/orgs',
        text: `Organizations`,
        hide: userPermissions => !userPermissions.OrgConfig.Read,
    },
    {
        to: '/app-config/compound-questions',
        text: 'Compound Questions',
        hide: userPermissions => !userPermissions.CompoundQuestions.Read,
    },
    {
        to: '/app-config/vc-zip-code-to-location',
        text: 'VC Zip Codes',
        hide: userPermissions => !userPermissions.VirtualCareZipCodeMap.Read,
    },
    {
        to: '/app-config/vc-eligibility-whitelist',
        text: 'VC Eligibility Whitelist',
        hide: userPermissions => !userPermissions.VirtualCareEligibilityWhitelist.Read,
    },
    {
        to: '/app-config/message-templates',
        text: 'Message Template',
        hide: userPermissions => !userPermissions.EmailTemplates.Read,
    },
    {
        to: '/app-config/vcaffiliates',
        text: 'VC Affiliates',
        hide: userPermissions => !userPermissions.VirtualCareAffiliates.Read,
    },
    {
        to: '/app-config/affiliate-claim-submissions',
        text: 'VC Affiliate Claim Submissions',
        hide: userPermissions => !userPermissions.AffiliateClaims.Read,
    },
    {
        to: '/app-config/eligibility-match',
        text: 'Eligibility Match',
        hide: userPermissions => !userPermissions.EligibilityMatch.Read,
    },
    {
        to: '/app-config/calendars',
        text: 'Calendars',
        hide: userPermissions => !userPermissions.Calendars.Read,
    },
    {
        to: '/app-config/slas',
        text: 'SLA',
        hide: userPermissions => !userPermissions.Sla.Read,
    },
    {
        to: '/app-config/affiliate-invoices',
        text: 'VC Affiliate Invoices',
        hide: userPermissions => !userPermissions.AffiliateInvoices.Read,
    },
    {
        to: '/app-config/chat-slas',
        text: 'SLA Chat',
        hide: userPermissions => !userPermissions.Sla.Read,
    },
    {
        to: '/app-config/staff?affiliate-users-only=true',
        text: 'VC Users',
        hide: userPermissions => !userPermissions?.Staff.Read,
    },
    {
        to: '/app-config/vcf-eligibility-results',
        text: 'VC Eligibility Results',
        hide: userPermissions => !userPermissions.VirtualCareEligibilityResults.Read,
    },
    {
        to: '/app-config/vc-external-form-templates',
        text: 'VC External Form Templates',
        hide: userPermissions => !userPermissions.VcExternalFormTemplates.Read,
    },
    {
        to: '/app-config/patient-estimates-submitted',
        text: 'VC Patient Estimates Submitted',
        hide: userPermissions => !userPermissions.PatientEstimatesSubmitted.Read,
    },
    {
        to: '/app-config/appReleases',
        text: 'App Releases',
        hide: userPermissions => !userPermissions.AppBuilds.Read,
    },
];

export default ConfigLinks;

import React from 'react';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PatientEstimatesSubmitted } from '../types';

type DownloadProps = {
    claim: PatientEstimatesSubmitted;
    downloadHandler: (name: string) => void;
};

const Download: React.FC<DownloadProps> = props => {
    const { claim, downloadHandler } = props;
    return (
        <Grid container>
            {claim.patientFileName && (
                <Grid item>
                    <Tooltip title={claim.patientFileName}>
                        <IconButton
                            onClick={() => downloadHandler(claim.patientFileName as string)}
                        >
                            <FontAwesomeIcon icon={faFileCsv} size="xs" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {claim.visitFileName && (
                <Grid item>
                    <Tooltip title={claim.visitFileName}>
                        <IconButton onClick={() => downloadHandler(claim.visitFileName as string)}>
                            <FontAwesomeIcon icon={faFileCsv} size="xs" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
            {claim.visitInsuranceFileName && (
                <Grid item>
                    <Tooltip title={claim.visitInsuranceFileName}>
                        <IconButton
                            onClick={() => downloadHandler(claim.visitInsuranceFileName as string)}
                        >
                            <FontAwesomeIcon icon={faFileCsv} size="xs" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    );
};

export default Download;

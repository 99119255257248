/* eslint-disable camelcase */

import { ROOT_ORG_ID } from '~/constants';
import { deprecatedTargets } from '~/hooks/useUserPermissions';
import { Permission, PermissionsInput, Target } from '~/schemaTypes';

export enum TargetGroup {
    Config = 'Config',
    Patients = 'Patients',
    Settings = 'Settings',
    Support = 'Support',
    Testing = 'Testing',
    VirtualCare = 'VirtualCare',
    Doula = 'Doula',
}

const targetToName = (target: Target): string => {
    switch (target) {
        case Target.AppDefaults:
            return 'App Defaults';
        case Target.Apps:
            return 'Applications';
        case Target.Appointments:
            return 'Appointments';
        case Target.Orders:
            return 'Orders';
        case Target.Devices:
            return 'Devices';
        case Target.Expressions:
            return 'Expressions';
        case Target.HealthPlans:
            return 'Health Plans';
        case Target.Measurements:
            return 'Measurements';
        case Target.MessageCenter:
            return 'Message Center';
        case Target.MessageCenterTemplates:
            return 'Message Center Templates';
        case Target.PatientDetails:
            return 'Patient Details';
        case Target.PatientNotes:
            return 'Patient Notes';
        case Target.SurveyResponses:
            return 'Survey Responses';
        case Target.PatientWhatsNew:
            return "What's New";
        case Target.PatientQuestions:
            return 'Patient Questions';
        case Target.Practices:
            return 'Organizations';
        case Target.ProfileDefs:
            return 'Profile Definitions';
        case Target.PatientArticleFeed:
            return 'Article Feed';
        case Target.ProfileScreens:
            return 'Profile Screens';
        case Target.Providers:
            return 'Providers';
        case Target.Staff:
            return 'Admin Users';
        case Target.SurveyDefs:
            return 'Survey Definitions';
        case Target.WhatsNewTimelines:
            return 'Timelines';
        case Target.AdvocateTaskTemplates:
            return 'Advocate Task Templates';
        case Target.AdvocateTaskList:
            return 'Advocate Tasks List';
        case Target.WhatsNewTemplates:
            return "What's New Templates";
        case Target.WhatsNewInstances:
            return "What's New Instances";
        case Target.Tags:
            return 'Tags';
        case Target.CarePlans:
            return 'Care Plans';
        case Target.CareTeams:
            return 'Care Teams';
        case Target.CareTeamMembers:
            return 'Care Team Members';
        case Target.Roles:
            return 'Roles';
        case Target.CareTeamContacts:
            return 'Care Team Contacts';
        case Target.FrontDesk:
            return 'Front Desk';
        case Target.PatientRiskIndicators:
            return 'Risk Indicators';
        case Target.PatientRiskScore:
            return 'Risk Score';
        case Target.PatientAttachments:
            return 'Attachments';
        case Target.Triggers:
            return 'Tag Triggers';
        case Target.ConditionTypes:
            return 'Condition Types';
        case Target.OrgConfig:
            return 'Org Configuration';
        case Target.PatientProfileVariables:
            return 'Profile Variables';
        case Target.ArticleCategory:
            return 'Article Categories';
        case Target.Tokens:
            return 'Tokens';
        case Target.SimpleContent:
            return 'Simple Content';
        case Target.Articles:
            return 'Articles';
        case Target.ArticleGroup:
            return 'Article Groups';
        case Target.ArticleApproval:
            return 'Article Approval';
        case Target.ContentDirectory:
            return 'Content Directories';
        case Target.PatientTodos:
            return 'Patient Todos';
        case Target.ProfileValueOrigin:
            return 'Profile Value Origin';
        case Target.ArticlePromotions:
            return 'Article Promotions';
        case Target.PatientChart:
            return 'Patient Chart';
        case Target.PatientActions:
            return 'Patient Actions';
        case Target.HgPatientAccess:
            return 'Health Gorilla Patient Access';
        case Target.IsHealthAdvocate:
            return 'Is Health Advocate';
        case Target.PatientCareTeam:
            return 'Patient Care Team';
        case Target.HealthAdvocateSupervisor:
            return 'Health Advocate Supervisor';
        case Target.HealthAdvocate:
            return 'Health Advocate';
        case Target.CareTeamMemberTypes:
            return 'Care Team Member Types';
        case Target.AppEvents:
            return 'App Events';
        case Target.AdminTimelineViewer:
            return 'Admin Timeline Viewer';
        case Target.ReportingCategory:
            return 'Reporting categories';
        case Target.NoteTags:
            return 'Note Tags';
        case Target.PatientClinicalSummaries:
            return 'Clinical Summaries';
        case Target.PatientActionTypes:
            return 'Patient Action Types';
        case Target.Highlights:
            return 'Highlights';
        case Target.PatientHighlights:
            return 'Patient Highlights';
        case Target.PatientList:
            return 'Patient List';
        case Target.PatientTags:
            return 'Patient Tags';
        case Target.PatientAppEvents:
            return 'Patient App Events';
        case Target.ReferenceLinks:
            return 'Reference Links';
        case Target.ReferenceLinkCategories:
            return 'Reference Link Categories';
        case Target.DateTriggers:
            return 'Date Triggers';
        case Target.EventTriggers:
            return 'Event Triggers';
        case Target.TrendTriggers:
            return 'Trend Triggers';
        case Target.CompoundQuestions:
            return 'Compound Questions';
        case Target.VirtualCareZipCodeMap:
            return 'VC Zip Code to Location';
        case Target.HaTasks:
            return 'HA Tasks';
        case Target.VirtualCareEligibilityWhitelist:
            return 'VC Eligibility Whitelist';
        case Target.EmailTemplates:
            return 'Message Templates';
        case Target.EligibilityMatch:
            return 'Eligibility Match';
        case Target.AffiliateClaims:
            return 'VC Affiliates Claim Submissions';
        case Target.VirtualCareAffiliates:
            return 'VC Affiliates';
        case Target.MassUpdate:
            return 'Patient Mass Update';
        case Target.AffiliateCareClaimsSubmission:
            return 'Affiliate Care Claims Submission';
        case Target.Calendars:
            return 'Calendars';
        case Target.VirtualCareClaims:
            return 'Claims';
        case Target.VirtualCarePatients:
            return 'Patients';
        case Target.Sla:
            return 'SLAs';
        case Target.SlaTimer:
            return 'SLA Timers';
        case Target.AffiliateCareInvoices:
            return 'Affiliate Care Invoices';
        case Target.AffiliateInvoices:
            return 'Admin Affiliate Invoices';
        case Target.PatientCallLists:
            return 'Patient Call Lists';
        case Target.ArticleExport:
            return 'Article Export';
        case Target.PatientTranscript:
            return 'Patient Transcript';
        case Target.AffiliateCareSettings:
            return 'Affiliate Care Settings';
        case Target.VirtualCareEligibilityResults:
            return 'VC Eligibility Results';
        case Target.DoulaInvoices:
            return 'Doula Invoices';
        case Target.DoulaClaimsSubmission:
            return 'Doula Claims Submission';
        case Target.VcExternalFormTemplates:
            return 'VC External Form Templates';
        case Target.VcExternalForm:
            return 'VC External Form';
        case Target.PatientEstimatesSubmitted:
            return 'VC Patient Estimates Submitted';
        case Target.AppBuilds:
            return 'App Builds';
        case Target.PatientHeaders:
            return 'Patient Headers';
        case Target.EligibilityCheckResults:
            return 'Eligibility Check Results';
        default:
            return 'Unknown';
    }
};
export interface GroupedPermission {
    group: TargetGroup;
    items: [
        {
            targetName: string;
            target: Target;
            canExport: boolean;
            permission: Permission;
        },
    ];
}

export class UserPermissions {
    public static PermissionsGroupsForUser(
        permissions: PermissionsInput[] | undefined,
    ): TargetGroup[] {
        const allPermissions = UserPermissions.permissionData();
        const groups = new Set<TargetGroup>();
        permissions
            ?.filter(p => p.permission !== Permission.None)
            .forEach(p => {
                const base = allPermissions.find(all => all.target === p.target);
                if (base) groups.add(base.group);
            });
        return Array.from(groups);
    }

    public static PermissionsGroupsForRole(
        permissions: PermissionsInput[] | undefined,
        orgId: string | undefined,
    ): GroupedPermission[] {
        const activePermissions = UserPermissions.permissionData().filter(
            d => !deprecatedTargets.includes(d.target),
        );
        const data =
            !orgId || orgId !== ROOT_ORG_ID
                ? activePermissions.filter(d => !d.wfOnly)
                : activePermissions;
        // get sorted distinct groups
        const groups = Array.from(
            new Set(
                data
                    .sort((a, b) => {
                        if (a.group < b.group) return -1;
                        if (a.group === b.group) return 0;
                        return 1;
                    })
                    .map(d => d.group),
            ),
        );
        return groups.map(g => {
            return {
                group: g,
                items: data
                    .filter(d => d.group === g)
                    .sort((a, b) => {
                        const aName = targetToName(a.target);
                        const bName = targetToName(b.target);
                        if (aName < bName) return -1;
                        if (aName === bName) return 0;
                        return 1;
                    })
                    .map(d => {
                        const permission = permissions?.find(p => p?.target === d.target);
                        return {
                            targetName: targetToName(d.target),
                            target: d.target,
                            canExport: permission?.canExport,
                            permission: permission?.permission || Permission.None,
                        };
                    }),
            } as GroupedPermission;
        });
    }

    public static permissionsForClientAdmin(): PermissionsInput[] {
        return UserPermissions.permissionData()
            .filter(p => p.group === TargetGroup.Patients)
            .map(p => {
                return {
                    target: p.target,
                    permission: Permission.Edit,
                    canExport: true,
                };
            });
    }

    public static permissionsForClientUser(): PermissionsInput[] {
        return UserPermissions.permissionData()
            .filter(p => p.group === TargetGroup.Patients)
            .map(p => {
                return {
                    target: p.target,
                    permission: Permission.View,
                    canExport: false,
                };
            });
    }

    private static permissionData() {
        return [
            {
                target: Target.PatientList,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientDetails,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientArticleFeed,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientNotes,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.MessageCenter,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientWhatsNew,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientRiskIndicators,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientRiskScore,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientAttachments,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientQuestions,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.Appointments,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.Measurements,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.SurveyResponses,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.HealthAdvocate,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.HealthAdvocateSupervisor,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientCallLists,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.Orders,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.Practices,
                group: TargetGroup.Settings,
                wfOnly: false,
            },
            {
                target: Target.OrgConfig,
                group: TargetGroup.Settings,
                wfOnly: false,
            },
            {
                target: Target.HealthPlans,
                group: TargetGroup.Settings,
                wfOnly: false,
            },
            {
                target: Target.Providers,
                group: TargetGroup.Settings,
                wfOnly: false,
            },
            {
                target: Target.Devices,
                group: TargetGroup.Settings,
                wfOnly: false,
            },
            {
                target: Target.PatientHeaders,
                group: TargetGroup.Settings,
                wfOnly: false,
            },
            {
                target: Target.AppDefaults,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Apps,
                group: TargetGroup.Config,
                wfOnly: true,
            },
            {
                target: Target.Expressions,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.MessageCenterTemplates,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ProfileDefs,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.SurveyDefs,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Staff,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ProfileScreens,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.WhatsNewTimelines,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.AdvocateTaskTemplates,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.AdvocateTaskList,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.WhatsNewTemplates,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.WhatsNewInstances,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Tags,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.CarePlans,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.CareTeams,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.CareTeamMembers,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.CareTeamContacts,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Roles,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ConditionTypes,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Triggers,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.FrontDesk,
                group: TargetGroup.Support,
                wfOnly: true,
            },
            {
                target: Target.PatientProfileVariables,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.ArticleCategory,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Tokens,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.SimpleContent,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Articles,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ArticleGroup,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ContentDirectory,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.PatientTodos,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.ProfileValueOrigin,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ArticlePromotions,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.PatientChart,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientActions,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.HgPatientAccess,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.IsHealthAdvocate,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientCareTeam,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.CareTeamMemberTypes,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.AddPatient,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.AppEvents,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.AdminTimelineViewer,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ReportingCategory,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.PatientClinicalSummaries,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.NoteTags,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.PatientActionTypes,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.Highlights,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.PatientHighlights,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.ReferenceLinks,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ReferenceLinkCategories,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ArticleApproval,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.MassUpdate,
                group: TargetGroup.Support,
                wfOnly: false,
                canExport: false,
            },
            {
                target: Target.DateTriggers,
                group: TargetGroup.Config,
                wfOnly: true,
                canExport: false,
            },
            {
                target: Target.EventTriggers,
                group: TargetGroup.Config,
                wfOnly: true,
                canExport: false,
            },
            {
                target: Target.TrendTriggers,
                group: TargetGroup.Config,
                wfOnly: true,
                canExport: false,
            },
            {
                target: Target.CompoundQuestions,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.VirtualCareZipCodeMap,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.PatientTags,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientAppEvents,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.HaTasks,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.VirtualCareEligibilityWhitelist,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.EmailTemplates,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.EligibilityMatch,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.AffiliateClaims,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.VirtualCareAffiliates,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ProfileValueJobs,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.AffiliateCareClaimsSubmission,
                group: TargetGroup.VirtualCare,
                wfOnly: false,
            },
            {
                target: Target.Calendars,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.VirtualCarePatients,
                group: TargetGroup.VirtualCare,
                wfOnly: false,
            },
            {
                target: Target.VirtualCareClaims,
                group: TargetGroup.VirtualCare,
                wfOnly: false,
            },
            {
                target: Target.Sla,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.SlaTimer,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.AffiliateCareInvoices,
                group: TargetGroup.VirtualCare,
                wfOnly: false,
            },
            {
                target: Target.AffiliateInvoices,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.ArticleExport,
                group: TargetGroup.Config,
                wfOnly: true,
            },
            {
                target: Target.PatientTranscript,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.AffiliateCareSettings,
                group: TargetGroup.VirtualCare,
                wfOnly: false,
            },
            {
                target: Target.VirtualCareEligibilityResults,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.DoulaInvoices,
                group: TargetGroup.Doula,
                wfOnly: false,
            },
            {
                target: Target.DoulaClaimsSubmission,
                group: TargetGroup.Doula,
                wfOnly: false,
            },
            {
                target: Target.VcExternalFormTemplates,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.VcExternalForm,
                group: TargetGroup.Patients,
                wfOnly: false,
            },
            {
                target: Target.PatientEstimatesSubmitted,
                group: TargetGroup.Config,
                wfOnly: false,
            },
            {
                target: Target.AppBuilds,
                group: TargetGroup.Config,
                wfOnly: true,
            },
            {
                target: Target.EligibilityCheckResults,
                group: TargetGroup.Config,
                wfOnly: true,
            },
        ];
    }
}

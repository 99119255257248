import React from 'react';
import { Typography } from '@mui/material';
import type { TranscriptFiltersProps } from '../types';
import { CardTypeFilter } from './CardTypeFilter';
import { DateRangeFilter } from './DateRangeFilter';

export const TranscriptFilters = ({
    cardTypes,
    setCardTypes,
    dateRange,
    setDateRange,
    customStartDate,
    setCustomStartDate,
    customEndDate,
    setCustomEndDate,
}: TranscriptFiltersProps) => {
    return (
        <div className="flex flex-row items-center justify-between">
            <Typography variant="h5">Patient Transcript</Typography>
            <div className="flex flex-row gap-2">
                <CardTypeFilter cardTypes={cardTypes} setCardTypes={setCardTypes} />
                <DateRangeFilter
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    customStartDate={customStartDate}
                    setCustomStartDate={setCustomStartDate}
                    customEndDate={customEndDate}
                    setCustomEndDate={setCustomEndDate}
                />
            </div>
        </div>
    );
};

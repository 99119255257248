import { format } from 'date-fns';
import { ProfileValueData, UserProfileDefChoice, UserProfileValueType } from '~/schemaTypes';
import { displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';

const dateFormat = 'MM/dd/yyyy';
const dateTimeFormat = 'MM/DD/yyyy, hh:mm:ss A';

function arrayToString<T extends number | string | boolean>(value: T[], isDateValue = false) {
    return value
        .reduce((acc, item) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            let _item = item;

            if (isDateValue) _item = format(new Date(item as string), dateFormat) as T;

            return `${acc}\n${_item}`;
        }, '')
        .trim();
}

const guardValue = (value: any) => {
    if (value === undefined || value === null) throw new Error();

    return value;
};

export type ChoiceConfiguration = {
    choices?: UserProfileDefChoice[];
    choiceValueType?:
        | UserProfileValueType.Str
        | UserProfileValueType.Num
        | UserProfileValueType.Bool
        | UserProfileValueType.Date;
};

type DateConfiguration = {
    isDateTime?: boolean | null;
};

type ValueTypeMapper = {
    [key: string]: (
        value: ProfileValueData,
    ) => (choiceConfiguration: ChoiceConfiguration, dateConfiguration: DateConfiguration) => string;
};

const valueTypeMapper: ValueTypeMapper = {
    [UserProfileValueType.Str]: (value: ProfileValueData) => () => guardValue(value.str),
    [UserProfileValueType.Num]: (value: ProfileValueData) => () =>
        Number(guardValue(value.num)).toString(),
    [UserProfileValueType.Bool]: (value: ProfileValueData) => () =>
        Boolean(guardValue(value.bool)).toString(),
    [UserProfileValueType.Date]:
        (value: ProfileValueData) =>
        (_, { isDateTime }) =>
            isDateTime
                ? displayDateWithAbbrTimeZone({
                      isoDateStr: value.date,
                      format: dateTimeFormat,
                      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  })
                : format(new Date(guardValue(value.date)), dateFormat),
    [UserProfileValueType.Strs]: (value: ProfileValueData) => () =>
        arrayToString<string>(guardValue(value.strs)),
    [UserProfileValueType.Nums]: (value: ProfileValueData) => () =>
        arrayToString<number>(guardValue(value.nums)),
    [UserProfileValueType.Bools]: (value: ProfileValueData) => () =>
        arrayToString<boolean>(guardValue(value.bools)),
    [UserProfileValueType.Dates]: (value: ProfileValueData) => () =>
        arrayToString<string>(guardValue(value.dates), true),
    [UserProfileValueType.Choice]:
        (value: ProfileValueData) =>
        ({ choices, choiceValueType }) => {
            guardValue(choices);
            guardValue(value.choice);

            if (choiceValueType == null) {
                return '';
            }

            const choice = choices?.find(c => c.id === value.choice);

            let stringifiedChoiceValue = choice?.value?.[choiceValueType]?.toString() ?? '';

            if (choice?.specifyValue) {
                const specifiedItem = value?.specified?.find(
                    i => i.choiceId === choice.id.toString(),
                );
                stringifiedChoiceValue = specifiedItem?.val[choiceValueType].toString() ?? '';
            }

            if (choiceValueType === UserProfileValueType.Date) {
                stringifiedChoiceValue = format(new Date(stringifiedChoiceValue), dateFormat);
            }

            return choice ? `Label: ${choice.label?.en}, Value: ${stringifiedChoiceValue}` : '';
        },
    [UserProfileValueType.Choices]:
        (value: ProfileValueData) =>
        ({ choices, choiceValueType }) => {
            guardValue(choices);
            guardValue(value.choices);

            const { choices: selectedChoices, specified } = value;

            if (choices == null || choiceValueType == null) return '';

            return choices
                .reduce((acc, choice) => {
                    const { id, value: choiceValue, specifyValue } = choice;
                    const choiceId = id.toString();

                    if (selectedChoices?.includes(choiceId)) {
                        let stringifiedChoiceValue =
                            choiceValue?.[choiceValueType]?.toString() ?? '';

                        if (specifyValue) {
                            const specifiedItem = specified?.find(i => i.choiceId === choiceId);
                            stringifiedChoiceValue =
                                specifiedItem?.val[choiceValueType].toString() ?? '';
                        }

                        if (choiceValueType === UserProfileValueType.Date) {
                            stringifiedChoiceValue = format(
                                new Date(stringifiedChoiceValue),
                                dateFormat,
                            );
                        }

                        return `${acc}\r\n{Label: ${choice.label?.en}, Value: ${stringifiedChoiceValue}},`;
                    }

                    return acc;
                }, '')
                .trim();
        },
    [UserProfileValueType.Localized]: (value: ProfileValueData) => () =>
        `${guardValue(value.localized).en}\n${value.localized?.es || ''}`,
};

export const mapProfileVariableValue = (
    value: ProfileValueData,
    type: UserProfileValueType,
    choiceConfiguration?: ChoiceConfiguration,
    dateConfiguration?: DateConfiguration,
) => {
    try {
        return valueTypeMapper[type](value)(choiceConfiguration ?? {}, dateConfiguration ?? {});
    } catch {
        /*
            in case of any error like using the value which is null or undefined =>
            return an empty string
         */
        return '';
    }
};

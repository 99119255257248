import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import { format, toDate } from 'date-fns-tz';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DateInput from '~/components/DateInput/DateInput';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    Patient,
    PatientInsurancePlan,
    useUpdatePatientOnPatientsPageMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import '../../../VirtualCareSurvey/yupSchema';

type EditInsuranceDialogProps = {
    patient: Partial<Patient>;
    onClose: () => void;
};

type EditInsuranceDate = {
    eligibilityStart: string;
    eligibilityEnd: string;
};

const EDIT_INSURANCE_SCHEMA = Yup.object().shape({
    eligibilityStart: Yup.date().dateFormatVCF(),
    eligibilityEnd: Yup.date().dateFormatVCF(),
});

const EditInsuranceDialog: React.FC<EditInsuranceDialogProps> = props => {
    const { patient, onClose } = props;
    const [isActiveInsuranceExists, setIsActiveInsuranceExists] = useState(false);
    const [activeInsutancePlan, setActiveInsutancePlan] = useState<PatientInsurancePlan>({});

    const { control, setValue, handleSubmit } = useForm({
        resolver: yupResolver(EDIT_INSURANCE_SCHEMA as any),
    });

    useEffect(() => {
        if (patient) {
            const activeInsutance = patient.insurancePlans?.find(plan => plan?.isActive);

            if (activeInsutance) {
                setActiveInsutancePlan(activeInsutance);
                setValue(
                    'eligibilityStart',
                    activeInsutance.eligibilityStartDate
                        ? format(new Date(activeInsutance.eligibilityStartDate), 'MM/dd/yyyy')
                        : '',
                );
                setValue(
                    'eligibilityEnd',
                    activeInsutance.eligibilityEndDate
                        ? format(new Date(activeInsutance.eligibilityEndDate), 'MM/dd/yyyy')
                        : '',
                );

                setIsActiveInsuranceExists(true);
            }
        }
    }, [patient, setValue]);

    const [updatePatient, { loading: updatePatientLoading }] =
        useUpdatePatientOnPatientsPageMutation({
            onCompleted: data => {
                if (data.updatePatient?.resourceUpdated?.id) {
                    onClose();
                    setActiveInsutancePlan({});
                }
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `${error}`,
                });
            },
        });

    const onSubmit = async (data: EditInsuranceDate) => {
        const { __typename, ...restActivePlan } = activeInsutancePlan;
        const activePlan = {
            ...restActivePlan,
            eligibilityStartDate: format(toDate(data.eligibilityStart), 'MM/dd/yyyy'),
            eligibilityEndDate: format(toDate(data.eligibilityEnd), 'MM/dd/yyyy'),
        };

        const inactiveInsurancePlans =
            patient.insurancePlans?.filter(
                (item): item is PatientInsurancePlan => item !== null && !item.isActive,
            ) ?? [];

        await updatePatient({
            variables: {
                input: {
                    id: patient.id,
                    data: {
                        insurancePlans: [
                            ...inactiveInsurancePlans.map(
                                ({ __typename, ...planData }) => planData,
                            ),
                            activePlan,
                        ],
                    },
                },
            },
        });
    };

    if (updatePatientLoading) return <Loading />;

    return (
        <div>
            {isActiveInsuranceExists ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitleWithClose id="edit-insurance-dates" onClose={onClose}>
                        Insurance Information
                    </DialogTitleWithClose>
                    <DialogContent dividers>
                        <Grid container>
                            <Grid item xs={12} container>
                                <TextField
                                    disabled
                                    value={activeInsutancePlan.name}
                                    fullWidth
                                    label="Name"
                                />
                            </Grid>
                            <Grid item xs={12} container>
                                <TextField
                                    disabled
                                    value={activeInsutancePlan.type}
                                    fullWidth
                                    label="Type"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="eligibilityStart"
                                    render={({ field }) => (
                                        <DateInput
                                            label="Eligibility Start Date"
                                            field={field}
                                            inputProps={{
                                                fullWidth: true,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="eligibilityEnd"
                                    render={({ field }) => (
                                        <DateInput
                                            label="Eligibility End Date"
                                            field={field}
                                            inputProps={{
                                                fullWidth: true,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary" variant="contained">
                            Save
                        </Button>
                        <Button onClick={onClose} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            ) : (
                <div style={{ textAlign: 'center', padding: '60px' }}>
                    <DialogTitleWithClose id="add-patient-to-calims" onClose={onClose}>
                        No Insurance Plans
                    </DialogTitleWithClose>
                </div>
            )}
        </div>
    );
};

export default EditInsuranceDialog;

import MaterialTable, { Column, OrderByCollection } from '@material-table/core';

import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import { makeStyles } from 'tss-react/mui';
import tableIcons from '~/helpers/tableIcons';
import { OrderByDirectionEnum, useEligibilityCheckResultsV2Query } from '~/schemaTypes';
import _ from 'lodash';
import { Badge, TablePagination } from '@mui/material';
import EligibilityCheckResultsFilterModal from '~/views/Dashboard/EligibilityCheckResults/EligibilityCheckResultsFilterModal/EligibilityCheckResultsFilterModal';
import { primaryColor, secondaryColor, errorRed } from '~/theme/WildTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useAtom } from 'jotai/index';
import { EligibilityCheckResultsFiltersState } from '~/views/Dashboard/EligibilityCheckResults/state';
import { useUserPermissions } from '~/hooks';
import EligibilityCheckResultsViewModal, {
    EligibilityCheckResult,
} from './EligibilityCheckResultsViewModal/EligibilityCheckResultsViewModal';

const useStyles = makeStyles()({
    root: {},
});

interface EligibilityCheckResultFieldsOrder {
    field: keyof EligibilityCheckResult;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}

const createCustomSort = (fieldName: keyof EligibilityCheckResult) => {
    return (a: EligibilityCheckResult, b: EligibilityCheckResult) => {
        const valueA = a[fieldName];
        const valueB = b[fieldName];

        if (valueA === valueB) return 0;
        if (valueA === null || valueA === undefined) return -1;
        if (valueB === null || valueB === undefined) return 1;

        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
        }

        return valueA > valueB ? 1 : -1;
    };
};

const tableColumns: ({
    field: keyof EligibilityCheckResult;
} & Column<EligibilityCheckResult>)[] = [
    {
        title: 'App Bundle Id',
        width: 'auto',
        field: 'appBundleId',
        customSort: createCustomSort('appBundleId'),
        tooltip: 'Bundle ID of the application this eligibility check is for',
    },
    {
        title: 'Unit Id',
        width: 'auto',
        field: 'unitId',
        customSort: createCustomSort('unitId'),
        tooltip: 'ID of the unit this eligibility check belongs to',
    },
    {
        title: 'Is Success',
        field: 'success',
        type: 'boolean',
        cellStyle: (_, data) => ({ color: data.success ? secondaryColor : errorRed }),
        customSort: createCustomSort('success'),
        tooltip: 'Whether the eligibility check completed successfully',
    },
    {
        title: 'Is Eligible',
        field: 'isEligible',
        type: 'boolean',
        cellStyle: (_, data) => ({ color: data.isEligible ? secondaryColor : errorRed }),
        customSort: createCustomSort('isEligible'),
        tooltip: 'Whether the member is eligible based on the check results',
    },
];

const columnIdToSortingFieldMap = tableColumns.reduce((accum, item, index) => {
    accum[index] = item.field;
    return accum;
}, {} as Record<number, keyof EligibilityCheckResult>);

const initialOrder: EligibilityCheckResultFieldsOrder = {
    field: 'createdAt',
    direction: OrderByDirectionEnum.Desc,
    isInit: true,
};

const EligibilityCheckResults = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const [filters] = useAtom(EligibilityCheckResultsFiltersState);
    const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
    const [viewModalData, setViewModalData] = useState<EligibilityCheckResult | null>(null);
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<EligibilityCheckResultFieldsOrder>(initialOrder);

    const { data: listData, loading: dataLoading } = useEligibilityCheckResultsV2Query({
        variables: {
            input: {
                filter: {
                    fields: {
                        appBundleId: filters.appBundleId,
                        isEligible: filters.isEligible,
                        success: filters.isSuccess,
                    },
                    fieldsInList: {
                        unitId: filters.unitIds,
                    },
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                pagination: {
                    limit: rowsPerPage,
                    skip: page * rowsPerPage,
                },
            },
        },
    });

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setPage(pageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        if (!orderByCollection || orderByCollection.length === 0) {
            setOrder(initialOrder);
            return;
        }

        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];

        if (field) {
            setOrder({
                direction:
                    orderBy.orderDirection.toUpperCase() === OrderByDirectionEnum.Asc
                        ? OrderByDirectionEnum.Asc
                        : OrderByDirectionEnum.Desc,
                field,
                isInit: field !== order.field,
            });
        }
    };

    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<EligibilityCheckResult>
                    title="Eligibility Check Results"
                    icons={tableIcons}
                    isLoading={dataLoading}
                    page={page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onOrderCollectionChange={handleOrderChange}
                    data={_.cloneDeep(listData?.eligibilityCheckResultsV2.results) ?? []}
                    actions={[
                        {
                            tooltip: 'Filter Eligibility Check Results',
                            onClick: () => {
                                setIsFilterModal(true);
                            },
                            icon: () => (
                                <Badge
                                    badgeContent={
                                        Object.values(filters).filter(i => i !== undefined).length
                                    }
                                    color="error"
                                    style={{
                                        padding: '12px',
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                        fontSize: '1rem',
                                        borderRadius: '1rem',
                                    }}
                                >
                                    Filter
                                </Badge>
                            ),
                            isFreeAction: true,
                        },
                        {
                            onClick: (_, data: EligibilityCheckResult) => setViewModalData(data),
                            hidden: !pagePermissions?.EligibilityCheckResults.Read,
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View Eligibility Check Result',
                        },
                    ]}
                    columns={tableColumns}
                    options={{
                        search: false,
                        paging: true,
                        pageSize: rowsPerPage,
                        pageSizeOptions: [10, PAGESIZE, 50],
                        debounceInterval: 500,
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={listData?.eligibilityCheckResultsV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                />
            </div>
            <EligibilityCheckResultsFilterModal
                isOpen={isFilterModal}
                onClose={() => setIsFilterModal(false)}
            />
            <EligibilityCheckResultsViewModal
                eligibilityCheckResult={viewModalData}
                onClose={() => setViewModalData(null)}
            />
        </>
    );
};

export default EligibilityCheckResults;

import { atomWithStorage } from 'jotai/utils';

export type EligibilityCheckResultsFilters = {
    appBundleId?: string;
    isSuccess?: boolean;
    isEligible?: boolean;
    unitIds?: string[];
};

export const EligibilityCheckResultsFiltersState = atomWithStorage<EligibilityCheckResultsFilters>(
    'EligibilityCheckResultsFilters',
    {},
);

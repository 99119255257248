import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Avatar,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Select,
    MenuItem,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import SaveIcon from '@mui/icons-material/Save';
import { FileInfo } from '@uploadcare/react-widget';
import React, { Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ROOT_ORG_ID, CIGNA_LACTATION_ORG_ID } from '~/constants';
import {
    FetchOrganizationsFromCurrentUserQuery,
    FetchRolesForStaffModalQueryQuery,
    useCreateStaffInStaffModalMutation,
    useFetchCurrentUserForStaffModalQuery,
    useFetchOrganizationsFromCurrentUserQuery,
    useFetchRolesForStaffModalQueryQuery,
    useFetchStaffByIdForStaffModalLazyQuery,
    useUpdateStaffInStaffModalMutation,
    useStaffVirtualCareAffiliatessV2Query,
    OrderByDirectionEnum,
    User,
} from '~/schemaTypes';
import { AdminUserEnum } from '~/selectors';
import { TriggerGlobalImageUploader } from '~/state';
import useUpdateCache from '../useUpdateCache';

type Role = NonNullable<FetchRolesForStaffModalQueryQuery['roles'][0]>;
type Organization = NonNullable<
    FetchOrganizationsFromCurrentUserQuery['currentUser']
>['organizations'][0];

const STAFF_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required(),
    title: Yup.string(),
    photo: Yup.string(),
    smsNumber: Yup.string(),
    email: Yup.string().required(),
    rootOrg: Yup.string().required(),
    affiliateId: Yup.string().when('rootOrg', ([rootOrg], schema) => {
        if (rootOrg === CIGNA_LACTATION_ORG_ID) {
            return schema.required();
        }
        return schema;
    }),
});

// eslint-disable-next-line prefer-regex-literals
const isAffiliatePattern = RegExp(/affiliate/i);

const useStyles = makeStyles()({
    root: {},
    photoFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    photoField: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    photoIcon: {
        marginRight: 75,
        marginLeft: 20,
    },
});

interface StaffFormInput {
    rootOrg: string;
    email: string;
    smsNumber: string;
    roleId: string;
    name: string;
    title: string;
    photo: string;
    affiliateId?: string;
}

type StaffModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditStaffId: Dispatch<SetStateAction<string>>;
    id?: string;
};

const findClientOrg = (orgId: string, orgs: Organization[]): string | undefined => {
    const org = orgs.find(o => o.id === orgId);
    if (org === undefined || org.parentId === undefined) return undefined;
    if (org.parentId === ROOT_ORG_ID) return org.id;
    return findClientOrg(org.parentId || '', orgs);
};

const StaffModal: React.FC<StaffModalProps> = ({ setOpen, setEditStaffId, id }) => {
    const { classes } = useStyles();
    const [photoUrl, setPhotoUrl] = useState<string>('');
    const [isStaff, setIsStaff] = useState(true);
    const [isTestData, setIsTestData] = useState<boolean>(false);
    const [canPromoteToProd, setCanPromoteToProd] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [selectedClientOrgId, setSelectedClientOrgId] = useState(ROOT_ORG_ID);
    const [isAffiliateOrg, setIsAffiliateOrg] = useState(false);
    const {
        register,
        handleSubmit,
        control,
        reset,
        resetField,
        formState: { errors },
        watch,
    } = useForm<StaffFormInput>({
        resolver: yupResolver(STAFF_VALIDATION_SCHEMA as any),
    });
    const [rootOrg] = watch(['rootOrg']);
    const { data: roleData, loading: roleDataLoading } = useFetchRolesForStaffModalQueryQuery();

    const { data: currentUserQuery } = useFetchCurrentUserForStaffModalQuery();
    const { data: orgsData } = useFetchOrganizationsFromCurrentUserQuery();
    const { data: affiliates } = useStaffVirtualCareAffiliatessV2Query({
        variables: {
            input: {
                orderBy: {
                    field: 'externalName',
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
    });

    const [getStaff, { data: staffQuery, loading }] = useFetchStaffByIdForStaffModalLazyQuery({
        onCompleted: data => {
            if (data.user) {
                if (data.user.photo) {
                    setPhotoUrl(data.user.photo);
                }
                setIsStaff(data.user.isStaff ?? true);
                setIsTestData(data.user.isTestData ?? false);
                setSelectedRole(data.user.roleId ?? '');
                setSelectedClientOrgId(data.user.clientOrgId ?? ROOT_ORG_ID);
                setCanPromoteToProd(data.user.canPromoteToProd || false);
                setIsAffiliateOrg(data?.user?.rootOrg.id === CIGNA_LACTATION_ORG_ID);
                reset({
                    rootOrg: data.user.rootOrg.id,
                    email: data.user.email,
                    smsNumber: data.user.smsNumber ?? '',
                    name: data.user.name,
                    title: data.user.title ?? '',
                    photo: data.user.photo ?? '',
                    affiliateId: data.user.affiliateId ?? '',
                });
            }
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (rootOrg !== '' && currentUserQuery?.currentUser) {
            if (rootOrg === ROOT_ORG_ID) setSelectedClientOrgId(ROOT_ORG_ID);
            else {
                const clientOrgId = findClientOrg(
                    rootOrg,
                    orgsData?.currentUser?.organizations || [],
                );
                if (clientOrgId) setSelectedClientOrgId(clientOrgId);
            }
        }
        if (rootOrg === CIGNA_LACTATION_ORG_ID) {
            setIsAffiliateOrg(true);
        } else {
            setIsAffiliateOrg(false);
            resetField('affiliateId');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootOrg, orgsData]);

    const affiliateOptions = useMemo(() => {
        const initialOption = {
            title: 'Select Affiliate',
            value: '',
        };
        if (affiliates?.virtualCareAffiliatessV2.results) {
            return [
                initialOption,
                ...affiliates.virtualCareAffiliatessV2.results.map(affiliate => ({
                    title: affiliate.externalName,
                    value: affiliate.id,
                })),
            ];
        }
        return [initialOption];
    }, [affiliates]);

    const [updateUser, { loading: updateUserLoading }] = useUpdateStaffInStaffModalMutation();
    const { updateAfterCreate } = useUpdateCache(currentUserQuery?.currentUser?.currentOrg.id);
    const [createUser, { loading: createUserLoading }] = useCreateStaffInStaffModalMutation({
        update: (cache, response) => {
            updateAfterCreate(
                cache,
                !!response.data?.createUser?.success,
                response.data?.createUser?.user as User,
            );
        },
    });

    const onSubmit = ({ rootOrg, email, smsNumber, name, title, affiliateId }: StaffFormInput) => {
        if (id) {
            updateUser({
                variables: {
                    input: {
                        id,
                        data: {
                            rootOrg,
                            email,
                            smsNumber,
                            name,
                            title,
                            photo: photoUrl,
                            isStaff,
                            roleId: selectedRole,
                            isTestData,
                            affiliateId: affiliateId || null,
                            canPromoteToProd,
                        },
                    },
                },
            });
        } else {
            createUser({
                variables: {
                    input: {
                        clientOrgId: selectedClientOrgId,
                        rootOrg,
                        email,
                        smsNumber,
                        name,
                        title,
                        photo: photoUrl,
                        isStaff,
                        roleId: selectedRole,
                        isTestData,
                        affiliateId: affiliateId || null,
                        canPromoteToProd,
                    },
                },
            });
        }
        setOpen(false);
    };

    const onImageUpload = (file: FileInfo) => {
        const newLogo = file.cdnUrl;
        if (newLogo) {
            setPhotoUrl(newLogo);
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditStaffId('');
    };
    useEffect(() => {
        if (id) {
            getStaff({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getStaff]);
    useEffect(() => {
        if (roleData) {
            const roles = roleData?.roles
                .filter(r => r.organizationId === selectedClientOrgId)
                .filter(r => isAffiliatePattern.test(r.name) === isAffiliateOrg)
                .sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name === b.name) return 0;
                    return 1;
                });
            setRoleList(roles);
            if (!id && roles.length > 0) setSelectedRole(roles[0].id);
        }
    }, [roleData, selectedClientOrgId, id, isAffiliateOrg]);

    if (loading || roleDataLoading || updateUserLoading || createUserLoading) {
        return <Loading />;
    }

    return (
        <form
            className={classes.root}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            data-test={AdminUserEnum.MODAL}
        >
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                {staffQuery?.user?.id === undefined ? 'New Admin User' : 'Edit Admin User'}
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="rootOrg"
                            defaultValue={
                                id
                                    ? staffQuery?.user?.rootOrg.id
                                    : currentUserQuery?.currentUser?.currentOrg.id
                            }
                            render={({ field: { onChange } }) => (
                                <Autocomplete
                                    size="small"
                                    onChange={(_, org) => {
                                        onChange(org?.value);
                                    }}
                                    getOptionLabel={selected => selected.name ?? ''}
                                    fullWidth
                                    disabled={!!id}
                                    options={
                                        currentUserQuery?.currentUser?.currentOrganizations.map(
                                            p => ({
                                                name: p.brandingName,
                                                value: p.id,
                                            }),
                                        ) ?? []
                                    }
                                    defaultValue={
                                        id
                                            ? {
                                                  name: staffQuery?.user?.rootOrg.brandingName,
                                                  value: staffQuery?.user?.rootOrg.id,
                                              }
                                            : {
                                                  name: currentUserQuery?.currentUser?.currentOrg
                                                      .brandingName,
                                                  value: currentUserQuery?.currentUser?.currentOrg
                                                      .id,
                                              }
                                    }
                                    isOptionEqualToValue={(option, val) => {
                                        return option.value === val.value;
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder="Select your practice"
                                            error={!!errors.rootOrg}
                                            helperText={
                                                !id
                                                    ? errors.rootOrg?.message
                                                    : "Editing a user's top level practice is not supported"
                                            }
                                            label="Top Level Practice"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid container item xs={6}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Is Staff?"
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        id="isStaff"
                                        name="isStaff"
                                        checked={isStaff}
                                        onChange={e => setIsStaff(e.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Is Test User?"
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        id="isTestData"
                                        name="isTestData"
                                        checked={isTestData}
                                        onChange={e => setIsTestData(e.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Can Promote Builds to Production?"
                                labelPlacement="start"
                                control={
                                    <Checkbox
                                        id="canPromoteToProd"
                                        name="canPromoteToProd"
                                        checked={canPromoteToProd}
                                        onChange={e => setCanPromoteToProd(e.target.checked)}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedSection title="Role">
                            <select id="roleId" onChange={e => setSelectedRole(e.target.value)}>
                                {roleList &&
                                    roleList.map(r => (
                                        <option value={r.id} selected={r.id === selectedRole}>
                                            {r.name}
                                        </option>
                                    ))}
                            </select>
                        </OutlinedSection>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.name}
                            {...register('name')}
                            label="Name"
                            id="name"
                            type="text"
                            margin="dense"
                            fullWidth
                            helperText={errors.name?.message}
                            InputLabelProps={{ shrink: true }}
                            data-test={AdminUserEnum.NAME}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.title}
                            {...register('title')}
                            label="Title"
                            id="title"
                            type="text"
                            margin="dense"
                            fullWidth
                            helperText={errors.title?.message}
                            InputLabelProps={{ shrink: true }}
                            data-test={AdminUserEnum.TITLE}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Email"
                            id="email"
                            {...register('email')}
                            type="text"
                            margin="dense"
                            fullWidth
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            InputLabelProps={{ shrink: true }}
                            data-test={AdminUserEnum.EMAIL}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            error={!!errors.smsNumber}
                            {...register('smsNumber')}
                            label="SMS Number"
                            id="smsNumber"
                            type="text"
                            margin="dense"
                            fullWidth
                            helperText={errors.smsNumber?.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" paragraph>
                            Photo
                        </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.photoFieldContainer}>
                        <Grid item xs={12} className={classes.photoField}>
                            <Grid item xs={1} className={classes.photoIcon}>
                                <Avatar
                                    style={{ height: 75, width: 75, textAlign: 'center' }}
                                    src={photoUrl ?? ''}
                                >
                                    No Photo
                                </Avatar>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        TriggerGlobalImageUploader({
                                            callback: onImageUpload,
                                            isImage: true,
                                        })
                                    }
                                >
                                    Change Photo
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {isAffiliateOrg && (
                        <Grid item xs={12}>
                            <OutlinedSection title="Affiliate">
                                <Controller
                                    name="affiliateId"
                                    control={control}
                                    render={({ field: { onChange, value, ...props } }) => (
                                        <Select
                                            id="affiliateId"
                                            onChange={e => onChange(e)}
                                            error={!!errors.affiliateId}
                                            value={value ?? ''}
                                            fullWidth
                                            displayEmpty
                                            {...props}
                                        >
                                            {affiliateOptions.map(affiliate => (
                                                <MenuItem
                                                    key={affiliate.value}
                                                    value={affiliate.value}
                                                >
                                                    {affiliate.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </OutlinedSection>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    data-test={AdminUserEnum.SAVE_CREATE}
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default StaffModal;

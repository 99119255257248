import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Checkbox, Dialog, IconButton, Link, TablePagination, Tooltip } from '@mui/material';
import { Add, InfoOutlined } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import MaterialTable, { MaterialTableProps, OrderByCollection } from '@material-table/core';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import tableIcons from '~/helpers/tableIcons';
import { useUser, useUserPermissions } from '~/hooks';
import {
    OrderByDirectionEnum,
    ReferencePages,
    useOrdersForOrdersPageLazyQuery,
    useFetchReferenceLinksForOrdersPageQuery,
    AlertSeverity,
    OrderTypes,
} from '~/schemaTypes';
import { PAGESIZE } from '~/constants';
import { TriggerGlobalAlert } from '~/state';
import copy from 'copy-to-clipboard';
import { primaryColor } from '~/theme/WildTheme';
import { flipDirection } from '../../../helpers/flipDirection';
import OrderModal from './OrderModal/OrderModal';
import { IOrdersOrderChange, OrdersSortingFields } from './types';
import { FilterOrdersModal } from './FilterOrdersModal';

type OrdersProps = {
    setLinks?: any;
};

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
}));
const FILTER_BY_NOTES = {
    bfCuff: 'Device(s)->BP Cuff',
    glucometer: 'Device(s)->Glucometer',
};

const columnIdToSortingFieldMap: Record<number, OrdersSortingFields> = {
    2: OrdersSortingFields.procedure,
    4: OrdersSortingFields.patientFullName,
    5: OrdersSortingFields.transactionDateTime,
    6: OrdersSortingFields.isDeviceOrder,
};

const Orders: React.FC<OrdersProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const history = useNavigate();

    const { data: userData } = useUser();

    const [page, setPage] = useState<number>(0);
    const [showOrderModal, setShowOrderModal] = useState<boolean>(false);
    const [orderIdToShow, setOrderIdToShow] = useState<string | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const filterOrdersBPCuffsLoc = localStorage.getItem(`filterOrdersBPCuffs`);
    const [filterOrdersBPCuffs, setFilterOrdersBPCuffs] = useState<boolean>(
        filterOrdersBPCuffsLoc ? JSON.parse(filterOrdersBPCuffsLoc) : false,
    );
    const filterOrdersGlucometersLoc = localStorage.getItem(`filterOrdersGlucometers`);
    const [filterOrdersGlucometers, setFilterOrdersGlucometers] = useState<boolean>(
        filterOrdersGlucometersLoc ? JSON.parse(filterOrdersGlucometersLoc) : false,
    );
    const filterOrdersAdvocacyReferralsLoc = localStorage.getItem(`filterOrdersAdvocacyReferrals`);
    const [filterOrdersAdvocacyReferrals, setFilterOrdersAdvocacyReferrals] = useState<boolean>(
        filterOrdersAdvocacyReferralsLoc ? JSON.parse(filterOrdersAdvocacyReferralsLoc) : false,
    );
    const filterOrdersDeviceOrderLoc = localStorage.getItem(`filterOrdersDeviceOrder`);
    const [filterOrdersDeviceOrder, setFilterOrdersDeviceOrder] = useState<boolean>(
        filterOrdersDeviceOrderLoc ? JSON.parse(filterOrdersDeviceOrderLoc) : false,
    );
    const filterOrdersRPMLoc = localStorage.getItem(`filterOrdersRPMEligible`);
    const [filterOrdersRPM, setFilterOrdersRPM] = useState<boolean>(
        filterOrdersRPMLoc ? JSON.parse(filterOrdersRPMLoc) : false,
    );
    const filterOrdersVBCLoc = localStorage.getItem(`filterOrdersVBCEligible`);
    const [filterOrdersVBC, setFilterOrdersVBC] = useState<boolean>(
        filterOrdersVBCLoc ? JSON.parse(filterOrdersVBCLoc) : false,
    );

    const [order, setOrder] = useState<IOrdersOrderChange>({
        field: OrdersSortingFields.createdAt,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
    });

    const { pagePermissions } = useUserPermissions();
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
    const variables = useMemo(() => {
        const notes: string[] = [];
        const isDeviceTypeDefined = filterOrdersGlucometers || filterOrdersBPCuffs;
        if (filterOrdersGlucometers) {
            notes.push(FILTER_BY_NOTES.glucometer);
        }
        if (filterOrdersBPCuffs) {
            notes.push(FILTER_BY_NOTES.bfCuff);
        }
        const filterTypes = new Set<OrderTypes>([]);
        if (filterOrdersAdvocacyReferrals) {
            filterTypes.add(OrderTypes.Referral);
        }
        if (filterOrdersDeviceOrder) {
            filterTypes.add(OrderTypes.Device);
        }

        return {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                filter: {
                    fields: {
                        ...(userData?.currentUser?.currentOrg?.id && {
                            practiceId: userData?.currentUser?.currentOrg?.id,
                        }),
                        ...((filterOrdersAdvocacyReferrals || filterOrdersDeviceOrder) && {
                            types: [...filterTypes],
                        }),
                        ...(filterOrdersRPM && {
                            isRPMEligiblePatient: true,
                        }),
                        ...(filterOrdersVBC && {
                            isVBCEligiblePatient: true,
                        }),
                        ...(isDeviceTypeDefined && {
                            notes,
                        }),
                    },
                },
            },
        };
    }, [
        page,
        rowsPerPage,
        order,
        userData?.currentUser?.currentOrg?.id,
        filterOrdersBPCuffs,
        filterOrdersGlucometers,
        filterOrdersAdvocacyReferrals,
        filterOrdersDeviceOrder,
        filterOrdersRPM,
        filterOrdersVBC,
    ]);

    const [fetchOrders, { data: ordersData, loading: ordersLoading }] =
        useOrdersForOrdersPageLazyQuery({
            variables,
            fetchPolicy: 'network-only',
        });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForOrdersPageQuery({
            variables: {
                input: {
                    page: ReferencePages.Orders,
                },
            },
        });

    useEffect(() => {
        if (filterOrdersDeviceOrderLoc !== null) {
            setFilterOrdersDeviceOrder(JSON.parse(filterOrdersDeviceOrderLoc));
        }
        if (filterOrdersGlucometersLoc !== null) {
            setFilterOrdersGlucometers(JSON.parse(filterOrdersGlucometersLoc));
        }
        if (filterOrdersBPCuffsLoc !== null) {
            setFilterOrdersBPCuffs(JSON.parse(filterOrdersBPCuffsLoc));
        }
        if (filterOrdersAdvocacyReferralsLoc !== null) {
            setFilterOrdersAdvocacyReferrals(JSON.parse(filterOrdersAdvocacyReferralsLoc));
        }
        if (filterOrdersRPMLoc !== null) {
            setFilterOrdersRPM(JSON.parse(filterOrdersRPMLoc));
        }
        if (filterOrdersVBCLoc !== null) {
            setFilterOrdersVBC(JSON.parse(filterOrdersVBCLoc));
        }
    }, [
        filterOrdersAdvocacyReferralsLoc,
        filterOrdersBPCuffsLoc,
        filterOrdersGlucometersLoc,
        filterOrdersDeviceOrderLoc,
        filterOrdersRPMLoc,
        filterOrdersVBCLoc,
    ]);

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    useEffect(() => {
        if (userData?.currentUser?.currentOrg?.id) {
            fetchOrders();
        }
    }, [fetchOrders, userData?.currentUser?.currentOrg?.id]);

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const changeDirection = (previousDirection: OrderByDirectionEnum): void => {
        setOrder(state => ({
            ...state,
            direction: flipDirection(previousDirection),
            isInit: false,
        }));
    };

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];
        if (field) {
            if (field !== order.field) {
                setOrder(state => ({
                    ...state,
                    isInit: true,
                }));
            }
            const direction = order.isInit ? getDirection(orderBy.orderDirection) : order.direction;
            changeDirection(direction);
            setOrder(state => ({
                ...state,
                field,
            }));
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const renderStatus = (status: string) => {
        switch (status) {
            case 'NEW':
            case 'New':
                return <div style={{ color: 'green' }}>New</div>;
            case 'CANCEL':
            case 'Cancel':
                return <div style={{ color: 'red' }}>Cancelled by Provider</div>;
            default:
                return <div>{status}</div>;
        }
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                isLoading={ordersLoading || refereneLinksLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                columns={[
                    {
                        title: 'Order Id',
                        field: 'id',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Order Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        sorting: false,
                        render: ({ status }) => renderStatus(status),
                    },
                    {
                        title: 'Procedure',
                        field: 'procedure',
                        sorting: true,
                    },
                    {
                        title: 'Procedure Description',
                        field: 'procedureDescription',
                        sorting: false,
                    },
                    {
                        title: 'Patient Name',
                        field: 'patientFullName',
                        sorting: true,
                        render: ({ patientId, patientFullName }) =>
                            pagePermissions?.PatientDetails.Read ? (
                                <Link
                                    to={`/portal/patients/${patientId}/orders`}
                                    component={RouterLink}
                                    underline="hover"
                                >
                                    {patientFullName}
                                </Link>
                            ) : (
                                <div>{patientFullName}</div>
                            ),
                    },
                    {
                        title: 'Order Placed',
                        field: 'transactionDateTime',
                        sorting: true,
                        render: ({ transactionDateTime }) =>
                            transactionDateTime
                                ? moment
                                      .utc(transactionDateTime)
                                      .local()
                                      .format('YYYY-MM-DD HH:mm:ss')
                                : '',
                    },
                    {
                        title: 'Device Order',
                        field: 'isDeviceOrder',
                        sorting: true,
                        render: order => (
                            <Checkbox checked={order?.isDeviceOrder || false} disabled />
                        ),
                    },
                ]}
                data={ordersData?.ordersV2?.results ?? []}
                title="Orders"
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={ordersData?.ordersV2?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    paginationPosition: 'both',
                }}
                actions={[
                    {
                        tooltip: 'Filter Orders',
                        onClick: () => {
                            setOpenFilterModal(true);
                        },
                        icon: () => (
                            <Badge
                                invisible={
                                    parseInt(
                                        localStorage.getItem('filtersAppliedCountOrders') ?? '',
                                        10,
                                    ) === 0
                                }
                                badgeContent={localStorage.getItem('filtersAppliedCountOrders')}
                                color="error"
                                style={{
                                    padding: '12px',
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    fontSize: '1rem',
                                    borderRadius: '1rem',
                                }}
                            >
                                Filter Orders
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/orders/new'),
                        hidden: !pagePermissions?.Orders.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add New Order',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => {
                            setShowOrderModal(true);
                            setOrderIdToShow(id);
                        },
                        hidden: !pagePermissions?.Orders.Edit,
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View Order',
                    },
                ]}
            />
            {showOrderModal && (
                <Dialog
                    scroll="paper"
                    open={showOrderModal}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                >
                    <OrderModal setOpen={setShowOrderModal} orderId={orderIdToShow} />
                </Dialog>
            )}
            <FilterOrdersModal open={openFilterModal} onClose={() => setOpenFilterModal(false)} />
        </div>
    );
};

export default Orders;

import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { ClaimSubmittedStatus } from '~/schemaTypes';
import ClaimStatusView from '~/views/ConfigDashboard/Organizations/OrganizationCard/OrganizationClaims/components/ClaimStatus';
import allowUpload from '~/views/ConfigDashboard/Organizations/OrganizationCard/OrganizationClaims/helpers/allowUpload';
import UploadClaim from './ReUploadClaim';
import { PatientEstimatesSubmitted } from '../types';
import Download from './Download';

type ClaimStatusProps = {
    claim: PatientEstimatesSubmitted;
    downloadHandler: (name: string) => void;
    reUploadCb: () => void;
};

const ClaimStatus: React.FC<ClaimStatusProps> = props => {
    const { claim, reUploadCb, downloadHandler } = props;
    const allowReUpload = useMemo(
        () =>
            allowUpload({
                updatedAt: claim.updatedAt,
                uploaded: claim.uploaded,
                status: claim.status as unknown as ClaimSubmittedStatus,
                errorText: claim.error,
            }),
        [claim],
    );
    const allowDownload =
        claim.visitInsuranceFileName || claim.visitFileName || claim.patientFileName;
    return (
        <Grid container item xs={12} alignItems="center" columnSpacing={1}>
            <Grid item>
                <ClaimStatusView
                    claim={{
                        status: claim.status as unknown as ClaimSubmittedStatus,
                        errorText: claim.error,
                    }}
                    allowUpload={allowReUpload}
                />
            </Grid>
            {allowReUpload && (
                <Grid item>
                    <UploadClaim estimateId={claim.id} callback={reUploadCb} />
                </Grid>
            )}
            {allowDownload && (
                <Grid item>
                    <Download claim={claim} downloadHandler={downloadHandler} />
                </Grid>
            )}
        </Grid>
    );
};

export default ClaimStatus;

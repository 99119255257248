import moment from 'moment';
import {
    AppointmentMethod as AppointmentMethodEnum,
    DestinationNames,
    InsurancePlan,
    VcfFormFlow,
} from '~/schemaTypes';
import { RelationshipToInsuredEnum, VirtualCareSurveyInput } from '~/views/VirtualCareSurvey/types';

export const dateToString = (date: Date) => date.toISOString().split('T')[0];

export const DATE_FORMAT = 'MM/DD/YYYY';

type PreparePayloadProps = {
    formData: VirtualCareSurveyInput;
    insuredAddressSameAsPatient: boolean;
    isAffiliateView: boolean;
    isDoulaView: boolean;
    affiliateData?: {
        id?: string;
        affiliateIdApta?: string;
        selfCheck: boolean;
    };
    insurancePlan?: InsurancePlan | null;
    isApta?: boolean;
    eligibilityData?: {
        eligibilityRequestId: string | null;
        eligibilityGroupNumber: string | null;
    };
    isEligibilityRefresh?: boolean;
};

export const preparePayload = (props: PreparePayloadProps) => {
    const {
        formData: data,
        insuredAddressSameAsPatient,
        isAffiliateView,
        isApta,
        isDoulaView,
        affiliateData: affiliate,
        insurancePlan,
        eligibilityData,
        isEligibilityRefresh,
    } = props;
    const isSelfInsured = data.patientRelationshipToInsured === RelationshipToInsuredEnum.SELF;
    if (isSelfInsured) {
        data.insuredFirstName = data.firstName;
        data.insuredLastName = data.lastName;
        data.subscriberSex = data.sex;
        data.subscriberBirthDate = data.birthDate;
    }
    if (insuredAddressSameAsPatient) {
        data.insuredAddressLine1 = data.addressLine1;
        data.insuredAddressLine2 = data.addressLine2;
        data.insuredCity = data.city;
        data.insuredState = data.state;
        data.insuredZipCode = data.zipCode;
    }
    return {
        firstName: data.firstName?.trim(),
        lastName: data.lastName?.trim(),
        birthDate:
            data.birthDate instanceof Date
                ? data.birthDate.toLocaleDateString()
                : moment(data.birthDate, DATE_FORMAT).toISOString(),
        address: {
            street1: data.addressLine1?.trim(),
            street2: data.addressLine2?.trim(),
            city: data.city?.trim(),
            state: data.state?.trim(),
            code: data.zipCode?.trim(),
        },
        phoneNumber: data.phoneNumber?.trim() || null,
        email: data.email?.trim(),
        sex: data.sex,
        insuranceId: data.insuranceId?.trim(),
        insuranceGroupNumber: data.insuranceGroup?.trim(),
        insuredFirstName: data.insuredFirstName?.trim(),
        insuredLastName: data.insuredLastName?.trim(),
        insuredAddress: {
            street1: data.insuredAddressLine1?.trim(),
            street2: data.insuredAddressLine2?.trim(),
            city: data.insuredCity?.trim(),
            state: data.insuredState?.trim(),
            code: data.insuredZipCode?.trim(),
        },
        insuredPhoneNumber: data.phoneNumber?.trim() || null,
        patientRelationshipToInsured: data.patientRelationshipToInsured,
        subscriberSex: data.subscriberSex,
        subscriberBirthDate:
            data.subscriberBirthDate instanceof Date
                ? data.subscriberBirthDate.toLocaleDateString()
                : moment(data.subscriberBirthDate, DATE_FORMAT).toISOString(),
        eligibilityRequestId: eligibilityData?.eligibilityRequestId,
        ...(data.appointmentMethod && { appointmentMethod: data.appointmentMethod }),
        ...(isAffiliateView && { Destination: DestinationNames.Affiliate }),
        ...(isDoulaView && { Destination: DestinationNames.HubspotDoula }),
        ...(isEligibilityRefresh && { Destination: DestinationNames.InsuranceRefresh }),
        ...(affiliate && {
            affiliateId: affiliate.id,
            selfCheck: affiliate.selfCheck,
        }),
        ...(isApta &&
            affiliate && { affiliateId: affiliate.id, aptaAffiliateId: affiliate.affiliateIdApta }),
        babyMultiples: data.babyMultiples,
        babyArrived: data.babyArrived,
        babySex: data.babySex,
        babyFirstName: data.babyFirstName?.trim(),
        babyLastName: data.babyLastName?.trim(),
        babyBirthDate:
            data.babyBirthDate instanceof Date
                ? data.babyBirthDate.toLocaleDateString()
                : moment(data.babyBirthDate, DATE_FORMAT).toISOString(),
        babyDueDate:
            data.babyDueDate instanceof Date
                ? data.babyDueDate.toLocaleDateString()
                : moment(data.babyDueDate, DATE_FORMAT).toISOString(),
        ...(insurancePlan && {
            insurancePlan: {
                name: insurancePlan.name,
                eligibilityStartDate: insurancePlan.eligibilityStartDate,
                eligibilityEndDate: insurancePlan.eligibilityEndDate,
                type: insurancePlan.type,
                doulaEligible: insurancePlan.doulaEligible,
                groupNumber: insurancePlan.groupNumber,
                subscriberState: insurancePlan.subscriberState,
            },
        }),
        type: isDoulaView ? VcfFormFlow.Doula : VcfFormFlow.Lactation,
        shouldSendBreastPumpEmail: data.shouldSendBreastPumpEmail,
        patientGroupNumber: eligibilityData?.eligibilityGroupNumber,
        ...(data.affiliateFilterZipCode &&
            data.appointmentMethod &&
            data.appointmentMethod === AppointmentMethodEnum.AtHome && {
                routingZipCode: data.affiliateFilterZipCode,
            }),
    };
};

export const isOlderThanTwoYears = (birthDate: Date | string) => {
    const birth = new Date(birthDate);
    const today = new Date();
    const ageInMilliseconds = today.getTime() - birth.getTime();
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

    return ageInYears > 2;
};

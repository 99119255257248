import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import Loading from '../../../../../components/Loading/Loading';

type ResendConfirmationModalProps = {
    isModalOpen: boolean;
    templateName: string | null;
    loading: boolean;
    handleClose: (isConfirmed: boolean) => void;
};

const ResendConfirmationModal: React.FC<ResendConfirmationModalProps> = ({
    isModalOpen,
    templateName,
    loading,
    handleClose,
}) => {
    return (
        <Dialog
            scroll="paper"
            onClose={(_, reason) => reason !== 'backdropClick' && handleClose(false)}
            open={isModalOpen}
            maxWidth="xs"
            aria-labelledby="form-dialog-title"
        >
            {loading ? (
                <Loading />
            ) : (
                <DialogContent>
                    <Grid container direction="column" gap={4}>
                        <Typography>
                            Are you sure you want to send{' '}
                            {templateName ? `"${templateName}" ` : 'this form '}
                            again?
                        </Typography>
                        <DialogActions sx={{ padding: 0, justifyContent: 'flex-start' }}>
                            <Button variant="contained" onClick={() => handleClose(true)}>
                                Send
                            </Button>
                            <Button variant="outlined" onClick={() => handleClose(false)}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </Grid>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default ResendConfirmationModal;

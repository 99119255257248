import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import Loading from '~/components/Loading/Loading';
import {
    AppointmentSource,
    FetchApptsForApptsPageQueryDocument,
    FetchApptsForApptsPageQueryQuery,
    FetchApptsForPatientApptsPageQueryDocument,
    FetchApptsForPatientApptsPageQueryQuery,
    FetchApptsForPatientApptsPageQueryQueryVariables,
    FetchCurrentUserForApptModalDocument,
    FetchPatientForPatientOverviewPageQuery,
    FetchPatientForPatientOverviewPageQueryVariables,
    OrderByDirectionEnum,
    useCreateNewApptFromApptModalMutation,
    useFetchApptByIdForApptModalLazyQuery,
    useFetchCurrentUserForApptModalQuery,
    useUpdateNewApptFromApptModalMutation,
} from '~/schemaTypes';
import { AppointmentEnum } from '~/selectors';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import DateEditor from '~/components/DateEditor/DateEditor';

const APPT_VALIDATION_SCHEMA = Yup.object().shape({
    externalId: Yup.string(),
    patientId: Yup.string().required('Required'),
    practiceId: Yup.string().required('Required'),
    durationMinutes: Yup.number().required('Required'),
    staffId: Yup.string(),
    staffName: Yup.string(),
    physicalLocation: Yup.string(),
    isVirtualVisit: Yup.boolean().required('Required'),
    virtualLink: Yup.string(),
    visitType: Yup.string(),
});

const useStyles = makeStyles()({
    root: {},
    inputField: {
        width: '100%',
        '& label': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '80%',
            overflow: 'hidden',
        },
    },
});

interface AppointmentFormInput {
    externalId: string;
    patientId: string;
    practiceId: string;
    durationMinutes: number;
    staffId?: string;
    staffName?: string;
    physicalLocation?: string;
    description?: string;
    isVirtualVisit?: boolean;
    virtualLink?: string;
    visitType?: string;
}

type AppointmentModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditAppointmentId: Dispatch<SetStateAction<string>>;
    id?: string;
    defaultValues?: {
        patientId?: string;
        patientName?: string | null;
    };
};

const AppointmentModal = ({
    setOpen,
    setEditAppointmentId,
    id,
    defaultValues,
}: AppointmentModalProps) => {
    const { classes } = useStyles();
    const {
        register,
        handleSubmit,
        control,
        reset,
        getValues,
        formState: { errors },
    } = useForm<AppointmentFormInput>({
        resolver: yupResolver(APPT_VALIDATION_SCHEMA as any),
    });
    const [apptStartDate, setApptStartDate] = useState<string>(new Date().toISOString());
    const [apptHour, setApptHour] = useState<number>(new Date().getHours());
    const [apptMin, setApptMin] = useState<number>(new Date().getMinutes());
    const [getAppt, { data: apptQuery, loading }] = useFetchApptByIdForApptModalLazyQuery({
        onCompleted: data => {
            if (data.appointment) {
                const {
                    externalId,
                    practice,
                    patient,
                    staff,
                    durationMinutes,
                    startDate,
                    physicalLocation,
                    virtualLink,
                    visitType,
                    isVirtualVisit,
                    description,
                } = data.appointment;
                reset({
                    externalId: externalId ?? '',
                    practiceId: practice?.id,
                    patientId: patient.id,
                    staffId: staff?.id,
                    ...(durationMinutes && { durationMinutes }),
                    ...(physicalLocation && { physicalLocation }),
                    ...(virtualLink && { virtualLink }),
                    ...(visitType && { visitType }),
                    ...(isVirtualVisit && { isVirtualVisit }),
                    ...(description && { description }),
                });
                const appDate = moment.tz(startDate, 'UTC').toDate();
                setApptStartDate(startDate);
                setApptHour(appDate.getHours());
                setApptMin(appDate.getMinutes());
            }
        },
        fetchPolicy: 'network-only',
    });

    const [updateAppt, { loading: updatingAppointment }] = useUpdateNewApptFromApptModalMutation({
        onCompleted: data => {
            if (data.updateAppointment?.success) {
                setOpen(false);
                setEditAppointmentId('');
            }
        },
    });

    const [createAppt, { loading: createApptLoading }] = useCreateNewApptFromApptModalMutation({
        onCompleted: data => {
            if (data.createAppointment?.success) {
                setOpen(false);
                setEditAppointmentId('');
            }
        },
        update: (cache, response) => {
            if (response.data?.createAppointment?.success) {
                const newAppt = response.data?.createAppointment?.resourceCreated;
                if (newAppt) {
                    const currentData = cache.readQuery<FetchApptsForApptsPageQueryQuery>({
                        query: FetchApptsForApptsPageQueryDocument,
                        variables: { input: {} },
                    });
                    if (currentData?.currentUser) {
                        cache.writeQuery<FetchApptsForApptsPageQueryQuery>({
                            query: FetchApptsForApptsPageQueryDocument,
                            data: {
                                currentUser: {
                                    ...currentData.currentUser,
                                    appointmentsV2: {
                                        total: currentData.currentUser.appointmentsV2.total + 1,
                                        results: [
                                            newAppt,
                                            ...currentData.currentUser.appointmentsV2.results,
                                        ],
                                    },
                                },
                            },
                            variables: { input: {} },
                        });
                    }
                    if (newAppt.patient.id) {
                        const currentPatientApptData = cache.readQuery<
                            FetchApptsForPatientApptsPageQueryQuery,
                            FetchApptsForPatientApptsPageQueryQueryVariables
                        >({
                            variables: {
                                patientInput: { id: newAppt.patient.id },
                                appointmentInput: {
                                    filter: {
                                        fields: { source: AppointmentSource.Portal },
                                    },
                                },
                            },
                            query: FetchApptsForPatientApptsPageQueryDocument,
                        });
                        if (currentPatientApptData?.patient) {
                            cache.writeQuery<
                                FetchApptsForPatientApptsPageQueryQuery,
                                FetchApptsForPatientApptsPageQueryQueryVariables
                            >({
                                variables: {
                                    patientInput: { id: newAppt.patient.id },
                                    appointmentInput: {
                                        filter: {
                                            fields: { source: AppointmentSource.Portal },
                                        },
                                    },
                                },
                                query: FetchApptsForPatientApptsPageQueryDocument,
                                data: {
                                    patient: {
                                        ...currentPatientApptData.patient,
                                        appointments: [
                                            newAppt,
                                            ...(currentPatientApptData.patient.appointments ?? []),
                                        ],
                                    },
                                },
                            });
                        }
                        const patientOverviewApptData = cache.readQuery<
                            FetchPatientForPatientOverviewPageQuery,
                            FetchPatientForPatientOverviewPageQueryVariables
                        >({
                            variables: {
                                patientInput: { id: newAppt.patient.id },
                                appointmentInput: {
                                    orderBy: {
                                        field: 'startDate',
                                        order: OrderByDirectionEnum.Desc,
                                    },
                                },
                            },
                            query: FetchCurrentUserForApptModalDocument,
                        });
                        if (patientOverviewApptData?.patient) {
                            cache.writeQuery<
                                FetchPatientForPatientOverviewPageQuery,
                                FetchPatientForPatientOverviewPageQueryVariables
                            >({
                                variables: {
                                    patientInput: { id: newAppt.patient.id },
                                    appointmentInput: {
                                        orderBy: {
                                            field: 'startDate',
                                            order: OrderByDirectionEnum.Desc,
                                        },
                                    },
                                },
                                query: FetchCurrentUserForApptModalDocument,
                                data: {
                                    patient: {
                                        ...patientOverviewApptData.patient,
                                        appointments: [
                                            newAppt,
                                            ...(patientOverviewApptData.patient.appointments ?? []),
                                        ],
                                    },
                                },
                            });
                        }
                    }
                }
            }
        },
        refetchQueries: 'active',
        optimisticResponse: {
            createAppointment: {
                __typename: 'CreateAppointmentResponse',
                success: true,
                message: '',
                resourceCreated: {
                    __typename: 'Appointment',
                    id: 'temp',
                    startDate: apptStartDate,
                    timezoneName: apptQuery?.appointment?.timezoneName,
                    patient: {
                        __typename: 'Patient',
                        id: getValues('patientId'),
                        firstName: apptQuery?.appointment?.patient.firstName ?? '',
                        lastName: apptQuery?.appointment?.patient.lastName ?? '',
                        fullName: apptQuery?.appointment?.patient.fullName ?? '',
                        birthDate: apptQuery?.appointment?.patient.birthDate ?? '',
                    },
                    source: AppointmentSource.Portal,
                    ...defaultValues,
                },
            },
        },
    });

    const { data: currentUserData, loading: currentUserLoading } =
        useFetchCurrentUserForApptModalQuery();

    const onSubmit = (appointments: AppointmentFormInput) => {
        const startDate = new Date(apptStartDate || '');
        startDate.setHours(apptHour);
        startDate.setMinutes(apptMin);
        if (id) {
            updateAppt({
                variables: {
                    input: {
                        id,
                        data: {
                            ...appointments,
                            startDate: startDate.toISOString(),
                            source: AppointmentSource.Portal,
                            timezoneName:
                                apptQuery?.appointment?.timezoneName ??
                                Intl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                    },
                },
            });
        } else if (apptStartDate) {
            createAppt({
                variables: {
                    input: {
                        ...appointments,
                        startDate: startDate.toISOString(),
                        source: AppointmentSource.Portal,
                        timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditAppointmentId('');
    };

    const handleHourChange = (hour: string) => {
        const newHour = Number(hour);
        setApptHour(newHour);
    };
    const handleMinChange = (min: string) => {
        const newMin = Number(min);
        setApptMin(newMin);
    };

    useEffect(() => {
        if (id) {
            getAppt({
                variables: {
                    input: {
                        id,
                    },
                },
            });
        }
    }, [id, getAppt]);

    if (loading || currentUserLoading) {
        return <Loading subtitle="Loading appointment details..." />;
    }

    if (createApptLoading) {
        return <Loading subtitle="Creating appointment..." />;
    }

    if (updatingAppointment) {
        return <Loading subtitle="Updating appointment..." />;
    }
    return (
        <form className={classes.root} noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle id="form-dialog-title">
                {apptQuery?.appointment?.id === undefined ? 'New Appointment' : 'Edit Appointment'}
                <div style={{ fontSize: '.8em' }}>{`Timezone: ${
                    apptQuery?.appointment?.timezoneName ??
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                }`}</div>
            </DialogTitle>
            <DialogContent className="formCont">
                <Grid container>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            {...register('patientId', {
                                required: true,
                                value:
                                    apptQuery?.appointment?.patient?.id ?? defaultValues?.patientId,
                            })}
                            render={({ field: { onChange } }) => (
                                <Autocomplete
                                    size="small"
                                    onChange={(_, org) => onChange(org?.value)}
                                    getOptionLabel={selected => selected.name ?? ''}
                                    fullWidth
                                    options={
                                        _.sortBy(
                                            currentUserData?.currentUser?.patients,
                                            'lastName',
                                        ).map(o => ({
                                            name: `${o.lastName}, ${o.firstName}`,
                                            value: o.id,
                                        })) || []
                                    }
                                    defaultValue={{
                                        name: apptQuery?.appointment?.patient
                                            ? `${apptQuery?.appointment?.patient?.lastName}, ${apptQuery?.appointment?.patient?.firstName}`
                                            : defaultValues?.patientName,
                                        value:
                                            apptQuery?.appointment?.patient?.id ??
                                            defaultValues?.patientId,
                                    }}
                                    isOptionEqualToValue={(option, val) => {
                                        return option.value === val.value;
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder="Select Patient"
                                            error={!!errors.patientId}
                                            helperText={errors.patientId?.message}
                                            label="For Patient"
                                            InputLabelProps={{ shrink: true }}
                                            data-test={AppointmentEnum.PATIENT_INPUT}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            {...register('practiceId', {
                                required: true,
                                value:
                                    apptQuery?.appointment?.practice?.id ??
                                    currentUserData?.currentUser?.currentOrg.id,
                            })}
                            render={({ field: { onChange } }) => (
                                <Autocomplete
                                    size="small"
                                    onChange={(_, org) => onChange(org?.value)}
                                    getOptionLabel={selected => selected.name ?? ''}
                                    fullWidth
                                    options={
                                        currentUserData?.currentUser?.currentOrganizations?.map(
                                            o => ({
                                                name: o.brandingName,
                                                value: o.id,
                                            }),
                                        ) || []
                                    }
                                    defaultValue={{
                                        name:
                                            apptQuery?.appointment?.practice?.name ??
                                            currentUserData?.currentUser?.currentOrg.brandingName,
                                        value:
                                            apptQuery?.appointment?.practice?.id ??
                                            currentUserData?.currentUser?.currentOrg.id,
                                    }}
                                    isOptionEqualToValue={(option, val) => {
                                        return option.value === val.value;
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder="Select your practice"
                                            error={!!errors.practiceId}
                                            helperText={errors.practiceId?.message}
                                            label="Top Level Practice"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <OutlinedSection title="Start Date & Time 24h">
                        <DateEditor
                            initialDate={apptStartDate}
                            setSelectedDate={setApptStartDate}
                            title="Date"
                        />
                        Hour:
                        <select
                            style={{ marginLeft: '5px', marginRight: '10px' }}
                            value={apptHour}
                            onChange={e => handleHourChange(e.target.value)}
                        >
                            {[...Array(24).keys()].map(h => (
                                <option value={h} key={h}>
                                    {h}
                                </option>
                            ))}
                        </select>
                        Min:
                        <select
                            style={{ marginLeft: '5px' }}
                            value={apptMin}
                            onChange={e => handleMinChange(e.target.value)}
                        >
                            {[...Array(60).keys()].map(m => (
                                <option value={m} key={m}>
                                    {m}
                                </option>
                            ))}
                        </select>
                    </OutlinedSection>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="Duration (minutes)"
                            type="number"
                            margin="dense"
                            fullWidth
                            {...register('durationMinutes', { required: true })}
                            error={!!errors.durationMinutes}
                            helperText={errors.durationMinutes?.message}
                            data-test={AppointmentEnum.DURATION_INPUT}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            variant="outlined"
                            label="External Id"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('externalId')}
                            error={!!errors.externalId}
                            helperText={errors.externalId?.message}
                            data-test={AppointmentEnum.EXTERNAL_ID_INPUT}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            {...register('staffId')}
                            defaultValue={apptQuery?.appointment?.staff?.id}
                            render={({ field: { onChange } }) => (
                                <Autocomplete
                                    size="small"
                                    onChange={(_, org) => onChange(org?.value)}
                                    getOptionLabel={selected => selected.name ?? ''}
                                    fullWidth
                                    options={
                                        _.sortBy(currentUserData?.currentUser?.users, 'name').map(
                                            o => ({
                                                name: o.name,
                                                value: o.id,
                                            }),
                                        ) || []
                                    }
                                    defaultValue={{
                                        name: apptQuery?.appointment?.staff?.name,
                                        value: apptQuery?.appointment?.staff?.id,
                                    }}
                                    isOptionEqualToValue={(option, val) => {
                                        return option.value === val.value;
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder="Select Staff"
                                            defaultValue={
                                                apptQuery?.appointment?.staff?.name || undefined
                                            }
                                            error={!!errors.staffId}
                                            helperText={errors.staffId?.message}
                                            label="For Staff"
                                            InputLabelProps={{ shrink: true }}
                                            data-test={AppointmentEnum.STAFF_INPUT}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Location (for In-Person Appointments)"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('physicalLocation')}
                            error={!!errors.physicalLocation}
                            helperText={errors.physicalLocation?.message}
                            data-test={AppointmentEnum.LOCATION_INPUT}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Description"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('description')}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                            data-test={AppointmentEnum.DESCRIPTION_INPUT}
                        />
                    </Grid>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexBasis: '40%',
                            paddingLeft: 20,
                        }}
                    >
                        <FormControlLabel
                            style={{ marginBottom: 0 }}
                            control={
                                <Switch
                                    {...register('isVirtualVisit')}
                                    defaultChecked={apptQuery?.appointment?.isVirtualVisit ?? false}
                                />
                            }
                            label="Is Virtual Visit"
                        />
                    </div>
                    <Grid item xs={7}>
                        <TextField
                            variant="outlined"
                            label="Link to Virtual Visit"
                            type="text"
                            margin="dense"
                            fullWidth
                            {...register('virtualLink')}
                            error={!!errors.virtualLink}
                            helperText={errors.virtualLink?.message}
                            data-test={AppointmentEnum.VIRTUAL_VISIT_INPUT}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-test={AppointmentEnum.SUBMIT_APPOINTMENT}
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default AppointmentModal;

import React from 'react';
import { Typography } from '@mui/material';
import type { TranscriptCardsProps } from '../types';
import { TranscriptCard } from './TranscriptCard';
import { LoadMoreButtons } from './LoadMoreButtons';

export const TranscriptCards = ({
    cards,
    cardTypes,
    handleLoadMore,
    queries,
    loading,
    loadingStates,
}: TranscriptCardsProps) => {
    const eventTypesSelected =
        cardTypes.TriggerFiredEvent ||
        cardTypes.TriggerInterventionClearProfileChoice ||
        cardTypes.TriggerInterventionClearProfileValue ||
        cardTypes.TriggerInterventionCreateAdvocateTask ||
        cardTypes.TriggerInterventionEndEpisode ||
        cardTypes.TriggerInterventionSendMessageCenter ||
        cardTypes.TriggerInterventionSetProfileValue ||
        cardTypes.TriggerInterventionStartEpisode ||
        cardTypes.ExpressionEvaluated ||
        cardTypes.ResolvedTagEvent;

    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] h-full w-full p-8 rounded-md mb-6">
            {loading.trackerHistory && cardTypes.Tracker && (
                <Typography variant="body1">Loading tracker data...</Typography>
            )}
            {loading.profileValueHistory && cardTypes.ProfileValue && (
                <Typography variant="body1">Loading profile value data...</Typography>
            )}
            {loading.patientNotes && cardTypes.PatientNotes && (
                <Typography variant="body1">Loading patient notes...</Typography>
            )}
            {loading.advocateTasks && cardTypes.AdvocateTasks && (
                <Typography variant="body1">Loading advocate tasks...</Typography>
            )}
            {loading.surveyResponses && cardTypes.SurveyResponse && (
                <Typography variant="body1">Loading survey responses...</Typography>
            )}
            {loading.chatMessages && cardTypes.ChatMessages && (
                <Typography variant="body1">Loading chat messages...</Typography>
            )}
            {loading.sentMessages && cardTypes.SentComms && (
                <Typography variant="body1">Loading sent messages...</Typography>
            )}
            {loading.events && eventTypesSelected && (
                <Typography variant="body1">Loading event data...</Typography>
            )}

            <div className="flex flex-col gap-4">
                {cards.map(card => (
                    <TranscriptCard key={card?.id} card={card} />
                ))}

                <LoadMoreButtons
                    queries={queries}
                    cardTypes={cardTypes}
                    handleLoadMore={handleLoadMore}
                    loadingStates={loadingStates}
                />
            </div>
        </div>
    );
};

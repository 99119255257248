import React, { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import debounce from 'lodash/debounce';
import {
    FormControlLabel,
    Grid,
    Switch,
    FormControl,
    FormHelperText,
    OutlinedInput,
    InputLabel,
} from '@mui/material';
import { HourglassEmpty } from '@mui/icons-material';
import { useUpdatePracticeConfigOptionsForPracticeMutation } from '~/schemaTypes';
import { FormInput } from '../types';

type ClaimSubmissionProps = {
    orgId: string;
    defaultMessage?: string | null;
};

const ClaimSubmission: React.FC<ClaimSubmissionProps> = props => {
    const { orgId, defaultMessage } = props;
    const { getValues, setValue } = useFormContext<FormInput>();
    const [disableSubmission, setDisableSubmission] = useState(!!defaultMessage);
    const [customMessage, setCustomMessage] = useState(defaultMessage || '');
    const [updateConfigOptions, { loading: updateConfigLoading }] =
        useUpdatePracticeConfigOptionsForPracticeMutation();
    const storeMessage = useMemo(
        () =>
            debounce((message: string, id: string) => {
                updateConfigOptions({
                    variables: {
                        input: {
                            id,
                            data: {
                                waystarEligibilityConfig: {
                                    ...getValues(),
                                    claimSubmissionDeniedMessage: message,
                                },
                            },
                        },
                    },
                });
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    useEffect(() => {
        if (!disableSubmission && customMessage) {
            setValue('claimSubmissionDeniedMessage', null);
            setCustomMessage('');
            storeMessage.cancel();
            updateConfigOptions({
                variables: {
                    input: {
                        id: orgId,
                        data: {
                            waystarEligibilityConfig: {
                                ...getValues(),
                                claimSubmissionDeniedMessage: null,
                            },
                        },
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableSubmission]);
    useEffect(() => {
        if (disableSubmission && defaultMessage !== customMessage) {
            setValue('claimSubmissionDeniedMessage', customMessage);
            storeMessage(customMessage, orgId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customMessage]);
    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={disableSubmission}
                            onChange={(event, val) => {
                                setDisableSubmission(val);
                            }}
                        />
                    }
                    label="Turn off Claim Submission for all Affiliates"
                />
            </Grid>
            {disableSubmission && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="custom-message">Custom Message</InputLabel>
                        <OutlinedInput
                            fullWidth
                            multiline
                            rows={4}
                            id="custom-message"
                            value={customMessage}
                            onChange={evt => setCustomMessage(evt.target.value)}
                            label="Custom Message"
                        />
                        <FormHelperText error={!customMessage} sx={{ minHeight: 25 }}>
                            {!customMessage && 'Custom message is required'}
                            {updateConfigLoading && (
                                <>
                                    <HourglassEmpty sx={{ fontSize: 15 }} />
                                    Saving...
                                </>
                            )}
                        </FormHelperText>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    );
};

export default ClaimSubmission;

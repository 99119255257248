/* eslint-disable react/react-in-jsx-scope */
import { Typography } from '@mui/material';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import { GetTrackerHistoryForPatientTranscriptQuery } from '~/schemaTypes';
import {
    Symptom,
    SYMPTOMS,
} from '~/views/ConfigDashboard/ConfigExpressions/ConfigExpression/ConfigExpression';

type Tracker = NonNullable<
    GetTrackerHistoryForPatientTranscriptQuery['getTrackerHistoryForPatientTranscript']
>[0];

const GlucoseCard = ({ tracker }: { tracker: Tracker }) => {
    return (
        <div>
            <p className="m-0">Blood Glucose Tracker</p>
            <p className="m-0">
                Date Taken:{' '}
                {displayDateWithLocalTimeZoneAbbr({
                    isoDateStr: new Date(tracker?.takenDate).toISOString(),
                })}
            </p>
            <p className="m-0">
                Date Recorded:{' '}
                {displayDateWithLocalTimeZoneAbbr({
                    isoDateStr: new Date(tracker?.dateTime).toISOString(),
                })}
            </p>
            <p className="m-0">Entry Value: {tracker?.glucose}</p>
            <p className="m-0">
                Symptoms:{' '}
                {tracker?.activeSymptoms?.length != null && tracker?.activeSymptoms?.length >= 1
                    ? tracker?.activeSymptoms?.map(
                          (symptom, i) =>
                              `${SYMPTOMS.get(symptom as Symptom)}${
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  i !== tracker.activeSymptoms!.length - 1 ? ',' : ''
                              } `,
                      )
                    : 'N/A'}
            </p>
            <p className="m-0">Meal: {tracker?.meal || 'unknown'}</p>
            <p className="m-0">
                Meal Time:{' '}
                {displayDateWithLocalTimeZoneAbbr({
                    isoDateStr: new Date(tracker?.mealTime).toISOString(),
                })}
            </p>
            <p className="m-0">Meal Type: {tracker?.mealOption || 'unknown'}</p>
            <p className="m-0">Notes: {tracker?.notes}</p>
        </div>
    );
};

const BloodPressureCard = ({ tracker }: { tracker: Tracker }) => {
    return (
        <div>
            <p className="m-0">Blood Pressure Tracker</p>
            <p className="m-0">
                Date Taken:{' '}
                {displayDateWithLocalTimeZoneAbbr({
                    isoDateStr: new Date(tracker?.takenDate).toISOString(),
                })}
            </p>
            <p className="m-0">
                Date Recorded:{' '}
                {displayDateWithLocalTimeZoneAbbr({
                    isoDateStr: new Date(tracker?.dateTime).toISOString(),
                })}
            </p>
            <p className="m-0">
                Entry Value: {tracker?.systolicBP}/{tracker?.diastolicBP}
            </p>
            <p className="m-0">
                Symptoms:{' '}
                {tracker?.activeSymptoms?.length != null && tracker?.activeSymptoms?.length >= 1
                    ? tracker?.activeSymptoms?.map(
                          (symptom, i) =>
                              `${SYMPTOMS.get(symptom as Symptom)}${
                                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                  i !== tracker.activeSymptoms!.length - 1 ? ',' : ''
                              } `,
                      )
                    : 'N/A'}
            </p>
        </div>
    );
};

export const TrackerCard = ({ tracker }: { tracker: Tracker }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Tracker</Typography>
            {tracker?.type === 'BloodPressureTrackerRecord' && (
                <BloodPressureCard tracker={tracker} />
            )}
            {tracker?.type === 'GlucoseTrackerRecord' && <GlucoseCard tracker={tracker} />}
        </div>
    );
};

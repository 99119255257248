import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { SendOutlined as SendOutlinedIcon } from '@mui/icons-material';

type SentExternalFormModalProps = {
    isModalOpen: boolean;
    email: string | null;
    templateName: string | null;
    handleClose: () => void;
};

const SentExternalFormModal: React.FC<SentExternalFormModalProps> = ({
    isModalOpen,
    email,
    templateName,
    handleClose,
}) => {
    return (
        <Dialog
            scroll="paper"
            onClose={(_, reason) => reason !== 'backdropClick' && handleClose()}
            open={isModalOpen}
            maxWidth="xs"
            aria-labelledby="form-dialog-title"
        >
            <DialogContent>
                <Grid container direction="column" gap={4}>
                    <Grid container direction="column">
                        <Grid container gap={1}>
                            <Typography>{templateName ?? 'Form'} &ndash; SENT!</Typography>
                            <SendOutlinedIcon />
                        </Grid>
                        <Grid item>
                            <Typography>Emailed to: {email ?? 'patient email'}</Typography>
                        </Grid>
                    </Grid>
                    <DialogActions sx={{ padding: 0, justifyContent: 'flex-start' }}>
                        <Button variant="outlined" onClick={() => handleClose()}>
                            Close
                        </Button>
                    </DialogActions>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SentExternalFormModal;

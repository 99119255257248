import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    OrderByDirectionEnum,
    useAppConfigLazyQuery,
    useApplicationListLazyQuery,
} from '~/schemaTypes';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { toEnvName } from './AppBuilds';

export const AppConfigModal: React.FC<{
    isOpen: boolean;
    appBundleId?: string;
    onClose: () => void;
}> = props => {
    const { isOpen, onClose, appBundleId } = props;
    const [bundleId, setBundleId] = useState<string>('');
    const [selectedEnv, setSelectedEnv] = useState<string>('1');
    const [showResult, setShowResult] = useState<boolean>(false);
    const [getApplicationList, { data: appList, loading: appListLoading }] =
        useApplicationListLazyQuery({
            onCompleted: data => {
                setBundleId(data.applicationsV2.results[0].appBundleId);
            },
            variables: {
                input: {
                    filter: { fields: { isActive: true } },
                    orderBy: { field: 'appBundleId', order: OrderByDirectionEnum.Asc },
                },
            },
        });
    const [getConfig, { data: configBuilds, loading: configLoading }] = useAppConfigLazyQuery();
    const handleClose = () => {
        setShowResult(false);
        onClose();
    };
    const handleShow = () => {
        if (bundleId) {
            setShowResult(true);
            getConfig({
                variables: {
                    input: {
                        appBundleId: bundleId,
                        env: Number(selectedEnv),
                        refreshIndex: new Date().getTime(),
                    },
                },
            });
        }
    };
    useEffect(() => {
        if (appBundleId) {
            setBundleId(appBundleId);
        } else {
            getApplicationList();
        }
    }, [appBundleId, getApplicationList, isOpen]);
    if (appListLoading || configLoading) {
        return <Loading />;
    }
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                View App Config {appBundleId && `for ${appBundleId}`}
            </DialogTitleWithClose>
            <DialogContent>
                <div>
                    <Grid item xs={12}>
                        {!appBundleId && (
                            <OutlinedSection title="Select App">
                                <select
                                    value={bundleId || ''}
                                    onChange={event => setBundleId(event.target.value)}
                                >
                                    {appList?.applicationsV2.results.map(a => (
                                        <option key={a.appBundleId} value={a.appBundleId}>
                                            {a.appBundleId}
                                        </option>
                                    ))}
                                </select>
                            </OutlinedSection>
                        )}
                        <OutlinedSection title="Select Environment">
                            <select
                                value={selectedEnv}
                                onChange={event => setSelectedEnv(event.target.value)}
                            >
                                <option key={1} value={1}>
                                    Staging
                                </option>
                                <option key={2} value={2}>
                                    IAT
                                </option>
                                <option key={3} value={3}>
                                    UAT
                                </option>
                                <option key={4} value={4}>
                                    Store Review
                                </option>
                                <option key={5} value={5}>
                                    Production
                                </option>
                            </select>
                        </OutlinedSection>
                    </Grid>
                </div>
                {showResult &&
                    configBuilds?.appBuildConfig?.length !== 0 &&
                    configBuilds?.appBuildConfig?.map(b => (
                        <div
                            style={{
                                padding: '10px',
                                border: '1px solid #000',
                                borderRadius: '15px',
                                marginBottom: '5px',
                            }}
                        >
                            App Version: {b?.appVersion}
                            <br />
                            Min. Platform Level: {b?.minPlatformLevel}
                            <br />
                            Log Version: {b?.logVersion}
                            <br />
                            Env: {toEnvName(b?.currentEnv || 1)}
                        </div>
                    ))}
                {showResult && configBuilds?.appBuildConfig?.length === 0 && (
                    <div>No Build Data available</div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Close
                </Button>
                <Button
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={handleShow}
                >
                    Show Config
                </Button>
            </DialogActions>
        </Dialog>
    );
};

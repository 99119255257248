import React, { useEffect, useState } from 'react';
import {
    useAppBuildListLazyQuery,
    useFetchApplicationLazyQuery,
    useFetchAppReleaseLazyQuery,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import { COLOR_ENV_IAT, COLOR_ENV_STAGE, COLOR_ENV_UAT } from '~/components/EnvLabel/EnvLabel';
import { useUserPermissions } from '~/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useStyles } from './styles';
import { AppBuildModal } from './AppBuildModal';
import { AppBuildDisplay } from './AppBuildDisplay';
import { AppPromoteModal } from './AppPromoteModal';
import { AppConfigModal } from './AppConfigModal';

export const toEnvName = (env: number): string => {
    switch (env) {
        case 2:
            return 'IAT';
        case 3:
            return 'UAT';
        case 4:
            return 'Store Review';
        case 5:
            return 'Production';
        default:
            break;
    }
    return 'Staging';
};

const AppBuilds: React.FC = () => {
    const { classes } = useStyles();
    const [appReleaseId, setAppReleaseId] = useState<string | undefined>();
    const [appId, setAppId] = useState<string | undefined>();
    const [appBundleId, setAppBundleId] = useState<string | undefined>();
    const [refreshIndex, setRefreshIndex] = useState<number>(1);
    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        if (window.location.href.indexOf('release') >= 0) {
            setAppReleaseId(id);
        } else {
            setAppId(id);
        }
    }, [id]);
    const [fetchAppRelease, { data: releaseData, loading: releaseLoading }] =
        useFetchAppReleaseLazyQuery();
    const [fetchApp, { data: appData, loading: appLoading }] = useFetchApplicationLazyQuery({
        onCompleted(data) {
            if (data) {
                setAppBundleId(data.application?.appBundleId);
            }
        },
    });
    useEffect(() => {
        if (appReleaseId) {
            fetchAppRelease({ variables: { input: { id: appReleaseId } } });
        }
        if (appId) {
            fetchApp({ variables: { input: { id: appId } } });
        }
    }, [fetchAppRelease, appReleaseId, appId, fetchApp]);
    const [getBuildList, { data, loading }] = useAppBuildListLazyQuery();
    useEffect(() => {
        getBuildList({
            variables: {
                input: { appBundleId, appReleaseId, refreshIndex },
            },
        });
    }, [appBundleId, appReleaseId, getBuildList, refreshIndex]);
    const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const [isConfigModalOpen, setConfigModalOpen] = useState<boolean>(false);
    const [isPromoteOpen, setPromoteOpen] = useState<boolean>(false);
    const [selectedBuildId, setSelectedBuildId] = useState('');
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const onNavigateAway = () => {
        if (appReleaseId) {
            history(`/app-config/appReleases`);
        }
        if (appId) {
            history(`/app-config/apps`);
        }
    };
    const promoteHandler = (id: string) => {
        if (id) {
            setSelectedBuildId(id);
            setPromoteOpen(true);
        }
    };
    if (loading || appLoading || releaseLoading) {
        return <Loading />;
    }
    return (
        <div style={{ fontSize: '.8em' }}>
            <div className={classes.root}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to App {appReleaseId && 'Release'} List
                </Button>
                <h2>
                    Build List for{' '}
                    {appReleaseId
                        ? releaseData?.appRelease?.name
                        : appData?.application?.appBundleId}
                </h2>
                <div>
                    <Button
                        startIcon={<FontAwesomeIcon icon={faRefresh} />}
                        style={{ marginLeft: '10px' }}
                        color="primary"
                        variant="outlined"
                        onClick={() => setRefreshIndex(new Date().getTime())}
                    >
                        Refresh
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
                        style={{ marginLeft: '10px' }}
                        onClick={() => setConfigModalOpen(true)}
                    >
                        View Config
                    </Button>
                    {pagePermissions?.AppBuilds.Edit && (
                        <Button
                            startIcon={<Add />}
                            color="secondary"
                            variant="contained"
                            onClick={() => setCreateModalOpen(true)}
                        >
                            Create Build
                        </Button>
                    )}
                </div>
                <hr />
            </div>
            <div>
                {data?.appBuildList.results.length !== 0 && (
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <th
                                    style={{
                                        width: '20%',
                                        borderRight: '1px solid #000',
                                        borderLeft: '1px solid #000',
                                    }}
                                >
                                    App/Build
                                </th>
                                <th
                                    style={{
                                        width: '16%',
                                        color: COLOR_ENV_STAGE,
                                        borderRight: '1px solid #000',
                                    }}
                                >
                                    Staging
                                </th>
                                <th
                                    style={{
                                        width: '16%',
                                        color: COLOR_ENV_IAT,
                                        borderRight: '1px solid #000',
                                    }}
                                >
                                    IAT
                                </th>
                                <th
                                    style={{
                                        width: '16%',
                                        color: COLOR_ENV_UAT,
                                        borderRight: '1px solid #000',
                                    }}
                                >
                                    UAT
                                </th>
                                <th
                                    style={{
                                        width: '16%',
                                        color: '#ff0000',
                                        borderRight: '1px solid #000',
                                    }}
                                >
                                    Store Review
                                </th>
                                <th style={{ width: '16%', borderRight: '1px solid #000' }}>
                                    Production
                                </th>
                            </tr>
                            <tr>
                                <td colSpan={6}>
                                    <hr />
                                </td>
                            </tr>
                            {data?.appBuildList.results.map(b => (
                                <AppBuildDisplay
                                    key={b.id}
                                    id={b.id}
                                    currentEnv={b.currentEnv}
                                    title={
                                        (appReleaseId !== undefined
                                            ? b.appBundleId
                                            : b.appRelease?.name) || ''
                                    }
                                    minPlatformLevel={b.minPlatformLevel}
                                    appVersion={b.appVersion}
                                    logVersion={b.logVersion}
                                    notes={b.notes}
                                    storeReview={b.storeReviewRequired}
                                    backVersion={b.backVersion || false}
                                    canPromote={b.canPromote || false}
                                    history={b.promotionHistory.map(h => {
                                        return {
                                            env: h.env,
                                            promotedBy: h.promotedBy,
                                            notes: h.notes,
                                            promotionDate: h.promotionDate,
                                        };
                                    })}
                                    promoteHandler={promoteHandler}
                                />
                            ))}
                        </tbody>
                    </table>
                )}
                {data?.appBuildList.results.length === 0 && <div>No Builds added</div>}
            </div>
            <AppBuildModal
                isOpen={isCreateModalOpen}
                appReleaseId={appReleaseId || ''}
                appBundleId={appBundleId || ''}
                onClose={() => {
                    setCreateModalOpen(false);
                }}
                setRefreshIndex={setRefreshIndex}
            />
            <AppConfigModal
                isOpen={isConfigModalOpen}
                onClose={() => setConfigModalOpen(false)}
                appBundleId={appBundleId}
            />
            {selectedBuildId && (
                <AppPromoteModal
                    isOpen={isPromoteOpen}
                    appReleaseId={appReleaseId || ''}
                    appBundleId={appBundleId || ''}
                    appBuildId={selectedBuildId}
                    onClose={() => setPromoteOpen(false)}
                    setRefreshIndex={setRefreshIndex}
                />
            )}
        </div>
    );
};

export default AppBuilds;

import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import copy from 'copy-to-clipboard';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import { InfoOutlined } from '@mui/icons-material';

type CopyProps = {
    id: string;
};

const Copy: React.FC<CopyProps> = props => {
    const { id } = props;
    return (
        <Tooltip title={id}>
            <IconButton
                onClick={() => {
                    copy(`${id}`);
                    TriggerGlobalAlert({
                        message: 'Id copied to clipboard',
                        severity: AlertSeverity.Success,
                    });
                }}
                size="large"
            >
                <InfoOutlined />
            </IconButton>
        </Tooltip>
    );
};

export default Copy;

export const SUPPORT_EMAIL = 'Lactationsupport@wildflowerhealth.com';
export const DOULA_SUPPORT_EMAIL = 'doulasupport@wildflowerhealth.com';
export const WF_LOGO_PATH = '/logo-with-title.png';
export const DOULA_LOGO_PATH = '/care-connect-logo.png';
const DOULA_SUPPORT_LABEL =
    ' We currently support Doula services for a limited number of Cigna insured employers. Please check with your employer to confirm benefits.';
const ELIGIBILITY_DENIED_MSG = (
    email: string = SUPPORT_EMAIL.toLowerCase(),
    isDoulaSupport = false,
) =>
    `Based on the Primary Insurance information provided, you are not eligible for these services. Please verify that your Primary Insurance Information supplied is correct and then try again.${
        isDoulaSupport ? DOULA_SUPPORT_LABEL : ''
    } If you believe that you should be eligible, please contact our support at: ${email}`;
export const DOULA_ELIGIBILITY_DENIED_MSG = ELIGIBILITY_DENIED_MSG(DOULA_SUPPORT_EMAIL, true);
export const GENERAL_ELIGIBILITY_DENIED_MSG = ELIGIBILITY_DENIED_MSG();

import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
} from '@mui/material';
import {
    EligibilityCheckResultsFilters,
    EligibilityCheckResultsFiltersState,
} from '~/views/Dashboard/EligibilityCheckResults/state';
import { Controller, useForm } from 'react-hook-form';
import UnitIdsAutocomplete from './UnitIdsAutocomplete/UnitIdsAutocomplete';

const initialState: EligibilityCheckResultsFilters = {
    appBundleId: '',
    unitIds: [],
    isEligible: false,
    isSuccess: false,
};

const EligibilityCheckResultsFilterModal = ({
    onClose,
    isOpen,
}: {
    onClose: () => void;
    isOpen: boolean;
}) => {
    const { register, control, getValues, setValue, reset } =
        useForm<EligibilityCheckResultsFilters>({
            defaultValues: {
                ...initialState,
            },
        });

    const [filters, setFilters] = useAtom(EligibilityCheckResultsFiltersState);

    useEffect(() => {
        if (isOpen) {
            setValue('appBundleId', filters.appBundleId || initialState.appBundleId);
            setValue('unitIds', filters.unitIds || initialState.unitIds);
            setValue('isSuccess', filters.isSuccess || initialState.isSuccess);
            setValue('isEligible', filters.isEligible || initialState.isEligible);
        }
    }, [isOpen, filters, setValue]);

    const handleClearFiltersClick = () => {
        reset();
    };

    const handleCloseClick = () => {
        onClose();
    };

    const handleSaveClick = () => {
        const values = getValues();

        setFilters({
            appBundleId: values.appBundleId || undefined,
            unitIds: values.unitIds?.length ? values.unitIds : undefined,
            isSuccess: getValues('isSuccess') || undefined,
            isEligible: getValues('isEligible') || undefined,
        });

        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseClick} fullWidth>
            <DialogTitle>Filter Eligibility Check Results</DialogTitle>
            <DialogContent>
                <TextField
                    label="Filter App Bundle Id"
                    variant="outlined"
                    fullWidth
                    type="text"
                    {...register('appBundleId')}
                />
                <UnitIdsAutocomplete control={control} />
                <Controller
                    name="isSuccess"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value ?? false}
                                    onChange={e => onChange(e.target.checked)}
                                />
                            }
                            label="Is Success"
                        />
                    )}
                />
                <Controller
                    name="isEligible"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value ?? false}
                                    onChange={e => onChange(e.target.checked)}
                                />
                            }
                            label="Is Eligible"
                        />
                    )}
                />
                <div>
                    <Button onClick={handleClearFiltersClick}>Clear Filters</Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} color="primary">
                    Close
                </Button>
                <Button onClick={handleSaveClick} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EligibilityCheckResultsFilterModal;

import React, { useCallback, useRef, useState } from 'react';
import { Dialog, Grid, Typography } from '@mui/material';
import MaterialTable, { Action } from '@material-table/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
    AlertSeverity,
    useDeleteVcExternalFormTemplateMutation,
    useVcExternalFormTemplatesForVcExternalFormTemplatesPageQuery,
    VcExternalFormTemplate,
} from '../../../schemaTypes';
import { useUserPermissions } from '../../../hooks';
import { VCExternalFormTemplateEditModal } from './VCExternalFormTemplateEditModal';
import { SuppressNextGlobalAlert, TriggerGlobalAlert, TriggerGlobalConfirm } from '../../../state';

type ModalData = {
    isOpen: boolean;
    id?: string;
};

const VcExternalFormTemplates: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const { pagePermissions } = useUserPermissions();
    const { data: formTemplates, loading: formTemplatesLoading } =
        useVcExternalFormTemplatesForVcExternalFormTemplatesPageQuery();

    const [deleteVCExternalFormTemplate] = useDeleteVcExternalFormTemplateMutation({
        onCompleted: data => {
            if (!data.deleteVCExternalFormTemplate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: data.deleteVCExternalFormTemplate?.message ?? 'Unknown Error Occurred',
                });
                return;
            }
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'Entry updated successfully',
            });
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
        refetchQueries: ['VCExternalFormTemplatesForVCExternalFormTemplatesPage'],
    });

    const [editModalData, setEditModalData] = useState<ModalData>({ isOpen: false });

    const deleteItem = useCallback<Action<VcExternalFormTemplate>['onClick']>(
        (_, { id }: VcExternalFormTemplate) => {
            TriggerGlobalConfirm({
                message: `Do you want to delete VC Form Template ${id}?`,
                callback: () => {
                    SuppressNextGlobalAlert(true);
                    deleteVCExternalFormTemplate({
                        variables: {
                            input: {
                                id,
                            },
                        },
                    });
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<VcExternalFormTemplate>
                    title="VC External Form Templates"
                    actions={[
                        {
                            icon: () => <FontAwesomeIcon icon={faPlus} />,
                            isFreeAction: true,
                            onClick: () => setEditModalData({ isOpen: true }),
                            tooltip: 'Create New Entry',
                            hidden: !pagePermissions?.VcExternalFormTemplates.Edit,
                        },
                        {
                            onClick: (_, { id }: VcExternalFormTemplate) =>
                                setEditModalData({ isOpen: true, id }),
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit',
                            hidden: !pagePermissions?.VcExternalFormTemplates.Edit,
                        },
                        {
                            onClick: deleteItem,
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            tooltip: 'Delete',
                            hidden: !pagePermissions?.VcExternalFormTemplates.Delete,
                        },
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                            render: ({ name }) => <Typography data-test={name}>{name}</Typography>,
                            sorting: false,
                        },
                        {
                            title: 'Template Id',
                            field: 'formTemplateId',
                            render: ({ formTemplateId }) => (
                                <Typography data-test={formTemplateId}>{formTemplateId}</Typography>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={
                        formTemplates?.vCExternalFormTemplatesV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paging: false,
                    }}
                    localization={{ header: { actions: '' } }}
                    isLoading={formTemplatesLoading}
                />
            </Grid>
            {editModalData.isOpen && (
                <Dialog open={editModalData.isOpen} scroll="paper">
                    <VCExternalFormTemplateEditModal
                        id={editModalData.id}
                        onClose={() => {
                            setEditModalData({ isOpen: false });
                        }}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default VcExternalFormTemplates;

import { faList, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AppReleaseListDocument,
    AppReleaseListQuery,
    useAppReleaseListQuery,
    useDeleteAppReleaseMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import Loading from '~/components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { AppReleaseModal } from './AppReleaseModal';
import { useStyles } from './styles';

type AppRelease = NonNullable<AppReleaseListQuery['appReleasesV2']['results'][0]>;

const AppReleases: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useAppReleaseListQuery();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<AppRelease | null>(null);
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteAppReleaseMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: AppReleaseListDocument,
                variables: {},
            },
        ],
    });
    const handleDelete = (item: AppRelease) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${item?.name}'?`,
            callback: () => {
                deleteItem({ variables: { input: { id: item?.id } } });
            },
        });
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    if (loading || deleteItemLoading) {
        return <Loading />;
    }
    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<AppRelease>
                    title="App Releases"
                    icons={tableIcons}
                    isLoading={loading || deleteItemLoading}
                    data={_.cloneDeep(data?.appReleasesV2.results) ?? []}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            onClick: () => {
                                setSelectedItem(null);
                                setModalOpen(true);
                            },
                            icon: () => <Add />,
                            tooltip: 'Add App Release',
                            isFreeAction: true,
                            hidden: !pagePermissions?.AppBuilds.Edit,
                        },
                        {
                            onClick: (_, item) => {
                                const isSingleItemSelected = !Array.isArray(item);
                                if (isSingleItemSelected) {
                                    setModalOpen(true);
                                    setSelectedItem(item);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit App Release',
                            hidden: !pagePermissions?.AppBuilds.Edit,
                        },
                        {
                            onClick: (_, { id }: any) =>
                                history(`/app-config/appBuilds/release/${id}`),
                            hidden: !pagePermissions?.AppBuilds.Read,
                            icon: () => <FontAwesomeIcon icon={faList} />,
                            tooltip: 'App Builds',
                        },
                        rowData => ({
                            icon: () => <FontAwesomeIcon icon={faTrash} />,
                            onClick: (_e, item) => {
                                handleDelete(item as AppRelease);
                            },
                            tooltip: 'Delete App Release',
                            disabled: rowData.buildCount !== 0,
                            hidden: !pagePermissions?.AppBuilds.Delete,
                        }),
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'Added By',
                            field: 'addedBy',
                        },
                        {
                            title: 'Included Builds',
                            field: 'buildCount',
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <AppReleaseModal
                isOpen={isModalOpen}
                item={selectedItem || null}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedItem(null);
                }}
            />
        </>
    );
};

export default AppReleases;

import { faPenToSquare, faEye, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import { useApplicationListQuery, OrderByDirectionEnum } from '~/schemaTypes';

const useStyles = makeStyles()({
    root: {},
});
const Applications: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const { data: listData, loading: dataLoading } = useApplicationListQuery({
        variables: {
            input: {
                orderBy: {
                    field: 'fullName',
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
    });
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Applications"
                icons={tableIcons}
                isLoading={dataLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(listData?.applicationsV2.results) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/apps/new'),
                        hidden: !pagePermissions?.Apps.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Application',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/apps/${id}`),
                        hidden: !pagePermissions?.Apps.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Application',
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/appBuilds/app/${id}`),
                        hidden: !pagePermissions?.AppBuilds.Read,
                        icon: () => <FontAwesomeIcon icon={faList} />,
                        tooltip: 'App Builds',
                    },

                    {
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        hidden: !pagePermissions?.Apps.Edit,
                        tooltip: 'View App Details',
                        onClick: (_, { id }: any) => history(`/app-config/summary/${id}`),
                    },
                ]}
                columns={[
                    { title: 'Name', field: 'fullName' },
                    { title: 'App Bundle Id', field: 'appBundleId' },
                    { title: 'Active', field: 'isActive' },
                    { title: 'Maint. Mode', field: 'isMaintenance' },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
            />
        </div>
    );
};

export default Applications;

import React from 'react';
import {
    Card,
    Fade,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
} from '@mui/material';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import {
    OrganizationWaystarEligibilityConfig,
    useUpdatePracticeConfigOptionsForPracticeMutation,
    WaystarEligibilityStatus,
} from '~/schemaTypes';
import ClaimSubmission from './components/ClaimSubmission';
import { FormInput } from './types';

type OrgWaystarEligibilityConfigOptionsProps = {
    orgId: string;
    configOptions: OrganizationWaystarEligibilityConfig | null;
};

const OrgWaystarEligibilityConfigOptions: React.FC<OrgWaystarEligibilityConfigOptionsProps> = ({
    orgId,
    configOptions,
}) => {
    const methods = useForm<FormInput>({
        defaultValues: {
            status: configOptions?.status ?? WaystarEligibilityStatus.EnvironmentDefault,
            allowNoInsuranceClaims: configOptions?.allowNoInsuranceClaims ?? false,
            claimSubmissionDeniedMessage: configOptions?.claimSubmissionDeniedMessage,
        },
    });
    const { handleSubmit, control } = methods;
    const [updateConfigOptions] = useUpdatePracticeConfigOptionsForPracticeMutation();

    const onSubmit = (waystarEligibilityConfig: OrganizationWaystarEligibilityConfig) => {
        updateConfigOptions({
            variables: {
                input: {
                    id: orgId,
                    data: {
                        waystarEligibilityConfig,
                    },
                },
            },
        });
    };

    return (
        <FormProvider {...methods}>
            <Fade in timeout={500}>
                <Card elevation={0}>
                    <Typography variant="h5" paragraph>
                        Waystar Eligibility
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Set Status</InputLabel>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <Select
                                            variant="outlined"
                                            value={
                                                configOptions?.status ??
                                                WaystarEligibilityStatus.EnvironmentDefault
                                            }
                                            style={{ margin: '5px' }}
                                            onChange={event => {
                                                onChange(event);
                                                handleSubmit(onSubmit)();
                                            }}
                                        >
                                            <MenuItem
                                                value={WaystarEligibilityStatus.EnvironmentDefault}
                                            >
                                                Environment Default
                                            </MenuItem>
                                            <MenuItem value={WaystarEligibilityStatus.Enabled}>
                                                Override to Enabled
                                            </MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="allowNoInsuranceClaims"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <FormControlLabel
                                        label="Allow Claims Submissions with No Insurance Plan"
                                        control={
                                            <Switch
                                                checked={
                                                    configOptions?.allowNoInsuranceClaims ?? false
                                                }
                                                onChange={(event, val) => {
                                                    onChange(val);
                                                    handleSubmit(onSubmit)();
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ClaimSubmission
                                orgId={orgId}
                                defaultMessage={configOptions?.claimSubmissionDeniedMessage}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Fade>
        </FormProvider>
    );
};

export default OrgWaystarEligibilityConfigOptions;

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    AppBuildListDocument,
    AppBuildListQuery,
    useFetchAppBuildQuery,
    usePromoteAppBuildMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { useUser } from '~/hooks';
import { toEnvName } from './AppBuilds';

export const AppPromoteModal: React.FC<{
    isOpen: boolean;
    appBuildId: string;
    appBundleId?: string;
    appReleaseId?: string;
    onClose: () => void;
    setRefreshIndex: React.Dispatch<React.SetStateAction<number>>;
}> = props => {
    const { isOpen, appBuildId, onClose, appBundleId, appReleaseId, setRefreshIndex } = props;
    const { data: userData } = useUser();
    const [newEnv, setNewEnv] = useState<number>(1);
    const [notes, setNotes] = useState('');
    const [isBackVersion, setIsBackVersion] = useState(false);
    const [okToPromote, setOkToPromote] = useState(true);
    const handleClose = () => {
        onClose();
    };
    const { data: appBuild, loading } = useFetchAppBuildQuery({
        variables: { input: { id: appBuildId || '' } },
    });
    useEffect(() => {
        if (appBuild) {
            setIsBackVersion(appBuild.appBuild?.backVersion || false);
            setOkToPromote(!appBuild.appBuild?.backVersion === true);
            switch (appBuild.appBuild?.currentEnv) {
                case 1:
                    setNewEnv(2);
                    break;
                case 2:
                    setNewEnv(3);
                    break;
                case 3:
                    if (appBuild.appBuild.storeReviewRequired) {
                        setNewEnv(4);
                    } else {
                        setNewEnv(5);
                    }
                    break;
                case 4:
                    setNewEnv(5);
                    break;
                default:
                    break;
            }
        }
    }, [appBuild]);
    const [promoteAppBuild, { loading: updateProcessing }] = usePromoteAppBuildMutation({
        onCompleted: () => {
            setRefreshIndex(new Date().getTime());
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem promoting the App Build: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const updatedItem = response.data?.promoteAppBuild?.appBuild;
            if (response.data?.promoteAppBuild?.success && updatedItem) {
                const currentItems = cache.readQuery<AppBuildListQuery>({
                    query: AppBuildListDocument,
                    variables: { input: { appReleaseId, appBundleId } },
                });
                if (currentItems?.appBuildList) {
                    cache.writeQuery<AppBuildListQuery>({
                        query: AppBuildListDocument,
                        data: {
                            appBuildList: {
                                results: [
                                    ...currentItems.appBuildList.results.filter(
                                        i => i.id !== updatedItem.id,
                                    ),
                                    updatedItem,
                                ],
                                total: currentItems.appBuildList.total,
                            },
                        },
                    });
                }
            }
        },
    });

    const onSubmit = () => {
        TriggerGlobalConfirm({
            message: `Do you really want to promote this build to ${toEnvName(newEnv)}?`,
            callback: () => {
                promoteAppBuild({
                    variables: {
                        input: {
                            appBuildId,
                            newEnv,
                            promotedBy: userData?.currentUser?.name || 'Unknown',
                            notes,
                        },
                    },
                });
            },
        });
    };
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                Promote App Build
            </DialogTitleWithClose>
            <DialogContent>
                {loading || updateProcessing ? (
                    <Loading height={50} />
                ) : (
                    <>
                        <div>
                            <strong>Promoting build to {toEnvName(newEnv)}.</strong>
                        </div>
                        {isBackVersion && (
                            <div>
                                <strong style={{ color: 'red' }}>
                                    WARNING: This build is a back version.
                                </strong>
                                <FormControlLabel
                                    label="Do you want to proceed and
                                    promote this build?"
                                    labelPlacement="start"
                                    control={
                                        <Checkbox
                                            id="okToPromote"
                                            name="okToPromote"
                                            checked={okToPromote}
                                            onChange={e => setOkToPromote(e.target.checked)}
                                        />
                                    }
                                />
                            </div>
                        )}
                        <div>
                            Click &apos;Promote&apos; to confirm promotion. This cannot be reversed.
                        </div>
                        <TextField
                            variant="outlined"
                            label="Promotion Notes (optional)"
                            multiline
                            rows={4}
                            type="text"
                            margin="dense"
                            fullWidth
                            onChange={e => {
                                setNotes(e.target.value);
                            }}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={!okToPromote}
                >
                    Promote
                </Button>
            </DialogActions>
        </Dialog>
    );
};

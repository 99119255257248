import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useVcExternalFormForFormPageQuery } from '~/schemaTypes';
import { format } from 'date-fns';
import { makeStyles } from 'tss-react/mui';
import Loading from '../../../../../../components/Loading/Loading';

const useStyles = makeStyles()(theme => ({
    table: {
        marginBottom: 50,
    },
    tableHeader: {
        color: theme.palette.primary.contrastText,
        fontSize: '1.1rem',
        border: `1px solid ${theme.palette.primary.dark}`,
        backgroundColor: theme.palette.primary.light,
        fontWeight: 'bold',
    },
    tableRow: {
        '&:nth-child(even)': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    tableCell: {
        border: `1px solid ${theme.palette.grey[300]}`,
    },
}));

type RouteParams = {
    formId: string;
};

const PatientVCExternalForm: React.FC = () => {
    const { formId } = useParams<RouteParams>();
    const { classes } = useStyles();

    const { data: formDataResponse, loading: formLoading } = useVcExternalFormForFormPageQuery({
        variables: {
            input: {
                id: formId,
            },
        },
    });

    const formData = formDataResponse?.vCExternalForm;

    if (formLoading) {
        return <Loading />;
    }

    return (
        <Grid container gap={2}>
            <Typography variant="h6">
                {formData?.template?.name ?? 'Form'} &ndash; Taken at{' '}
                {formData?.dateTaken && format(new Date(formData.dateTaken), 'MM/dd/yyyy h:mm a')}
            </Typography>
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} variant="head">
                                Question
                            </TableCell>
                            <TableCell className={classes.tableHeader} variant="head">
                                Answer
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {formData?.answers?.map(answer => {
                            if (!answer) {
                                return null;
                            }
                            const { question, answerValue } = answer;
                            return (
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.tableCell} width="50%">
                                        {question}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {answerValue}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default PatientVCExternalForm;

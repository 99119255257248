import { useMemo } from 'react';
import type { DateRangeFilter } from '../types';

export const useDateRangeFilter = (
    dateRange: string,
    customStartDate: Date | null,
    customEndDate: Date | null,
): DateRangeFilter => {
    return useMemo(() => {
        const range: { greaterThanDate?: Date; lessThanDate?: Date } = {};

        if (dateRange) {
            const now = new Date();
            switch (dateRange) {
                case 'lastDay':
                    range.greaterThanDate = new Date(new Date().setDate(now.getDate() - 1));
                    range.lessThanDate = now;
                    break;
                case 'last3Days':
                    range.greaterThanDate = new Date(new Date().setDate(now.getDate() - 3));
                    range.lessThanDate = now;
                    break;
                case 'last7Days':
                    range.greaterThanDate = new Date(new Date().setDate(now.getDate() - 7));
                    range.lessThanDate = now;
                    break;
                case 'last30Days':
                    range.greaterThanDate = new Date(new Date().setDate(now.getDate() - 30));
                    range.lessThanDate = now;
                    break;
                case 'custom':
                    range.greaterThanDate = customStartDate ? new Date(customStartDate) : undefined;
                    range.lessThanDate = customEndDate ? new Date(customEndDate) : undefined;
                    break;
                default:
                    break;
            }
        }

        return range;
    }, [dateRange, customStartDate, customEndDate]);
};

import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Email as EmailIcon } from '@mui/icons-material';
import SentExternalFormModal from '../../SentExternalFormModal/SentExternalFormModal';
import ResendConfirmationModal from './ResendConfirmationModal';
import { TriggerGlobalAlert } from '../../../../../state';
import { AlertSeverity, useResendVcExternalFormMutation } from '../../../../../schemaTypes';

type SentExternalFormModalProps = {
    templateName: string | null;
    formId: string;
};

const ResendFormButton: React.FC<SentExternalFormModalProps> = ({ templateName, formId }) => {
    const [isResendConfirmationModalOpen, setIsResendConfirmationModalOpen] = useState(false);
    const [isResendSuccessModalOpen, setIsResendSuccessModalOpen] = useState(false);

    const [resendForm, { data, loading }] = useResendVcExternalFormMutation({
        variables: {
            input: {
                formId,
            },
        },
        onCompleted: data => {
            if (data.resendVCExternalForm?.success) {
                setIsResendConfirmationModalOpen(false);
                setIsResendSuccessModalOpen(true);
                return;
            }
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Failed to resend the form',
            });
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
        refetchQueries: ['VCExternalFormDataOnSendFormEmailModal'],
    });

    return (
        <>
            <IconButton
                title="Send Again?"
                onClick={() => setIsResendConfirmationModalOpen(true)}
                sx={{ padding: 0 }}
            >
                <EmailIcon />
            </IconButton>
            <ResendConfirmationModal
                loading={loading}
                isModalOpen={isResendConfirmationModalOpen}
                templateName={templateName}
                handleClose={isConfirmed => {
                    if (isConfirmed) {
                        resendForm();
                    } else {
                        setIsResendConfirmationModalOpen(false);
                    }
                }}
            />
            <SentExternalFormModal
                isModalOpen={isResendSuccessModalOpen}
                templateName={templateName}
                email={data?.resendVCExternalForm?.receiverEmail ?? 'patient email'}
                handleClose={() => {
                    setIsResendSuccessModalOpen(false);
                }}
            />
        </>
    );
};

export default ResendFormButton;

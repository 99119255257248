import React from 'react';
import { FileUploadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { usePatientEstimatesSubmittedRunnerByIdMutation } from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

type ReUploadClaimProps = {
    estimateId: string;
    callback: () => void;
};

const ReUploadClaim: React.FC<ReUploadClaimProps> = props => {
    const { estimateId, callback } = props;
    const [sendClaimToUpload] = usePatientEstimatesSubmittedRunnerByIdMutation({
        onCompleted: callback,
    });
    const onClickHandler = React.useCallback(() => {
        TriggerGlobalConfirm({
            message: 'Are you sure you want to upload the claim to SFTP?',
            callback: () => {
                sendClaimToUpload({
                    variables: {
                        input: {
                            patientEstimateId: estimateId,
                        },
                    },
                });
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimateId]);
    return (
        <Tooltip title="Re-Upload">
            <IconButton aria-label="upload claim" onClick={onClickHandler}>
                <FileUploadOutlined />
            </IconButton>
        </Tooltip>
    );
};

export default ReUploadClaim;

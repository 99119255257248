import { Button, Stack, CircularProgress } from '@mui/material';
import React from 'react';
import type { CardTypes } from '../types';
import type { usePatientTranscriptQueries } from '../hooks/usePatientTranscriptQueries';

type LoadMoreButtonsProps = {
    queries: ReturnType<typeof usePatientTranscriptQueries>['queries'];
    cardTypes: CardTypes;
    handleLoadMore: (type: string) => Promise<void>;
    loadingStates: Record<string, boolean>;
};

const hasMoreData = (token?: string) => {
    if (!token) return false;
    try {
        const [, s3Token] = token.split('::');
        return Boolean(s3Token);
    } catch (error) {
        return false;
    }
};

export const LoadMoreButtons = ({
    queries,
    cardTypes,
    handleLoadMore,
    loadingStates,
}: LoadMoreButtonsProps) => {
    const renderLoadMoreButton = (
        token: string | undefined | null,
        cardType: boolean,
        actionType: string,
        buttonText: string,
    ) => {
        if (!hasMoreData(token || undefined) || !cardType) return null;

        const isLoading = loadingStates[actionType];

        return (
            <Button
                onClick={() => handleLoadMore(actionType)}
                variant="contained"
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
                {isLoading ? 'Loading...' : buttonText}
            </Button>
        );
    };

    return (
        <Stack spacing={2} direction="column" alignItems="stretch">
            {renderLoadMoreButton(
                queries.resolvedTagEvents?.getResolvedTagsForPatientTranscript?.nextToken,
                cardTypes.ResolvedTagEvent,
                'resolvedTags',
                'Load More Resolved Tags',
            )}

            {renderLoadMoreButton(
                queries.triggerInterventionStartEpisodes
                    ?.getTriggerInterventionStartEpisodesForPatientTranscript?.nextToken,
                cardTypes.TriggerInterventionStartEpisode,
                'interventionStartEpisode',
                'Load More Start Episodes',
            )}

            {renderLoadMoreButton(
                queries.triggerInterventionEndEpisodes
                    ?.getTriggerInterventionEndEpisodesForPatientTranscript?.nextToken,
                cardTypes.TriggerInterventionEndEpisode,
                'interventionEndEpisode',
                'Load More End Episodes',
            )}

            {renderLoadMoreButton(
                queries.expressionEvaluated?.getExpressionEvaluatedForPatientTranscript?.nextToken,
                cardTypes.ExpressionEvaluated,
                'expressionEvaluated',
                'Load More Expression Evaluated',
            )}

            {renderLoadMoreButton(
                queries.triggerInterventionSetProfileValue
                    ?.getTriggerInterventionSetProfileValueForPatientTranscript?.nextToken,
                cardTypes.TriggerInterventionSetProfileValue,
                'interventionSetProfileValue',
                'Load More Set Profile Value',
            )}

            {renderLoadMoreButton(
                queries.triggerInterventionSendMessageCenter
                    ?.getTriggerInterventionSendMessageCenterForPatientTranscript?.nextToken,
                cardTypes.TriggerInterventionSendMessageCenter,
                'interventionSendMessageCenter',
                'Load More Send Message Center',
            )}

            {renderLoadMoreButton(
                queries.triggerInterventionCreateAdvocateTask
                    ?.getTriggerInterventionCreateAdvocateTaskForPatientTranscript?.nextToken,
                cardTypes.TriggerInterventionCreateAdvocateTask,
                'interventionCreateAdvocateTask',
                'Load More Create Advocate Task',
            )}

            {renderLoadMoreButton(
                queries.triggerFiredEvents?.getTriggerFiredEventsForPatientTranscript?.nextToken,
                cardTypes.TriggerFiredEvent,
                'triggerFired',
                'Load More Trigger Fired Events',
            )}

            {renderLoadMoreButton(
                queries.triggerInterventionClearProfileValue
                    ?.getTriggerInterventionClearProfileValueForPatientTranscript?.nextToken,
                cardTypes.TriggerInterventionClearProfileValue,
                'interventionClearProfileValue',
                'Load More Clear Profile Value',
            )}

            {renderLoadMoreButton(
                queries.triggerInterventionClearProfileChoice
                    ?.getTriggerInterventionClearProfileChoiceForPatientTranscript?.nextToken,
                cardTypes.TriggerInterventionClearProfileChoice,
                'interventionClearProfileChoice',
                'Load More Clear Profile Choice',
            )}
        </Stack>
    );
};

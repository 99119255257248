/* eslint-disable jsx-a11y/control-has-associated-label */
// This component has a UI Test
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Accordion,
    Button,
    Card,
    CardActions,
    CardHeader,
    Collapse,
    Typography,
    Checkbox,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUser } from '~/hooks';
import {
    BatchTaskInput,
    BatchTaskStatus,
    BatchTaskType,
    FetchBatchTaskListDocument,
    FetchBatchTaskListQuery,
    useCreateBatchTaskMutation,
    useDeleteBatchTaskMutation,
    useFetchAppsTagsForBatchTasksQuery,
    useFetchBatchTaskListLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';

type BatchTask = NonNullable<FetchBatchTaskListQuery['batchTasks'][0]>;

const toStatusName = (status: BatchTaskStatus): string => {
    switch (status) {
        case BatchTaskStatus.Cancelled:
            return 'Cancelled';
        case BatchTaskStatus.Complete:
            return 'Complete';
        case BatchTaskStatus.Error:
            return 'Complete with errors';
        case BatchTaskStatus.NotStarted:
            return 'Not Started';
        case BatchTaskStatus.Running:
            return 'Running';
        default:
            return 'Unknown';
    }
};

const toTypeName = (type: BatchTaskType): string => {
    switch (type) {
        case BatchTaskType.ConfigCacheRebuild:
            return 'Config Cache Rebuild';
        case BatchTaskType.ContentCacheRebuild:
            return 'Content Cache Rebuild';
        case BatchTaskType.DeleteAppPatients:
            return 'Delete App Patients';
        case BatchTaskType.DirectoryCacheRebuild:
            return 'Directory Cache Rebuild';
        case BatchTaskType.EligibilityRecheck:
            return 'Eligibility Recheck';
        case BatchTaskType.InitializeApp:
            return 'Initialize App';
        case BatchTaskType.PatientCacheRebuild:
            return 'Patient Cache Rebuild';
        case BatchTaskType.RecalcTags:
            return 'Recalculate Tags';
        case BatchTaskType.TagCacheRebuild:
            return 'Tag Cache Rebuild';
        case BatchTaskType.InvoicePaymentStatusRecheck:
            return 'Invoice Payment Recheck';
        default:
            return 'Unknown';
    }
};

export default function BatchTasks(): ReactElement {
    const [dataList, setDataList] = useState<BatchTask[]>([]);
    const [fetchList, { data, loading: dataLoading }] = useFetchBatchTaskListLazyQuery({
        onCompleted: () => {
            setDataList(data?.batchTasks || []);
        },
    });
    const [detailsExpanded, setDetailsExpanded] = useState(false);
    const [selectedItems, setSelectedItems] = useState<BatchTaskType[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [initAppBundleId, setInitAppBundleId] = useState<string>('');
    const [eligAppBundleId, setEligAppBundleId] = useState<string>('');
    const [recalcTagsAppBundleId, setRecalcTagsAppBundleId] = useState<string>('');
    const [deletePatientsAppBundleId, setDeletePatientsAppBundleId] = useState<string>('');
    const [patientAppBundleId, setPatientAppBundleId] = useState<string>('');
    const [createBatchTask] = useCreateBatchTaskMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchBatchTaskListDocument,
                variables: {},
            },
        ],
    });
    const { data: userData } = useUser();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const handleExpandClick = () => {
        setDetailsExpanded(!detailsExpanded);
    };
    const [deleteTask, { loading: deleteTaskLoading }] = useDeleteBatchTaskMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: FetchBatchTaskListDocument,
                variables: {},
            },
        ],
    });

    const handleDelete = (id: string) => {
        TriggerGlobalConfirm({
            message: `Do you really want to delete this task?`,
            callback: () => {
                deleteTask({ variables: { input: { id } } });
            },
        });
    };
    useEffect(() => {
        fetchList();
        if (data) setDataList(data.batchTasks);
    }, [fetchList, data]);
    const checkChanged = (type: BatchTaskType, checked: boolean) => {
        let newSelected = selectedItems.slice();
        if (checked && !newSelected.includes(type)) {
            newSelected.push(type);
        }
        if (!checked) {
            newSelected = newSelected.filter(t => t !== type);
        }
        setSelectedItems(newSelected);
    };
    const { data: prefetchData, loading: prefetchDataLoading } =
        useFetchAppsTagsForBatchTasksQuery();
    const submitTask = () => {
        let taskCreated = false;
        for (const item of selectedItems) {
            const data: BatchTaskInput = {
                type: item,
                status: BatchTaskStatus.NotStarted,
                addedBy: userData?.currentUser?.name ?? '',
                priority: 5,
                runAfter: new Date(),
            };
            switch (item) {
                case BatchTaskType.ConfigCacheRebuild:
                case BatchTaskType.ContentCacheRebuild:
                case BatchTaskType.DirectoryCacheRebuild:
                case BatchTaskType.TagCacheRebuild:
                    createBatchTask({
                        variables: {
                            input: data,
                        },
                    });
                    taskCreated = true;
                    break;
                case BatchTaskType.DeleteAppPatients:
                    if (
                        item === BatchTaskType.DeleteAppPatients &&
                        deletePatientsAppBundleId !== ''
                    ) {
                        createBatchTask({
                            variables: {
                                input: {
                                    ...data,
                                    settings: { appBundleId: deletePatientsAppBundleId },
                                },
                            },
                        });
                        taskCreated = true;
                    }
                    break;
                case BatchTaskType.EligibilityRecheck:
                    if (eligAppBundleId !== '') {
                        createBatchTask({
                            variables: {
                                input: {
                                    ...data,
                                    settings: { appBundleId: eligAppBundleId },
                                },
                            },
                        });
                        taskCreated = true;
                    }
                    break;
                case BatchTaskType.InitializeApp:
                    if (initAppBundleId !== '') {
                        createBatchTask({
                            variables: {
                                input: {
                                    ...data,
                                    settings: { appBundleId: initAppBundleId },
                                },
                            },
                        });
                        taskCreated = true;
                    }
                    break;
                case BatchTaskType.PatientCacheRebuild:
                    if (patientAppBundleId !== '') {
                        createBatchTask({
                            variables: {
                                input: {
                                    ...data,
                                    settings: { appBundleId: patientAppBundleId },
                                },
                            },
                        });
                        taskCreated = true;
                    }
                    break;
                case BatchTaskType.RecalcTags:
                    if (recalcTagsAppBundleId !== '' && selectedTags.length > 0) {
                        createBatchTask({
                            variables: {
                                input: {
                                    ...data,
                                    settings: {
                                        appBundleId: recalcTagsAppBundleId,
                                        tags: selectedTags,
                                    },
                                },
                            },
                        });
                        taskCreated = true;
                    }
                    break;
                default:
                    break;
            }
        }
        if (taskCreated) {
            setDetailsExpanded(false);
            setSelectedItems([]);
            setSelectedTags([]);
            setInitAppBundleId('');
            setPatientAppBundleId('');
            setDeletePatientsAppBundleId('');
            setEligAppBundleId('');
            setRecalcTagsAppBundleId('');
        }
    };
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleTagSelect = (event: any) => {
        const selected = Array.from(event.target.selectedOptions, (option: any) => option.value);
        setSelectedTags(selected);
    };
    if (prefetchDataLoading || deleteTaskLoading) return <Loading />;
    return (
        <div ref={tableRef} style={{ marginLeft: '15px' }}>
            <Card>
                <CardHeader title="Batch Tasks" />
                <CardActions>
                    <Accordion
                        expanded={detailsExpanded}
                        onClick={handleExpandClick}
                        aria-expanded={detailsExpanded}
                    >
                        {detailsExpanded && <ExpandLess />}
                        {!detailsExpanded && <ExpandMore />}
                    </Accordion>
                </CardActions>
                <Collapse in={detailsExpanded}>
                    <OutlinedSection title="Select Task(s)">
                        <OutlinedSection title="Cache Rebuilds">
                            <span>
                                Content
                                <Checkbox
                                    checked={selectedItems.includes(
                                        BatchTaskType.ContentCacheRebuild,
                                    )}
                                    onChange={e =>
                                        checkChanged(
                                            BatchTaskType.ContentCacheRebuild,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                            <span>
                                Config
                                <Checkbox
                                    checked={selectedItems.includes(
                                        BatchTaskType.ConfigCacheRebuild,
                                    )}
                                    onChange={e =>
                                        checkChanged(
                                            BatchTaskType.ConfigCacheRebuild,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                            <span>
                                Directorys
                                <Checkbox
                                    checked={selectedItems.includes(
                                        BatchTaskType.DirectoryCacheRebuild,
                                    )}
                                    onChange={e =>
                                        checkChanged(
                                            BatchTaskType.DirectoryCacheRebuild,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                            <span>
                                Tags
                                <Checkbox
                                    checked={selectedItems.includes(BatchTaskType.TagCacheRebuild)}
                                    onChange={e =>
                                        checkChanged(
                                            BatchTaskType.TagCacheRebuild,
                                            e.target.checked,
                                        )
                                    }
                                />
                            </span>
                        </OutlinedSection>
                        <OutlinedSection title="App Tasks">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Initialize App</td>
                                        <td>
                                            <Checkbox
                                                checked={selectedItems.includes(
                                                    BatchTaskType.InitializeApp,
                                                )}
                                                onChange={e => {
                                                    checkChanged(
                                                        BatchTaskType.InitializeApp,
                                                        e.target.checked,
                                                    );
                                                    if (!e.target.checked) {
                                                        setInitAppBundleId('');
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                value={initAppBundleId}
                                                onChange={e => {
                                                    setInitAppBundleId(e.target.value);
                                                    checkChanged(
                                                        BatchTaskType.InitializeApp,
                                                        e.target.value !== '',
                                                    );
                                                }}
                                            >
                                                <option value="">Select App...</option>
                                                {_.sortBy(
                                                    prefetchData?.applicationsV2.results,
                                                    'appBundleId',
                                                ).map(a => (
                                                    <option value={a.appBundleId}>
                                                        {a.appBundleId}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Delete App Patients</td>
                                        <td>
                                            <Checkbox
                                                checked={selectedItems.includes(
                                                    BatchTaskType.DeleteAppPatients,
                                                )}
                                                onChange={e => {
                                                    checkChanged(
                                                        BatchTaskType.DeleteAppPatients,
                                                        e.target.checked,
                                                    );
                                                    if (!e.target.checked) {
                                                        setDeletePatientsAppBundleId('');
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                value={deletePatientsAppBundleId}
                                                onChange={e => {
                                                    setDeletePatientsAppBundleId(e.target.value);
                                                    checkChanged(
                                                        BatchTaskType.DeleteAppPatients,
                                                        e.target.value !== '',
                                                    );
                                                }}
                                            >
                                                <option value="">Select App...</option>
                                                {_.sortBy(
                                                    prefetchData?.applicationsV2.results,
                                                    'appBundleId',
                                                ).map(a => (
                                                    <option value={a.appBundleId}>
                                                        {a.appBundleId}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Eligibility Recheck</td>
                                        <td>
                                            <Checkbox
                                                checked={selectedItems.includes(
                                                    BatchTaskType.EligibilityRecheck,
                                                )}
                                                onChange={e => {
                                                    checkChanged(
                                                        BatchTaskType.EligibilityRecheck,
                                                        e.target.checked,
                                                    );
                                                    if (!e.target.checked) {
                                                        setEligAppBundleId('');
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                value={eligAppBundleId}
                                                onChange={e => {
                                                    setEligAppBundleId(e.target.value);
                                                    checkChanged(
                                                        BatchTaskType.EligibilityRecheck,
                                                        e.target.value !== '',
                                                    );
                                                }}
                                            >
                                                <option value="">Select App...</option>
                                                {_.sortBy(
                                                    prefetchData?.applicationsV2.results,
                                                    'appBundleId',
                                                ).map(a => (
                                                    <option value={a.appBundleId}>
                                                        {a.appBundleId}
                                                    </option>
                                                ))}
                                                <option value="WHASN_UMR">WHASN (UMR)</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Patient Cache Rebuild</td>
                                        <td>
                                            <Checkbox
                                                checked={selectedItems.includes(
                                                    BatchTaskType.PatientCacheRebuild,
                                                )}
                                                onChange={e => {
                                                    checkChanged(
                                                        BatchTaskType.PatientCacheRebuild,
                                                        e.target.checked,
                                                    );
                                                    if (!e.target.checked) {
                                                        setPatientAppBundleId('');
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <select
                                                value={patientAppBundleId}
                                                onChange={e => {
                                                    setPatientAppBundleId(e.target.value);
                                                    checkChanged(
                                                        BatchTaskType.PatientCacheRebuild,
                                                        e.target.value !== '',
                                                    );
                                                }}
                                            >
                                                <option value="">Select App...</option>
                                                {_.sortBy(
                                                    prefetchData?.applicationsV2.results,
                                                    'appBundleId',
                                                ).map(a => (
                                                    <option value={a.appBundleId}>
                                                        {a.appBundleId}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </OutlinedSection>
                        <OutlinedSection title="Recalculate Tags">
                            Check to run
                            <Checkbox
                                checked={selectedItems.includes(BatchTaskType.RecalcTags)}
                                onChange={e =>
                                    checkChanged(BatchTaskType.RecalcTags, e.target.checked)
                                }
                            />
                            <select
                                value={recalcTagsAppBundleId}
                                onChange={e => setRecalcTagsAppBundleId(e.target.value)}
                            >
                                <option value="">Select App...</option>
                                {_.sortBy(prefetchData?.applicationsV2.results, 'appBundleId').map(
                                    a => (
                                        <option value={a.appBundleId}>{a.appBundleId}</option>
                                    ),
                                )}
                            </select>
                            <div>
                                Select Tags: (Use Ctrl/Cmd to multi select){' '}
                                {`${selectedTags.length} selected`}
                                <br />
                                <select
                                    value={selectedTags}
                                    onChange={handleTagSelect}
                                    multiple
                                    size={10}
                                >
                                    {_.sortBy(prefetchData?.tags, [
                                        tag => tag.name.toLowerCase(),
                                    ]).map(t => (
                                        <option key={t.id} value={t.id}>
                                            {t.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </OutlinedSection>
                    </OutlinedSection>
                    {selectedItems.length > 0 && (
                        <Button
                            aria-label="save"
                            startIcon={<FontAwesomeIcon icon={faCheck} />}
                            color="secondary"
                            variant="contained"
                            onClick={submitTask}
                        >
                            {selectedItems.length > 1
                                ? `Create ${selectedItems.length} Tasks`
                                : 'Create Task'}
                        </Button>
                    )}
                </Collapse>
            </Card>
            <MaterialTable<BatchTask>
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        onClick: (_e, item) => {
                            if ((item as BatchTask).status !== BatchTaskStatus.Running)
                                handleDelete((item as BatchTask).id);
                        },
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        tooltip: 'Delete',
                    },
                ]}
                columns={[
                    {
                        title: 'Type',
                        field: 'type',
                        render: ({ type }) => <Typography>{toTypeName(type)}</Typography>,
                    },
                    {
                        title: 'Added By',
                        field: 'addedBy',
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        render: ({ status }) => <Typography>{toStatusName(status)}</Typography>,
                    },
                    {
                        title: 'Date Created',
                        field: 'createdAt',
                    },
                    {
                        title: 'Started',
                        field: 'startedAt',
                    },
                    {
                        title: 'Completed',
                        field: 'completedAt',
                    },
                ]}
                isLoading={dataLoading}
                data={dataList ?? []}
                title="Submitted Tasks"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                }}
            />
        </div>
    );
}

import React from 'react';
import { format, toDate } from 'date-fns-tz';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Grid, Typography, Link, Divider, Card } from '@mui/material';
import {
    useAffiliateCarePatientQuery,
    useAffiliateCareSubmittedClaimsV2Query,
    OrderByDirectionEnum,
    EligibilityRefreshOrigin,
} from '~/schemaTypes';
import {
    getActiveInsurancePlanData,
    isEligibleForLactationServices,
} from '~/utils/lactationServicesEligibility';
import Loading from '~/components/Loading/Loading';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import useUserPermissions from '~/hooks/useUserPermissions';
import useUser from '~/hooks/useUser';
import { useInitVCF, VCFContext } from '~/views/VirtualCareSurvey/hooks/useVCF';
import Claims from './Claims';
import RecheckEligibilityButton from '../../components/RecheckEligibilityButton/RecheckEligibilityButton';

type RouteParams = {
    id: string;
};

type PatientProps = {
    affiliateId?: string;
    portalView?: boolean;
    VCView?: boolean;
};

const Patient: React.FC<PatientProps> = props => {
    const { affiliateId, portalView = false, VCView = false } = props;
    const { id } = useParams<RouteParams>();
    const { userAffiliateId } = useAffiliateCareView();
    const currentAffiliateId = portalView ? affiliateId : userAffiliateId;
    const { pagePermissions } = useUserPermissions();
    const { data: userData } = useUser();
    const { data: patient, loading: patientLoading } = useAffiliateCarePatientQuery({
        variables: {
            input: {
                id,
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const { data: claims, loading: claimsLoading } = useAffiliateCareSubmittedClaimsV2Query({
        variables: {
            input: {
                filter: {
                    fields: {
                        ...(!VCView && { affiliateId: currentAffiliateId }),
                        ...(VCView && { userId: userData?.currentUser?.id }),
                        patientId: id,
                    },
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const activeInsurancePlan = getActiveInsurancePlanData(patient?.patient?.insurancePlans ?? []);
    const isEligible = isEligibleForLactationServices(activeInsurancePlan);
    const showClaimSection =
        pagePermissions?.DoulaInvoices.Read ||
        pagePermissions?.VirtualCareClaims.Read ||
        portalView;
    const VCFInitProps = VCView ? { isDoulaView: true } : {};
    const VCFContextProps = useInitVCF(VCFInitProps);
    if (patientLoading || claimsLoading) {
        return <Loading subtitle="Loading patient data" />;
    }

    let insurancePlanName = activeInsurancePlan?.name;
    if (!insurancePlanName) {
        insurancePlanName = activeInsurancePlan ? 'Unknown' : 'None';
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {!VCView && (
                    <Breadcrumbs>
                        <Link
                            to={
                                portalView
                                    ? `/app-config/vcaffiliates/patients/${affiliateId}`
                                    : '/affiliate-care-portal/patients'
                            }
                            component={RouterLink}
                            underline="hover"
                            color="inherit"
                        >
                            <Typography variant="h6">Patients</Typography>
                        </Link>
                        <Typography>{patient?.patient?.fullName}</Typography>
                    </Breadcrumbs>
                )}
            </Grid>
            <VCFContext.Provider value={VCFContextProps}>
                <Grid container spacing={2} item>
                    <Grid item xs={12}>
                        <Card style={{ marginBottom: 0, padding: 0 }}>
                            <Grid
                                container
                                alignItems="center"
                                sx={{
                                    padding: 4,
                                    paddingBottom: 2,
                                    ...(isEligible ? {} : { backgroundColor: '#f2dada' }),
                                }}
                            >
                                <Typography variant="h6">
                                    Patient Info &ndash;
                                    {isEligible
                                        ? ' Eligible for Lactation Services.'
                                        : ' Insurance Expired. Not Currently Eligible for Lactation Services.'}
                                </Typography>
                                {isEligible || (
                                    <RecheckEligibilityButton
                                        origin={
                                            portalView
                                                ? EligibilityRefreshOrigin.AdminPortal
                                                : EligibilityRefreshOrigin.AffiliatePortal
                                        }
                                        patientId={id as string}
                                        affiliateId={currentAffiliateId}
                                    />
                                )}
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                alignItems="flex-start"
                                sx={{ paddingX: 4, paddingBottom: 4 }}
                            >
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" gutterBottom variant="body1">
                                        Full Name:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {patient?.patient?.fullName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="primary" gutterBottom variant="body1">
                                        Email:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {patient?.patient?.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" gutterBottom variant="body1">
                                        DOB:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {patient?.patient?.birthDate &&
                                            format(
                                                toDate(patient?.patient?.birthDate.split('T')[0]),
                                                'MM/dd/yyyy',
                                            )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" gutterBottom variant="body1">
                                        Date Created:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {patient?.patient?.createdAt &&
                                            format(
                                                toDate(patient?.patient?.createdAt.split('T')[0]),
                                                'MM/dd/yyyy',
                                            )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography color="primary" gutterBottom variant="body1">
                                        Phone Number:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {patient?.patient?.virtualCarePhoneNumber ??
                                            patient?.patient?.phoneNumber}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" gutterBottom variant="body1">
                                        Address:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {patient?.patient?.mailingAddress?.street1 && (
                                            <Typography noWrap>
                                                {patient?.patient?.mailingAddress?.street1}
                                            </Typography>
                                        )}
                                        {patient?.patient?.mailingAddress?.street2 && (
                                            <Typography noWrap>
                                                {patient?.patient?.mailingAddress?.street2}
                                            </Typography>
                                        )}
                                        {patient?.patient?.mailingAddress?.city &&
                                        patient?.patient?.mailingAddress?.state &&
                                        patient?.patient?.mailingAddress?.code ? (
                                            <Typography noWrap>
                                                {patient?.patient?.mailingAddress?.city},{' '}
                                                {patient?.patient?.mailingAddress?.state}{' '}
                                                {patient?.patient?.mailingAddress?.code}{' '}
                                            </Typography>
                                        ) : (
                                            patient?.patient?.mailingAddress?.city ||
                                            patient?.patient?.mailingAddress?.state
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" gutterBottom variant="body1">
                                        Insurance Plan Name:
                                    </Typography>
                                    <Typography variant="subtitle1">{insurancePlanName}</Typography>
                                </Grid>
                                <Grid container item xs={6}>
                                    <Grid
                                        item
                                        sx={{
                                            marginX: -2,
                                            marginY: -1,
                                            paddingX: 2,
                                            paddingY: 1,
                                            ...(isEligible ? {} : { backgroundColor: '#f2dada' }),
                                        }}
                                    >
                                        <Typography color="primary" gutterBottom variant="body1">
                                            Insurance Eligibility Dates:
                                        </Typography>
                                        <Grid container alignItems="center">
                                            <Typography variant="subtitle1">
                                                {activeInsurancePlan
                                                    ? `${activeInsurancePlan.eligibilityStartDate} - ${activeInsurancePlan.eligibilityEndDate}`
                                                    : 'None'}
                                            </Typography>
                                            {isEligible || (
                                                <RecheckEligibilityButton
                                                    origin={
                                                        portalView
                                                            ? EligibilityRefreshOrigin.AdminPortal
                                                            : EligibilityRefreshOrigin.AffiliatePortal
                                                    }
                                                    patientId={id as string}
                                                    affiliateId={currentAffiliateId}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        {showClaimSection && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <Typography color="primary" variant="h6" align="center">
                                            Patient Claims
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ minHeight: 200 }}
                                >
                                    <Claims claims={claims} portalView={portalView} />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </VCFContext.Provider>
        </Grid>
    );
};

export default Patient;

import { useState } from 'react';
import type { CardTypes } from '../types';

export const useCardTypes = () => {
    const [cardTypes, setCardTypes] = useState<CardTypes>(() => {
        const savedCardTypes = localStorage.getItem('patientTranscriptCardTypes');
        return savedCardTypes
            ? JSON.parse(savedCardTypes)
            : {
                  Tracker: true,
                  ProfileValue: true,
                  PatientNotes: true,
              };
    });

    const handleSetCardTypes = (newCardTypes: CardTypes) => {
        setCardTypes(newCardTypes);
        localStorage.setItem('patientTranscriptCardTypes', JSON.stringify(newCardTypes));
    };

    return [cardTypes, handleSetCardTypes] as const;
};

import { Typography } from '@mui/material';
import React from 'react';
import { GetTriggerInterventionStartEpisodesForPatientTranscriptQuery } from '~/schemaTypes';

type TriggerInterventionStartEpisode = NonNullable<
    NonNullable<
        GetTriggerInterventionStartEpisodesForPatientTranscriptQuery['getTriggerInterventionStartEpisodesForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionStartEpisodeCard = ({
    intervention,
}: {
    intervention: TriggerInterventionStartEpisode;
}) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <Typography variant="h6">Trigger Intervention Start Episode</Typography>
            <p className="m-0">
                {`Condition: ${intervention?.conditionType?.name?.en || 'Unknown'}`}
            </p>
            <p className="m-0">
                {`Intervention Type: ${intervention?.event?.data?.intervention?.type || 'Unknown'}`}
            </p>
        </div>
    );
};

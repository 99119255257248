import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Snackbar, Alert } from '@mui/material';
import { useAffiliateCareView } from '~/hooks';
import { AlertSeverity, OnboardingStatus } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { Steps } from '~/views/AffiliateCare/Onboarding/types';

interface OnboardingRouteProps {
    children: React.ReactElement<any, any> | null;
}

const ONBOARDING_PATH = '/affiliate-care-portal/affiliate-onboarding';

export const OnboardingRoute: React.FC<OnboardingRouteProps> = ({ children }) => {
    const {
        isAffiliateView,
        isDenied,
        isOnboarding,
        userAffiliateId,
        onboardingStep,
        deniedMessage,
    } = useAffiliateCareView();
    const { pathname } = useLocation();
    const history = useNavigate();
    const [showLoading, setShowLoading] = useState(true);
    useEffect(() => {
        if (isAffiliateView && userAffiliateId) {
            if (!pathname.startsWith(ONBOARDING_PATH) && isOnboarding) {
                let stepId;
                switch (onboardingStep) {
                    case OnboardingStatus.FirstStepCompleted:
                        stepId = Steps.Payment;
                        break;
                    case OnboardingStatus.SecondStepCompleted:
                        stepId = Steps.ServiceAndLocations;
                        break;
                    case OnboardingStatus.ThirdStepCompleted:
                    case OnboardingStatus.FourthStepCompleted:
                        stepId = Steps.Routing;
                        break;
                    default:
                        stepId = Steps.Consultants;
                }
                history(`${ONBOARDING_PATH}/${stepId + 1}`);
            }
            if (pathname.startsWith(ONBOARDING_PATH) && !isOnboarding) {
                history('/affiliate-care-portal/landing');
            }
            setShowLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, isOnboarding, isAffiliateView, userAffiliateId]);
    if (showLoading) {
        return <Loading />;
    }
    if (isDenied && !isOnboarding) {
        return (
            <>
                <Snackbar
                    key="submitClaimsDenied"
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{
                        width: 'fit-content',
                    }}
                >
                    <Alert severity={AlertSeverity.Warning}>
                        {deniedMessage ??
                            'Unable to Submit Claims. Contact lactationsupport@wildflowerhealth.com for more information.'}
                    </Alert>
                </Snackbar>
                {children}
            </>
        );
    }
    return children;
};

import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Button,
    Modal,
    Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

type DateRangeFilterProps = {
    dateRange: string;
    setDateRange: (range: string) => void;
    customStartDate: Date | null;
    setCustomStartDate: (date: Date | null) => void;
    customEndDate: Date | null;
    setCustomEndDate: (date: Date | null) => void;
};

export const DateRangeFilter = ({
    dateRange,
    setDateRange,
    customStartDate,
    setCustomStartDate,
    customEndDate,
    setCustomEndDate,
}: DateRangeFilterProps) => {
    const [openCustomDateModal, setOpenCustomDateModal] = React.useState(false);
    const [startDate, setStartDate] = React.useState<Date | null>(customStartDate);
    const [endDate, setEndDate] = React.useState<Date | null>(customEndDate);

    const handleCloseModal = () => {
        setStartDate(customStartDate);
        setEndDate(customEndDate);
        setOpenCustomDateModal(false);
    };

    const handleSaveModal = () => {
        if (startDate && endDate) {
            setCustomStartDate(startDate);
            setCustomEndDate(endDate);
            setDateRange('custom');
        }
        setOpenCustomDateModal(false);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="date-range-label">Date Range</InputLabel>
            <Select
                className="w-[150px]"
                labelId="date-range-label"
                value={dateRange}
                label="Date Range"
                onChange={e => {
                    if (e.target.value !== 'custom') {
                        setDateRange(e.target.value);
                    }
                }}
            >
                <MenuItem value="lastDay">Last Day</MenuItem>
                <MenuItem value="last3Days">Last 3 Days</MenuItem>
                <MenuItem value="last7Days">Last 7 Days</MenuItem>
                <MenuItem value="last30Days">Last 30 Days</MenuItem>
                <MenuItem
                    value="custom"
                    onClick={() => {
                        setOpenCustomDateModal(true);
                    }}
                >
                    Custom Range
                </MenuItem>
            </Select>

            <Modal
                open={openCustomDateModal}
                onClose={handleCloseModal}
                aria-labelledby="custom-date-range-modal"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <Typography variant="h6">Custom Date Range</Typography>
                    <DateTimePicker
                        label="Start Date"
                        value={startDate}
                        onChange={newValue => {
                            if (newValue) {
                                setStartDate(newValue);
                            }
                        }}
                        format="MM/dd/yyyy hh:mm"
                    />
                    <DateTimePicker
                        label="End Date"
                        value={endDate}
                        onChange={newValue => {
                            if (newValue) {
                                setEndDate(newValue);
                            }
                        }}
                        format="MM/dd/yyyy hh:mm"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Button onClick={handleCloseModal}>Cancel</Button>
                        <Button variant="contained" onClick={handleSaveModal}>
                            Apply
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </FormControl>
    );
};

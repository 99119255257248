/* eslint-disable react/react-in-jsx-scope */
import { VirtualCareSurvey } from '../VirtualCareSurvey';
import { useInitVCF, VCFContext } from '../hooks/useVCF';

const DOULA_FORM_DESCRIPTION = `
Securely provide your health information below. 
This will help us match you to the right resources for you and your health experience. 
After you complete this form, you can schedule your appointment. 
We currently support Doula services for a limited number of Cigna insured employers. 
Please check with your employer to confirm benefits.`;

const DoulaVCF = () => {
    const contextProps = useInitVCF({
        isDoulaView: true,
        formDescriptionText: DOULA_FORM_DESCRIPTION,
    });
    return (
        <VCFContext.Provider value={contextProps}>
            <VirtualCareSurvey pathname="/care-connect/doula" />
        </VCFContext.Provider>
    );
};

export default DoulaVCF;

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    OrderByDirectionEnum,
    useApplicationListLazyQuery,
    useAppReleaseListLazyQuery,
    useCreateAppBuildMutation,
} from '~/schemaTypes';
import { ConditionTypeEnum } from '~/selectors';
import { TriggerGlobalConfirm } from '~/state';
import { useUser } from '~/hooks';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';

interface FormInput {
    minPlatformLevel: string;
    appVersion: string;
    logVersion: string;
    notes: string;
}

const inputValidation = Yup.object().shape({
    minPlatformLevel: Yup.string().required('Required'),
    appVersion: Yup.string().required('Required'),
    logVersion: Yup.string().required('Required'),
});

export const AppBuildModal: React.FC<{
    isOpen: boolean;
    appReleaseId?: string;
    appBundleId?: string;
    onClose: () => void;
    setRefreshIndex: React.Dispatch<React.SetStateAction<number>>;
}> = props => {
    const { isOpen, appReleaseId, onClose, appBundleId, setRefreshIndex } = props;
    const { data: userData } = useUser();
    const [bundleId, setBundleId] = useState<string>('');
    const [releaseId, setReleaseId] = useState<string>('');
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<FormInput>({
        resolver: yupResolver(inputValidation as any),
    });
    const [getApplicationList, { data: appList, loading: appListLoading }] =
        useApplicationListLazyQuery({
            onCompleted: data => {
                reset();
                setBundleId(data.applicationsV2.results[0].appBundleId);
            },
            variables: {
                input: {
                    filter: { fields: { isActive: true } },
                    orderBy: { field: 'appBundleId', order: OrderByDirectionEnum.Asc },
                },
            },
        });
    const [getAppReleaseList, { data: appReleaseList, loading: appReleaseListLoading }] =
        useAppReleaseListLazyQuery({
            onCompleted: data => {
                reset();
                setReleaseId(data.appReleasesV2.results[0].id);
            },
            variables: {
                input: {
                    orderBy: { field: 'name', order: OrderByDirectionEnum.Asc },
                },
            },
        });
    const handleClose = () => {
        setRefreshIndex(new Date().getTime());
        reset();
        onClose();
    };
    useEffect(() => {
        reset();
        if (appBundleId) {
            getAppReleaseList();
            setBundleId(appBundleId);
        }
        if (appReleaseId) {
            getApplicationList();
            setReleaseId(appReleaseId);
        }
    }, [appBundleId, appReleaseId, getAppReleaseList, getApplicationList, reset]);
    const [createAppBuild, { loading: createLoading }] = useCreateAppBuildMutation({
        onCompleted: () => {
            handleClose();
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    handleClose();
                },
                message: `There was a problem saving the App Build: ${error.message}`,
            });
        },
    });

    const onSubmit = (values: FormInput) => {
        createAppBuild({
            variables: {
                input: {
                    appReleaseId: releaseId,
                    appBundleId: bundleId,
                    minPlatformLevel: Number(values.minPlatformLevel),
                    appVersion: Number(values.appVersion),
                    logVersion: Number(values.logVersion),
                    notes: values.notes,
                    createdBy: userData?.currentUser?.name || 'Unknown',
                    currentEnv: 1,
                    promotionHistory: [
                        {
                            env: 1,
                            promotionDate: new Date(),
                            promotedBy: userData?.currentUser?.name || 'Unknown',
                            notes: `Build created by ${
                                userData?.currentUser?.name
                            } on ${new Date().toUTCString()}`,
                        },
                    ],
                },
            },
        });
    };
    if (createLoading || appListLoading || appReleaseListLoading) {
        return <Loading />;
    }
    return (
        <Dialog open={isOpen}>
            <DialogTitleWithClose id="dialogTitle" onClose={handleClose}>
                Add App Build
            </DialogTitleWithClose>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <div>
                        <Grid item xs={12}>
                            {appReleaseId && (
                                <OutlinedSection title="Select App">
                                    <select
                                        value={bundleId || ''}
                                        onChange={event => setBundleId(event.target.value)}
                                    >
                                        {appList?.applicationsV2.results.map(a => (
                                            <option key={a.appBundleId} value={a.appBundleId}>
                                                {a.appBundleId}
                                            </option>
                                        ))}
                                    </select>
                                </OutlinedSection>
                            )}
                            {appBundleId && (
                                <OutlinedSection title="Select Release">
                                    <select
                                        value={releaseId || ''}
                                        onChange={event => setReleaseId(event.target.value)}
                                    >
                                        {appReleaseList?.appReleasesV2.results.map(a => (
                                            <option key={a.id} value={a.id}>
                                                {a.name}
                                            </option>
                                        ))}
                                    </select>
                                </OutlinedSection>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="App Version *"
                                fullWidth
                                margin="dense"
                                {...register('appVersion')}
                                error={!!errors.appVersion}
                                helperText={errors.appVersion?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Min Platform Level *"
                                fullWidth
                                margin="dense"
                                {...register('minPlatformLevel')}
                                error={!!errors.minPlatformLevel}
                                helperText={errors.minPlatformLevel?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Log Version *"
                                fullWidth
                                margin="dense"
                                {...register('logVersion')}
                                error={!!errors.logVersion}
                                helperText={errors.logVersion?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Build Notes"
                                fullWidth
                                margin="dense"
                                rows={4}
                                {...register('notes')}
                            />
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        type="submit"
                        color="secondary"
                        variant="contained"
                        onClick={() => handleSubmit(onSubmit)}
                        data-test={ConditionTypeEnum.SAVE}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

import moment from 'moment';
import { ClaimSubmitted, ClaimSubmittedStatus } from '~/schemaTypes';

const PROCESSING_DELAY = 5;

const UPLOAD_ERROR_PREFIX = 'upload error';

const allowUpload = (
    claim: Pick<ClaimSubmitted, 'status' | 'uploaded' | 'updatedAt' | 'errorText'>,
) => {
    if (!claim.uploaded && claim.status === ClaimSubmittedStatus.Completed) {
        return true;
    }
    if (
        !claim.uploaded &&
        claim.status &&
        [ClaimSubmittedStatus.InProgress, ClaimSubmittedStatus.Ready].includes(claim.status) &&
        moment(new Date(claim.updatedAt)).add(PROCESSING_DELAY, 'm').isBefore(moment())
    ) {
        return true;
    }
    if (
        !claim.uploaded &&
        claim.status === ClaimSubmittedStatus.Error &&
        claim.errorText?.toLowerCase().includes(UPLOAD_ERROR_PREFIX)
    ) {
        return true;
    }
    return false;
};

export default allowUpload;
